<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información del productor</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="productor"
            :remove-commit="'removerProductor'"
            :record-id="productor.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium blue-grey--text">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="productor.tipo_id"
            :rules="[r.required]"
            :items="tipo_identificacion"
            item-text="descripcion"
            item-value="id"
            label="Tipo de identificación*"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div
            v-if="r.pa_id(productor.id) !== true && productor.tipo_empresa == 3"
            class="warning--text"
          >
            No es una cédula panameña
          </div>
        </v-col>
        <v-col v-if="productor.tipo_id === 1" cols="6">
          <v-text-field
            v-model="productor.id"
            placeholder="#-###-###"
            :rules="[
              [1, 2].includes(productor.tipo_empresa) ? r.pa_id : true,
              r.required,
              r.max(23),
            ]"
            label="Cédula del Productor o Representante de la empresa*"
            append-icon="mdi-account"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col v-else-if="productor.tipo_id === 2" cols="6">
          <v-text-field
            v-model="productor.id"
            :rules="[r.required, r.max(23)]"
            label="Pasaporte*"
            append-icon="mdi-account"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.estado"
            :rules="[r.required]"
            :items="estado"
            item-text="NOMBRE"
            item-value="ID_STATUS"
            label="Estado*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.tipo_empresa"
            :rules="[r.required]"
            :items="tipo_empresa"
            item-text="TIPO"
            item-value="ID"
            label="Tipo de persona*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.primer_nombre"
            :rules="[r.required, r.name, r.max()]"
            label="Primer nombre*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.segundo_nombre"
            :rules="[r.name, r.max()]"
            label="Segundo nombre"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.primer_apellido"
            :rules="[r.required, r.name, r.max()]"
            label="Primer apellido*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.segundo_apellido"
            :rules="[r.name, r.max()]"
            label="Segundo apellido"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.apellido_conyugue"
            :rules="[r.name, r.max()]"
            label="Apellido de conyugue"
            outlined
          />
        </v-col>
        <v-col cols="8">
          <n-date
            label="Fecha de nacimiento*"
            :rules="[r.required]"
            :date.sync="productor.fecha_nacimiento"
            range-type="birthdate"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field :value="edad" label="Edad" disabled outlined />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="productor.direccion"
            :rules="[r.required, r.max(1000)]"
            label="Dirección*"
            counter="1000"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.id_provincia"
            :rules="[r.required]"
            :items="provincias"
            item-text="NOMBRE_PROVINCIA"
            item-value="ID_PROVINCIA"
            label="Provincia*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.id_distrito"
            :rules="[r.required]"
            :items="distritos(productor.id_provincia)"
            item-text="NOMBRE_DISTRITO"
            item-value="ID_DISTRITO"
            label="Distrito*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.id_corregimiento"
            :rules="[r.required]"
            :items="corregimientos(productor.id_distrito)"
            item-text="NOMBRE_CORREGIMIENTO"
            item-value="ID_CORREGIMIENTO"
            label="Corregimiento*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.id_poblado"
            :rules="[r.required]"
            :items="poblados(productor.id_corregimiento)"
            item-text="NOMBRE_POBLADO"
            item-value="ID_POBLADO"
            label="Poblado*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.numero_casa"
            :rules="[r.max(24)]"
            label="Número de casa o departamento"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
        </v-col>
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="obtenerPosicionProductor"
          >
            <v-icon left> mdi-crosshairs </v-icon>
            Registrar ubicación actual
          </v-btn>
          <div class="text-center">
            Registrar la ubicación solo al encontrase en la residencia del
            productor.
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.longitud"
            :rules="[r.x_coord]"
            label="Longitud"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.latitud"
            :rules="[r.y_coord]"
            label="Latitud"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="productor.id_nacionalidad"
            :rules="[r.required]"
            :items="pais"
            item-text="NOMBRE"
            item-value="ISO"
            label="Nacionalidad*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="productor.email"
            :rules="[r.email]"
            label="Correo"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.sexo"
            :rules="[r.required]"
            :items="sexo"
            item-text="descripcion"
            item-value="id"
            label="Sexo*"
            outlined
          />
        </v-col>
        <!-- listado dinámico de números de teléfono -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarNumero">
            <v-icon left> mdi-phone </v-icon>
            Agregar número
          </v-btn>
        </v-col>
        <template v-for="(numero, index) in productor.numeros">
          <v-col :key="`${index}tipo`" cols="5">
            <v-select
              v-model="numero.tipo_telefono"
              :items="tipo_telefono"
              label="Tipo"
              outlined
              clearable
            />
          </v-col>
          <v-col :key="`${index}nu`" cols="7">
            <v-text-field
              v-model.number="numero.telefono"
              :rules="[
                r.min_num(1000000),
                r.max_num(99999999),
                (v) =>
                  !numero.tipo_telefono ||
                  !!numero.telefono ||
                  'Coloque el número de teléfono',
              ]"
              label="Número"
              outlined
            >
              <v-icon
                slot="append-outer"
                :disabled="!puedeRemoverNumero"
                class="error--text"
                @click="removerNumero(index)"
              >
                mdi-close
              </v-icon>
            </v-text-field>
          </v-col>
        </template>
        <v-col cols="12">
          <v-select
            v-model="productor.tiene_organizacion"
            :rules="[r.required]"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="¿Pertecene a una organización asociativa?*"
            outlined
          />
        </v-col>
        <v-col v-if="productor.tiene_organizacion === 1" cols="12">
          <v-select
            v-model="productor.id_organizacion"
            :rules="[r.required]"
            :items="organizacion"
            item-text="nombre"
            item-value="id"
            label="Organización"
            outlined
          />
        </v-col>
        <!-- Muestra campos de nombre de organización y tipo de organización si la organización no existe en el listado (-1) -->
        <template
          v-if="
            productor.id_organizacion === 1 &&
            productor.tiene_organizacion === 1
          "
        >
          <v-col cols="12">
            <v-text-field
              v-model="productor.nombre_organizacion"
              :rules="[r.required, r.max()]"
              label="Nombre de la organización*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productor.registro_organizacion"
              :rules="[r.required, r.max()]"
              label="Registro*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="productor.tipo_organizacion"
              :items="tipo_organizacion"
              item-text="descripcion"
              item-value="id"
              :rules="[r.required]"
              label="Tipo*"
              outlined
            />
          </v-col>
        </template>
        <v-col cols="12">
          <v-select
            v-model="productor.escolaridad"
            :items="escolaridad"
            label="Escolaridad"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="productor.actividad"
            :items="actividad"
            label="Cuál es su principal actividad"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model.number="productor.anios_actividad"
            :rules="[r.int, r.min_num(0), r.max_num(150)]"
            label="Años"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model.number="productor.meses_actividad"
            :rules="[r.int]"
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            label="Meses"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.sabe_leer"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Sabe leer"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="productor.sabe_escribir"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="Sabe escribir"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="productor.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <div id="empresa" />
        <v-col v-if="tiene_empresa" cols="12">
          <!-- <template v-for="(num, index) in productor.empresa"> -->
          <v-row dense>
            <v-col cols="12">
              <div class="text-h6">Información de la Empresa</div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.ruc"
                :rules="[r.required, r.max()]"
                label="RUC*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.dv"
                :rules="[r.required]"
                label="DV*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="productor.empresa.nombre"
                :rules="[r.required, r.max()]"
                label="Nombre de la empresa*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="productor.empresa.direccion"
                :rules="[r.max(1000)]"
                label="Dirección"
                outlined
              />
            </v-col>
            <v-col v-if="productor.tiene_empresa === 2" cols="6">
              <v-text-field
                v-model="productor.empresa.telefono"
                :rules="[r.max(100)]"
                label="Teléfono"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.apartado_postal"
                :rules="[r.max()]"
                label="Apartado postal"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.email"
                :rules="[r.email]"
                label="Correo"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.sitio_web"
                :rules="[r.max(128)]"
                label="Sitio web"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="productor.empresa.id_provincia"
                :rules="[r.required]"
                :items="provincias"
                item-text="NOMBRE_PROVINCIA"
                item-value="ID_PROVINCIA"
                label="Provincia*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="productor.empresa.id_distrito"
                :rules="[r.required]"
                :items="distritos(productor.empresa.id_provincia)"
                item-text="NOMBRE_DISTRITO"
                item-value="ID_DISTRITO"
                label="Distrito*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="productor.empresa.id_corregimiento"
                :rules="[r.required]"
                :items="corregimientos(productor.empresa.id_distrito)"
                item-text="NOMBRE_CORREGIMIENTO"
                item-value="ID_CORREGIMIENTO"
                label="Corregimiento*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="productor.empresa.id_poblado"
                :rules="[r.required]"
                :items="poblados(productor.empresa.id_corregimiento)"
                item-text="NOMBRE_POBLADO"
                item-value="ID_POBLADO"
                label="Poblado*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <div class="text-h6">Ubicación (WGS84)</div>
            </v-col>
            <v-col cols="12">
              <v-btn
                depressed
                block
                class="primary--text"
                @click="obtenerPosicionDeEmpresa"
              >
                <v-icon left> mdi-crosshairs </v-icon>
                Registrar ubicación actual
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.longitud"
                :rules="[r.x_coord]"
                label="Longitud"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="productor.empresa.latitud"
                :rules="[r.y_coord]"
                label="Latitud"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="productor.empresa.actividad"
                :items="actividad"
                label="Cuál es su principal actividad"
                outlined
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model.number="productor.empresa.anios_actividad"
                :rules="[r.int, r.min_num(0), r.max_num(150)]"
                label="Años"
                outlined
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model.number="productor.empresa.meses_actividad"
                :rules="[r.int]"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                label="Meses"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="productor.empresa.certificaciones"
                multiple
                :items="sistema_gestion_calidad"
                label="¿Su empresa ha sido certificada por algún Sistema de Gestión de Calidad?"
                block
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="productor.empresa.organizaciones"
                multiple
                :items="institucion"
                item-text="descripcion"
                item-value="id"
                label="Organizaciones a la que pertenece"
                block
                outlined
              />
            </v-col>
            <v-col cols="12">
              <div class="text-h6">Información del contacto</div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="productor.empresa.nombre_contacto"
                :rules="[r.name, r.max()]"
                label="Nombre"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="productor.empresa.telefono_contacto"
                :rules="[r.max(254)]"
                label="Teléfono"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="productor.empresa.email_contacto"
                type="email"
                :rules="[r.email]"
                label="Correo"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="productor.estatus"
            :left-arrow="false"
            :right-arrow="true"
            :text="'Guardar y Continuar'"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'El productor ya existe'"
      :description="'La identificación colocada corresponde a un productor existente, desea cargar la información de este productor?'"
      :modal="modal_registro_existe"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { telefono_persona, formulario_de_productor } from '@/modelos'
import * as rules from '@/utils/validations'
import {
  getCurrentPosition,
  clona,
  edad,
  elObjetoTienePropiedades,
} from '../../utilidades'

export default {
  name: 'Productor',
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      tiene_empresa: false,
      registro_existente: {},
      productor: clona(formulario_de_productor),
      loading: false,
    }
  },
  computed: {
    // retorna cierto si existe al menos un número en el arreglo de numeros del productor
    puedeRemoverNumero() {
      return this.productor.numeros.length >= 0
    },
    puedeRemoverEmpresa() {
      return this.productor.empresa.length >= 0
    },
    edad() {
      return edad(this.productor.fecha_nacimiento)
    },
    puedeGuardar() {
      return (
        this.obtenerIdDeRuta() === '' ||
        this.obtenerIdDeRuta() === this.productor.id
      )
    },
    ...mapState('listados', [
      'sino',
      'tipo_empresa',
      'pais',
      'sexo',
      'tipo_telefono',
      'organizacion',
      'escolaridad',
      'tipo_organizacion',
      'tipo_identificacion',
      'institucion',
      'sistema_gestion_calidad',
      'actividad',
      'estado',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
    ]),
    ...mapGetters('registros', ['productor_actual', 'existeRegistroEnState']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'productor.tipo_empresa'() {
      if (this.productor.tipo_empresa === 2) {
        this.tiene_empresa = true
      } else {
        this.tiene_empresa = false
      }
    },
    'productor.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_distrito = ''
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_poblado = ''
      }
    },
    'productor.empresa.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_distrito = ''
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_poblado = ''
      }
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Productor')

    // si un id viene como parte de la ruta, intentar cargar el productor con ese id del almacenamiento local
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.$store.commit('registros/productorActual', id)
      this.$store.commit('registros/definirTipoProductor')
      this.cargarRegistro(id)
    } else {
      this.productor = clona(formulario_de_productor)
    }
  },
  methods: {
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicionDeEmpresa() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.empresa.longitud = position.coords.longitude
        this.productor.empresa.latitud = position.coords.latitude
      }
    },
    async obtenerPosicionProductor() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.longitud = position.coords.longitude
        this.productor.latitud = position.coords.latitude
      }
    },
    // agrega un objeto de número al arreglo de números del productor
    agregarNumero() {
      this.productor.numeros.push({ ...telefono_persona })
    },
    // remueve un número del arreglo de números
    removerNumero(index) {
      if (this.puedeRemoverNumero) {
        this.productor.numeros.splice(index, 1)
      }
    },
    // registra el productor en el almacenamiento local, lo coloca como productor actual y pasa a la siguiente pantalla
    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) {
          return
        }

        if (!this.obtenerIdDeRuta()) {
          this.productor.created_at = new Date().getTime() / 1000
        }
        this.productor.updated_at = new Date().getTime() / 1000

        await this.$store.dispatch(
          'registros/guardarProductor',
          clona(this.productor)
        )

        this.$router.push('/ru/fincas')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('productores', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/productor/${id}`)
      this.cerrarModalDeRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('productores', id)) {
        this.productor = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) {
          return false
        }
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id = this.productor.id
        // required fields to be fulfilled
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarProductores',
            { id }
          )
          if (status === 'ok' && data.length === 1) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getExpediente', registro.id)
        return true
      } catch (e) {
        return false
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    obtenerRegistroDelState(id) {
      return {
        ...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productores[id]),
      }
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalDeRegistroExistente() {
      this.modal_registro_existe = false
    },
  },
}
</script>
