<template>
  <v-dialog v-model="dialog" persistent max-width="350px">
    <template v-slot:activator="{ on }">
      <v-menu offset-y>
        <template v-slot:activator="_">
          <v-btn
            class="grey--text text--darken-2 mt-n2"
            :depressed="!disabled"
            :text="disabled"
            :disabled="disabled"
            v-on="_.on"
          >
            {{ units[unitsData[group].config.end_unit].acr }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(unit, key) in units"
            v-show="key !== unitsData[group].config.end_unit"
            :key="key"
            v-on="on"
          >
            {{ unit.acr.toUpperCase() }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-card v-if="!disabled">
      <v-card-title
        >Convertir {{ units[selectedUnit].name || '' }}</v-card-title
      >
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="unit_value"
            :rules="[r.required, r.float]"
            outlined
            :suffix="selectedUnit"
            label="Cantidad"
            @keyup.enter="transform"
          />
          <v-btn
            v-for="(unit, key) in units"
            v-show="Object.keys(units).length > 2"
            :key="unit.name"
            class="mb-2 mx-1"
            outlined
            :class="selectedUnit === key ? 'primary--text' : ''"
            @click="selectedUnit = key"
          >
            {{ unit.name }}
          </v-btn>
        </v-form>
        <v-row>
          <v-btn text @click="dialog = false"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn text class="primary--text" @click="transform">
            A {{ units[unitsData[group].config.end_unit].name }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as r from '@/utils/validations'
import { toFixed, clona } from '@/utilidades'

export default {
  props: {
    group: {
      type: String,
      default: 'to_kg',
    },
    unitsData: {
      type: Object,
      default: () => ({
        to_kg: {
          config: {
            end_unit: 'kg',
            default: 'lb',
          },
          units: {
            lb: { name: 'libras', acr: 'lb', op: '/', value: 2.2 },
            kg: { name: 'kilogramos', acr: 'kg', op: '*', value: 1 },
          },
        },
        to_l: {
          config: {
            end_unit: 'l',
            default: 'l',
          },
          units: {
            l: { name: 'litro(s)', acr: 'litro(s)', op: '*', value: 1 },
          },
        },
        to_tuca: {
          config: {
            end_unit: 'tuca',
            default: 'tuca',
          },
          units: {
            tuca: { name: 'tuca(s)', acr: 'tuca(s)', op: '*', value: 1 },
          },
        },
        'to_x 12': {
          config: {
            end_unit: 'x12',
            default: 'x12',
          },
          units: {
            x12: { name: 'Docena', acr: 'x12', op: '*', value: 1 },
          },
        },
        to_ha: {
          config: {
            end_unit: 'ha',
            default: 'ha',
          },
          units: {
            ha: { name: 'Hectárea', acr: 'ha', op: '*', value: 1 },
          },
        },
      }),
    },
  },
  data() {
    return {
      r,
      selectedUnit: null,
      unit_value: 0,
      dialog: false,
    }
  },
  computed: {
    units() {
      const units = clona(this.unitsData[this.group].units)
      return units
    },
    disabled() {
      return Object.keys(this.units).length === 1
    },
  },
  created() {
    this.selectedUnit = this.unitsData[this.group].config.default
  },
  methods: {
    transform() {
      if (!this.$refs.form.validate()) {
        this.$store.commit('ui/setSnack', {
          text: 'Valor inválido',
          color: 'error',
        })
        return ''
      }
      const unit = this.units[this.selectedUnit]
      if (unit.op === '/') {
        this.$emit(
          'transform',
          toFixed(parseFloat(this.unit_value) / unit.value, 2)
        )
      } else if (unit.op === '*') {
        this.$emit(
          'transform',
          toFixed(parseFloat(this.unit_value) * unit.value, 2)
        )
      } else {
        this.$emit('transform', 0)
      }
      this.unit_value = 0
      this.dialog = false
    },
  },
}
</script>
