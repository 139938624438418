<template>
  <v-container>
    <v-col cols="12"> </v-col>
    <v-row dense>
      <v-col cols="12">
        <div class="text-h6">Información de la Empresa</div>
      </v-col>
      <v-col cols="12">
        <div class="font-weight-medium blue-grey--text">
          * Campos requeridos
        </div>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="empresa.ruc"
          :rules="[r.required, r.max()]"
          label="RUC*"
          outlined
          @input="debounceBuscarRegistroExistenteRuc"
        />
      </v-col>
      <v-col cols="12">
        <!-- <template v-for="(num, index) in productor.empresa"> -->
        <v-row dense>
          <v-col cols="12">
            <div class="text-h6">Información de la Empresa</div>
          </v-col>
          <v-col cols="12">
            <form-meta
              :data="empresa"
              :remove-commit="'removerEmpresa'"
              :record-id="empresa.ruc"
              @removed="$router.go(-1)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.dv"
              :rules="[r.required, r.max(2)]"
              label="DV*"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="empresa.nombre"
              :rules="[r.required, r.max()]"
              label="Nombre de la empresa*"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="empresa.direccion"
              :rules="[r.max(1000)]"
              label="Dirección"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.telefono"
              :rules="[
                r.min_num(1000000),
                r.max_num(99999999),
                (v) => !!empresa.telefono || 'Coloque el número de teléfono',
              ]"
              label="Teléfono"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.apartado_postal"
              :rules="[r.max()]"
              label="Apartado postal"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.email"
              :rules="[r.email]"
              label="Correo"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.sitio_web"
              :rules="[r.max(128)]"
              label="Sitio web"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="empresa.id_provincia"
              :rules="[r.required]"
              :items="provincias"
              item-text="NOMBRE_PROVINCIA"
              item-value="ID_PROVINCIA"
              label="Zona*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="empresa.id_distrito"
              :rules="[r.required]"
              :items="distritos(empresa.id_provincia)"
              item-text="NOMBRE_DISTRITO"
              item-value="ID_DISTRITO"
              label="Estado*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="empresa.id_corregimiento"
              :rules="[r.required]"
              :items="corregimientos(empresa.id_distrito)"
              item-text="NOMBRE_CORREGIMIENTO"
              item-value="ID_CORREGIMIENTO"
              label="Municipio*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="empresa.id_poblado"
              :rules="[r.required]"
              :items="poblados(empresa.id_corregimiento)"
              item-text="NOMBRE_POBLADO"
              item-value="ID_POBLADO"
              label="Localidad*"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <div class="text-h6">Ubicación (WGS84)</div>
          </v-col>
          <v-col cols="12">
            <v-btn block @click="obtenerPosicionDeEmpresa">
              <v-icon left> mdi-crosshairs </v-icon>
              Registrar ubicación actual
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.longitud"
              :rules="[r.x_coord]"
              label="Longitud"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="empresa.latitud"
              :rules="[r.y_coord]"
              label="Latitud"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="empresa.actividad"
              :items="actividad"
              label="Cuál es su principal actividad"
              outlined
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="empresa.anios_actividad"
              :rules="[r.int, r.min_num(0), r.max_num(150)]"
              label="Años"
              outlined
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model.number="empresa.meses_actividad"
              :rules="[r.int]"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              label="Meses"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="empresa.certificaciones"
              multiple
              :items="sistema_gestion_calidad"
              label="¿Su empresa ha sido certificada por algún Sistema de Gestión de Calidad?"
              block
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="empresa.organizaciones"
              multiple
              :items="institucion"
              item-text="descripcion"
              item-value="id"
              label="Organizaciones a la que pertenece"
              block
              outlined
            />
          </v-col>
          <v-col cols="12">
            <div class="text-h7">Información del contacto</div>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="empresa.nombre_contacto"
              :rules="[r.name, r.max()]"
              label="Nombre"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="empresa.telefono_contacto"
              :rules="[
                r.min_num(1000000),
                r.max_num(99999999),
                (v) =>
                  !!empresa.telefono_contacto ||
                  'Coloque el número de teléfono',
              ]"
              label="Teléfono"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="empresa.email_contacto"
              type="email"
              :rules="[r.email]"
              label="Correo"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="text-h6">Información del Productor</div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="productor.id"
              placeholder="#-###-###"
              label="Cédula o Pasaporte del Productor o Representante*"
              append-icon="mdi-account"
              outlined
              @input="debounceBuscarRegistroExistente"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.primer_nombre"
              label="Primer nombre*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.primer_apellido"
              label="Primer apellido*"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="tipo_identif"
              label="Tipo de identificación*"
            />
          </v-col>
          <!-- 
          <v-col cols="6">
            <v-text-field readonly :value="estadito" label="Estado*" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="tipo_personita"
              label="Tipo de persona*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.segundo_nombre"
              label="Segundo nombre"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.segundo_apellido"
              label="Segundo apellido"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.apellido_conyugue"
              label="Apellido de conyugue"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Fecha de nacimiento*"
              :value="productor.fecha_nacimiento"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field :value="edad" label="Edad" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.direccion"
              label="Dirección*"
              counter="1000"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="provinciaPorId(productor.id_provincia)"
              label="Provincia*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="distritoPorId(productor.id_distrito)"
              label="Distrito*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="corregimientoPorId(productor.id_corregimiento)"
              label="Corregimiento*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="pobladoPorId(productor.id_poblado)"
              label="Poblado*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.numero_casa"
              label="Número de casa o departamento"
            />
          </v-col>
          <v-col cols="12">
            <div class="text-h6">Ubicación (WGS84)</div>
          </v-col>
          <v-col cols="12">
            <v-btn block>
              <v-icon left> mdi-crosshairs </v-icon>
              Ubicación actual
            </v-btn>
            <div class="text-center">
              Registrar la ubicación solo al encontrase en la residencia del
              productor.
            </div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.longitud"
              label="Longitud"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field readonly :value="productor.latitud" label="Latitud" />
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.id_nacionalidad"
              :items="pais"
              item-text="NOMBRE"
              item-value="ISO"
              label="Nacionalidad*"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field :value="productor.email" label="Correo" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="sexoPorId(productor.sexo)"
              readonly
              label="Sexo*"
            />
          </v-col> -->
          <!-- listado dinámico de números de teléfono -->
          <!-- <v-col cols="12">
            <v-btn block @click="agregarNumero">
              <v-icon left> mdi-phone </v-icon>
              Agregar número
            </v-btn>
          </v-col>
          <template v-for="(numero, index) in productor.numeros">
            <v-col :key="`${index}tipo`" cols="5">
              <v-text-field
                readonly
                :value="numero.tipo_telefono"
                label="Tipo"
              />
            </v-col>
            <v-col :key="`${index}nu`" cols="7">
              <v-text-field readonly :value="numero.telefono" label="Número">
              </v-text-field>
            </v-col>
          </template>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.tiene_organizacion"
              label="¿Pertecene a una organización asociativa?*"
            />
          </v-col>
          <v-col v-if="productor.tiene_organizacion === 1" cols="12">
            <v-text-field
              readonly
              :value="productor.id_organizacion"
              label="Organización"
            />
          </v-col> -->
          <!-- Muestra campos de nombre de organización y tipo de organización si la organización no existe en el listado (-1) -->
          <!-- <template
            v-if="
              productor.id_organizacion === 1 &&
              productor.tiene_organizacion === 1
            "
          >
            <v-col cols="12">
              <v-text-field
                readonly
                :value="productor.nombre_organizacion"
                label="Nombre de la organización*"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.registro_organizacion"
                label="Registro*"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                readonly
                :value="tipoOrganizacionPorId(productor.tipo_organizacion)"
                label="Tipo*"
              />
            </v-col>
          </template>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.escolaridad"
              :items="escolaridad"
              label="Escolaridad"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.actividad"
              :items="actividad"
              label="Cuál es su principal actividad"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              readonly
              :value="productor.anios_actividad"
              label="Años"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              readonly
              :value="productor.meses_actividad"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              label="Meses"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field readonly :value="sino_leer" label="Sabe leer" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="sino_escribir"
              label="Sabe escribir"
            />
          </v-col>
          <v-col cols="12">
            <div class="text-h6">Observaciones</div>
          </v-col>
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.observacion"
              label="Observaciones"
            ></v-text-field>
          </v-col>  -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <save-btn
          :record-id="empresa.ruc"
          :text="'Guardar y Continuar'"
          :loading="loading"
          @click="guardarYContinuar"
        />
      </v-col>
    </v-row>
    <registro-noexistente
      :title="'El productor no existe'"
      :description="'La identificación colocada corresponde a un productor no existente, verifique la información o cree el productor en la sección de Productores en el menú principal.'"
      :modal="modal_registro_existe"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistente"
    />
    <registro-noexistente
      :title="'Esta Empresa ya Existe'"
      :description="'El RUC colocado corresponde a una Empresa ya existente, verifique la información.'"
      :modal="modal_registro_existe_Ruc"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistenteRuc"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import {
  //telefono_persona,
  //formulario_de_productor,
  formulario_empresa,
} from '@/modelos'
import * as rules from '@/utils/validations'
import {
  getCurrentPosition,
  clona,
  edad,
  elObjetoTienePropiedades,
} from '../../utilidades'
import { formulario_de_productor } from '../../modelos'

const existing_empresa = {
  ruc: '',
}
export default {
  name: 'CrearEmpresa',
  data: () => {
    return {
      //NO SE USA
      nuevaEmpresa: {
        ruc: '',
        dv: '',
        nombre: '',
        direccion: '',
        telefono: '',
        apartado_postal: '',
        email: '',
        sitio_web: '',
        id_provincia: '',
        id_distrito: '',
        id_corregimiento: '',
        id_poblado: '',
        longitud: '',
        latitud: '',
        actividad: '',
        anios_actividad: '',
        meses_actividad: '',
        certificaciones: '',
        organizaciones: '',
        nombre_contacto: '',
        telefono_contacto: '',
        email_contacto: '',
      },
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_Ruc: false,
      modal_registro_existe_loading: false,
      //
      tipo_personita: '',
      tipo_identif: '',
      estadito: '',
      sino_leer: '',
      sino_escribir: '',
      //
      tiene_empresa: true,
      registro_existente: {},
      existing_empresa: clona(existing_empresa),
      empresa: clona(formulario_empresa),
      productor: clona(formulario_de_productor),
      loading: false,
    }
  },
  computed: {
    // retorna cierto si existe al menos un número en el arreglo de numeros del productor
    //puedeRemoverNumero() {
    //   return this.productor.numeros.length >= 0
    //},
    puedeRemoverEmpresa() {
      return this.empresa.length >= 0
    },
    edad() {
      return edad(this.productor.fecha_nacimiento)
    },
    puedeGuardar() {
      return (
        this.obtenerIdDeRuta() === '' ||
        this.obtenerIdDeRuta() === this.empresa.ruc
      )
    },
    ...mapState('listados', [
      'sino',
      'tipo_empresa',
      'pais',
      'sexo',
      'tipo_telefono',
      'organizacion',
      'escolaridad',
      'tipo_organizacion',
      'tipo_identificacion',
      'institucion',
      'sistema_gestion_calidad',
      'actividad',
      'estado',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
      'sexoPorId',
      'tipoOrganizacionPorId',
      'programaGobiernoPorId',
      'organizacionPorId',
    ]),
    ...mapGetters('registros', ['productor_actual', 'existeRegistroEnState']),
    ...mapState('registros', ['productor']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'empresa.id_productor'() {
      if (!this.productor.nombre) {
        this.productor.id = this.empresa.id_productor
      }
    },
    'productor.tipo_empresa'() {
      if (this.productor.tipo_empresa === 2) {
        this.tiene_empresa = true
        this.tipo_personita = 'PERSONA JURIDICA'
      } else {
        this.tiene_empresa = false
        this.tipo_personita = 'PERSONA NATURAL'
      }
    },
    'productor.tipo_id'() {
      if (this.productor.tipo_id === 1) {
        this.tipo_identif = 'CEDULA'
      } else {
        this.tipo_identif = 'PASAPORTE'
      }
    },
    'productor.estado'() {
      if (this.productor.estado === 1) {
        this.estadito = 'ACTIVO'
      } else {
        this.estadito = 'INACTIVO'
      }
    },
    'productor.sabe_leer'() {
      if (this.productor.sabe_leer === 0) {
        this.sino_leer = 'NO'
      } else {
        this.sino_leer = 'SI'
      }
    },
    'productor.sabe_escribir'() {
      if (this.productor.escribir === 0) {
        this.sino_escribir = 'NO'
      } else {
        this.sino_escribir = 'SI'
      }
    },
    /*'productor.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_distrito = ''
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_poblado = ''
      }
    },*/
    'empresa.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.empresa.id_distrito = ''
        this.empresa.id_corregimiento = ''
        this.eempresa.id_poblado = ''
      }
    },
    'empresa.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.empresa.id_corregimiento = ''
        this.empresa.id_poblado = ''
      }
    },
    'empresa.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.empresa.id_poblado = ''
      }
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Empresa')
    //Siempre usa id como variable aunque lo que venga sea el Ruc u otro.
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.empresa = clona(formulario_empresa)
      this.productor = clona(formulario_de_productor)
    }
  },
  methods: {
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicionDeEmpresa() {
      const position = await getCurrentPosition()
      if (position) {
        this.empresa.longitud = position.coords.longitude
        this.empresa.latitud = position.coords.latitude
      }
    },
    //NO SE USA
    async obtenerPosicionProductor() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.longitud = position.coords.longitude
        this.productor.latitud = position.coords.latitude
      }
    },
    // agrega un objeto de número al arreglo de números del productor
    // agregarNumero() {
    //   this.productor.numeros.push({ ...telefono_persona })
    // },
    // remueve un número del arreglo de números
    // removerNumero(index) {
    //  if (this.puedeRemoverNumero) {
    //    this.productor.numeros.splice(index, 1)
    //  }
    //},
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    cargarRegistro(id) {
      //Se carga el RUC y despues el PRODUCTOR
      if (this.existeRegistroEnState('empresas', id)) {
        this.empresa = this.obtenerRegistroDelStateRuc(id)
        //Esto es Nuevo
        //carga cualquier productor aunque no este en el listado
        this.productor.id = this.empresa.id_productor
        //alert(this.productor.id)
        //AQUI debo verificar si existe State offline Productores,
        this.productor = this.obtenerRegistroDelState(this.productor.id)
        if (
          this.productor.nombre == '' ||
          this.productor.nombre == 'undefined'
        ) {
          //Sino PRODUCTOR verifica y lo carga del server
          this.cargarRegistroDelServer(this.empresa.id_productor)
          return true
        } else {
          if (
            this.productor.nombre == '' ||
            this.productor.nombre == 'undefined'
          ) {
            return false
          } else {
            return true
          }
        }
      } else {
        //dar msg que Empresa No existe y debe ser creada como NUEVA
        alert('Esta cédula NO EXISTE')
        return false
      }
    },
    //busca al PRODUCTOR del Server y lo carga
    async buscarRegistroExistente() {
      //Productor o (es) del Server por la variable enviada
      const registro = await this.obtenerUnRegistroONadaUNO()
      //const pruebita = registro.id
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        //alert(this.productor.id)
        if (id === this.productor.id) {
          //this.productor = this.registro
          this.almacenarRegistroEncontradoTemporalmente(registro)
          return true
        }
      } else {
        this.mostrarModalDeRegistroExistente()
        return false
      }
    },
    obtenerRegistroDelStateRuc(id) {
      return {
        //...clona(formulario_empresa),
        ...clona(this.$store.state.registros.empresas[id]),
      }
    },
    //busca el productor en el State productores
    obtenerRegistroDelState(id) {
      return {
        //...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productores[id]),
      }
    },
    //NO SE USA
    /*async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('empresas', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/productor/crearempresa/${id}`)
      this.cerrarModalDeRegistroExistente()
      this.modal_registro_existe_loading = false
    },*/
    //Busca Productor en el Server
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    //SOLO para Validar la existencia del RUC en el Server
    async buscarRegistroExistenteRuc() {
      //lista 1 RUC del server por la variable ruc enviada
      const registro = await this.obtenerEmpresasPorRegistro()
      //alert(registro.ruc)
      if (elObjetoTienePropiedades(registro)) {
        this.mostrarModalDeRegistroExistenteRuc()
        const ruc = registro.id ? registro.id.toString() : ''
        //if (id === this.obtenerIdDeRuta()) {
        if (ruc === this.empresa.ruc) {
          this.mostrarModalDeRegistroExistenteRuc()
          return true
        }
        return false
      } else {
        return false
      }
    },
    debounceBuscarRegistroExistenteRuc: debounce(async function () {
      await this.buscarRegistroExistenteRuc()
    }, 1500),
    /*async obtenerUnRegistroONada() {
      try {
        const num_registro = this.finca.num_registro
        const nombre = this.finca.nombre
        const id_productor = this.finca.id_productor
        const id = this.productor.id
        // required fields to be fulfilled
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarProductores',
            { id }
          )
          if (status === 'ok' && data.length === 1) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },*/
    //Obtiene el PRODUCTOR del Server
    async obtenerUnRegistroONadaUNO() {
      try {
        let pap = this.productor.id
        if (!pap) {
          pap = this.empresa.id_productor
          this.productor.id = this.empresa.id_productor
        } else {
          this.empresa.id_productor = this.productor.id
        }
        const id = pap
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/traersolouno',
            { id }
          )
          if (status === 'ok' && data.length > 0) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    //obtiene un RUC existente
    async obtenerEmpresasPorRegistro() {
      try {
        this.existing_empresa = clona(existing_empresa)
        const ruc = this.empresa.ruc
        // required fields to be fulfilled
        if (ruc) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmpresas',
            { ruc }
          )
          if (status === 'ok' && data.length > 0) {
            if (data[0].ruc !== this.empresa.ruc) {
              this.existing_empresa = clona({
                ruc: this.empresa.ruc,
              })
            } else {
              this.empresa.ruc = data[0].ruc
              return data[0]
            }
          }
        }
        return {}
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    //NO SE USA
    async obtenerUnRegistroONadaRuc() {
      try {
        //const id_productor = this.empresa.id_productor
        const ruc = this.empresa.ruc
        // Verifica RUC solo regresa un registro del RUC verificado
        if (ruc) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmpresas',
            { ruc }
          )
          if (status === 'ok' && data.length > 0) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    //NO SE USA
    async cargarRegistroDelServerRuc(directa) {
      try {
        await this.$store.dispatch('registros/getEmpresa', directa)
        return true
      } catch (e) {
        return false
      }
    },
    //Carga al PRODUCTOR desde el server
    async cargarRegistroDelServer(ppp) {
      try {
        if (!ppp) return false
        this.productor = await this.$store.dispatch(
          'registros/getConsulta',
          ppp
        )
        this.productor.id = this.empresa.id_productor
        return true
      } catch (e) {
        return false
      }
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
      this.productor = registro
    },
    // registra el productor en el almacenamiento local, lo coloca como productor actual y pasa a la siguiente pantalla
    async guardarYContinuar() {
      try {
        this.loading = true
        /*if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }*/
        if (await this.buscarRegistroExistenteRuc()) {
          return
        }
        //obtenerIdruta id que es el RUC de la empresa, si es nueva le pone fecha de creacion
        if (!this.obtenerIdDeRuta()) {
          this.empresa.created_at = new Date().getTime() / 1000
        }
        this.empresa.updated_at = new Date().getTime() / 1000

        this.$store.dispatch('registros/guardarEmpresa', clona(this.empresa))
        this.$router.push('/ru/productor/empresa')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    mostrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = true
    },
    cerrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = false
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalDeRegistroExistente() {
      this.modal_registro_existe = false
    },
  },
}
</script>
