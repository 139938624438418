<template>
  <v-container>
    <v-row dense>
      <!-- listado de actividades pecuarias en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="pecuaria in pecuariasc">
            <form-card
              :key="pecuaria.id"
              :top-text="`Finca • ${getNombreDeFincas(pecuaria.id_finca)}`"
              :top-right-text="pecuaria.fecha"
              :middle-text="`Ciclo ${pecuaria.ciclo}`"
              :bottom-right-text="producerDescription(pecuaria.productor)"
              :sincronized="pecuaria.sincronizado"
              :opaque="pecuaria.hasOutdatedRecords"
              @click="traerRegistroYNavegar(pecuaria)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Copecuarios',
  computed: {
    ...mapState('registros', ['pecuariasc']),
    ...mapGetters('registros', ['getNombreDeFincas']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Pecuarias')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getPecuarias', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/copecuario/${registro.id}`)
    },
  },
}
</script>
