<template>
  <div>
    <v-icon v-if="!!icon" left :class="className" v-text="icon"></v-icon>
    <span
      :class="[
        className,
        {
          'font-weight-bold': bold,
        },
      ]"
      class="text-body-2"
    >
      <slot name="default" />
    </span>
    <v-text-field v-show="false" :rules="[(v) => !fixRequired]"></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    fixRequired: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.warning
        ? 'mdi-alert-circle-outline'
        : this.error
        ? 'mdi-close-circle-outline'
        : ''
    },
    className() {
      return this.warning ? 'warning--text' : this.error ? 'error--text' : ''
    },
  },
}
</script>
