<template>
  <v-container>
    <v-row dense>
      <!-- listado de parcelas en el almacenamiento local -->
      <v-col cols="12">
        <v-list two-line>
          <template v-for="registro in registros">
            <form-card
              :key="registro.id"
              :top-text="`${registro.nombre || '(sin nombre)'}`"
              :bottom-right-text="producerDescription(registro.productor)"
              :middle-text="`Finca • ${getNombreDeFincas(registro.id_finca)}`"
              :sincronized="registro.sincronizado"
              @click="traerRegistroYNavegar(registro)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Coparcelas',
  computed: {
    ...mapState('registros', {
      registros: 'parcelasc',
      fincas: 'fincasc',
    }),
    ...mapGetters('registros', ['getNombreDeFincas']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Parcelas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getParcelas', { id: registro.id })
      }
      this.$router.push(`/ru/coparcela/${registro.id}`)
    },
  },
}
</script>
