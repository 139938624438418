<template>
  <v-app>
    <e-drawer />
    <e-toolbar />
    <v-main>
      <v-btn
        v-show="!online"
        block
        depressed
        :ripple="false"
        class="warning"
        small
        >Sin conexión a internet</v-btn
      >
      <v-container fluid class="pa-0">
        <v-row>
          <v-col
            cols="12"
            :sm="en_reportes ? 12 : 8"
            :offset-sm="en_reportes ? 0 : 2"
            :xl="en_reportes ? 12 : 6"
            :offset-xl="en_reportes ? 0 : 3"
          >
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog :value="offline_modal" persistent max-width="400">
      <v-card class="warning">
        <v-card-title>Intente más tarde...</v-card-title>
        <v-card-text
          >La acción que está intentando ejecutar requiere de conexión a
          internet.</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="$store.commit('ui/updateOfflineModal', false)"
            >Entiendo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="unauthorized_modal" persistent max-width="400">
      <v-card class="px-3">
        <v-img
          src="img/icons/android-launchericon-512-512.png"
          height="150"
          contain
        ></v-img>
        <v-text-field
          v-model="email"
          :rules="[r.email]"
          type="email"
          outlined
          append-icon="mdi-account"
          label="Correo"
          autofocus
          @keyup.enter="iniciarSesion"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[r.max()]"
          :type="see_pass ? 'text' : 'password'"
          outlined
          :append-icon="see_pass ? 'mdi-eye-off' : 'mdi-eye'"
          label="Contraseña"
          @keyup.enter="iniciarSesion"
          @click:append="see_pass = !see_pass"
        ></v-text-field>
        <v-card-actions>
          <v-btn class="error--text" text @click="cerrarModalAuth"
            >Cancelar</v-btn
          >
          <v-spacer />
          <v-btn
            :loading="loading"
            text
            class="primary--text"
            @click="iniciarSesion"
            >Iniciar sesión</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="old_record_modal" persistent max-width="400">
      <v-card dark class="error">
        <v-card-title>Existen datos más recientes</v-card-title>
        <v-card-text>
          Existen datos más recientes para el registro o registros que se
          quieren sincronizar. Los registros que no se sincronizan se marcan con
          un signo de exclamación.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="$store.commit('ui/updatedOldRecordModal', false)"
            >Entendido</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="existing_record_modal" persistent max-width="400">
      <v-card dark class="error">
        <v-card-title>Registro repetido</v-card-title>
        <v-card-text>
          Existe un registro en la base de datos para los datos que se quieren
          registrar. Los registros que no se sincronizaron se marcarán con un
          signo de exclamación.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="$store.commit('ui/updatedExistingRecordModal', false)"
            >Entendido</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.show"
      bottom
      right
      :timeout="snack.timeout"
      :color="snack.color"
      >{{ snack.text }}
      <v-btn text @click="closeSnack" v-text="'Cerrar'"> </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import EToolbar from './components/EToolbar'
import EDrawer from './components/EDrawer'
import * as rules from '@/utils/validations'

export default {
  components: {
    EToolbar,
    EDrawer,
  },
  data: () => {
    return {
      r: rules,
      lb: '',
      kg: '',
      email: '',
      password: '',
      loading: false,
      see_pass: false,
    }
  },
  computed: {
    en_reportes() {
      return this.$route.path.includes('/ru/reportes')
    },
    snack: {
      get() {
        return this.$store.state.ui.snack
      },
      set(value) {
        this.$store.commit('ui/setSnack', value)
      },
    },
    ...mapState('ui', [
      'offline_modal',
      'unauthorized_modal',
      'old_record_modal',
      'existing_record_modal',
      'online',
    ]),
  },
  async mounted() {
    this.$store.dispatch('registros/ping')
    this.$store.dispatch('app/registrarPosicionActual')
    this.$store.commit('registros/definirTipoProductor')
    await this.$store.dispatch('listados/sincronizar')
  },
  methods: {
    // verifica que las credenciales ingresadas por el usuario sean correctas.
    // en caso de éxito redirige al listado de productores
    async iniciarSesion() {
      try {
        this.loading = true
        const success = await this.$store.dispatch('auth/verifyCredentials', {
          email: this.email,
          password: this.password,
        })
        this.loading = false
        if (success) {
          this.cerrarModalAuth()
          this.$store.commit('ui/updateEDrawer', true)
        } else {
          this.$store.commit('ui/setSnack', {
            color: 'error',
            text: 'Correo o contraseña incorrecta',
          })
        }
      } catch (e) {
        this.loading = false
      }
    },
    closeSnack() {
      this.$store.commit('ui/setSnack', { show: false })
    },
    cerrarModalAuth() {
      this.$store.commit('ui/updateUnauthorizedModal', false)
    },
  },
}
</script>
<style>
.md2-border {
  border: 1px solid #d8d8d8 !important;
  border-radius: 7px !important;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.v-input {
  margin-bottom: -10px !important;
}
.opaque {
  opacity: 0.5;
}
</style>
