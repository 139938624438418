<template>
  <v-container>
    <v-row dense>
      <!-- listado de preparaciones en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="preparacion in preparacionesc">
            <form-card
              :key="preparacion.id"
              :bottom-right-text="producerDescription(preparacion.productor)"
              :top-text="`Finca • ${getNombreDeFincas(preparacion.id_finca)}`"
              :top-right-text="preparacion.fecha"
              :middle-text="`Preparación de ${
                cultivo(preparacion.id_cultivo).descripcion
              }`"
              :bottom-text="`Coa ${preparacion.coa}`"
              :sincronized="preparacion.sincronizado"
              @click="traerRegistroYNavegar(preparacion)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Copreparaciones',
  computed: {
    ...mapState('registros', ['preparacionesc']),
    ...mapGetters('listados', ['cultivo']),
    ...mapGetters('registros', ['getNombreDeFincas']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Preparaciones')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getPreparaciones', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/copreparacion/${registro.id}`)
    },
  },
}
</script>
