<template>
  <div>
    <div v-text="item.nombre || 'Sin nombre...'"></div>
    <div
      class="grey--text"
      v-text="
        `${
          productores[item.id_productor]
            ? productores[item.id_productor].primer_nombre
            : ''
        } ${
          productores[item.id_productor]
            ? productores[item.id_productor].primer_apellido
            : ''
        } - ${item.id_productor}`
      "
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FincaSelectItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('registros', ['productores']),
  },
}
</script>
