export const modules = [
  'PRODUCTOR',
  'FAMILIA',
  'FINCA',
  'PARCELA',
  'ESTABLECIMIENTO',
  'ACUICULTURA',
  'SIEMBRA',
  'PREPARACION',
  'COSECHA',
  'AGROINDUSTRIA',
  'AGROTURISMO',
  'APICULTURA',
  'ARTESANIA',
  'FORESTAL',
  'PECUARIO',
  'PESCA',
  'EMBARCACION',
]

export const actions = ['CREAR', 'EDITAR']

export const productionModules = [
  'AGRICOLA',
  'ACUICULTURA',
  'APICULTURA',
  'ARTESANIA',
  'AGROINDUSTRIA',
  'FORESTAL',
  'PECUARIO',
  'PESCA',
]

export const coa = [1, 2, 3, 4, 5]
export const ciclo = [1, 2, 3, 4, 5, 6, 7, 8, 9]
