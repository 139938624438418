<template>
  <v-navigation-drawer
    app
    :value="e_drawer"
    width="300"
    @input="updateEDrawerState"
  >
    <v-app-bar dense dark color="primary" flat>
      <v-toolbar-title
        class="font-weight-bold text-subtitle-1"
        v-text="'SIGAP'"
      />
      <span class="text-caption ml-2 mt-1" v-text="version" />
    </v-app-bar>
    <!-- Información pertinente al usuario actual -->
    <v-list v-if="user.email" dense>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon
            v-if="!user.avatar"
            class="primary--text"
            v-text="'mdi-emoticon'"
          >
          </v-icon>
          <v-img v-else :src="`${url_base}/storage/${user.avatar}`"> </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="user.name"></v-list-item-title>
          <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-btn router to="/cuenta" class="primary--text" depressed block small>
          Cambiar contraseña
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn class="error--text" depressed block small @click="cerrarSesion">
          Cerrar sesión
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <!-- Información que se muestra cuando no hay ningún usuario actual en la aplicación -->
    <v-list v-else dense>
      <v-list-item @click="showLogin">
        <v-list-item-avatar>
          <v-icon class="primary--text" v-text="'mdi-alert-circle'"> </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="'Bienvenido'"></v-list-item-title>
          <v-list-item-subtitle
            v-text="'Inicie sesión para continuar'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- Lista con información pertinente al productor actual -->
    <v-list v-if="productor" dense>
      <v-subheader v-text="'Productor actual'" />
      <v-list-item router :to="`/ru/productor/${productor.id}`">
        <v-list-item-avatar>
          <v-icon v-text="'mdi-account-tie'" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-text="`${productor.primer_nombre} ${productor.primer_apellido}`"
          />
          <v-list-item-subtitle v-text="productor.id" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn class="grey--text" icon @click.prevent="quitarProductor">
            <v-icon v-text="'mdi-close'"></v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-btn
          router
          :to="`/ru/productor/${productor.id}/clasificacion`"
          small
          block
          depressed
          class="primary--text"
        >
          TIPO DE PRODUCTOR
        </v-btn>
      </v-list-item>
    </v-list>
    <!-- Listado con los enlaces a los distintos módulos del sistema incluyendo los reportes -->
    <v-list dense>
      <!-- Botón del listado para sincronizar todos los formularios pendientes por sincronizar en el servidor -->
      <v-divider inset />
      <v-list-item @click="sincronizarTodo">
        <v-list-item-icon>
          <v-icon v-text="'mdi-sync'" />
        </v-list-item-icon>
        <v-list-item-title v-text="`Sincronizar ${sync_all}`" />
      </v-list-item>
      <v-divider inset />
      <template v-for="(elemento, index) in elementos">
        <v-list-item
          v-if="!elemento.group && !elemento.divider"
          :key="`v-list-item-${index}`"
          router
          exact
          :to="elemento.ruta"
          :disabled="!userCanNavigate(elemento.ruta)"
        >
          <v-list-item-icon>
            <v-icon v-text="elemento.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="elemento.texto" />
        </v-list-item>
        <v-divider
          v-else-if="elemento.divider"
          :key="`v-divider-${index}`"
          :inset="elemento.inset"
        >
        </v-divider>
        <v-list-group
          v-else-if="elemento.group"
          :key="`v-list-group-${index}`"
          :prepend-icon="elemento.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title v-text="elemento.texto" />
          </template>
          <template v-for="child in elemento.child">
            <v-list-item
              :key="child.texto"
              router
              exact
              :to="child.ruta"
              :disabled="!userCanNavigate(child.ruta)"
            >
              <v-list-item-title v-text="child.texto" />
            </v-list-item>
          </template>
        </v-list-group>
      </template>
      <v-list-item @click="logearIMA(user)">
        <v-list-item-icon>
          <v-icon v-text="'mdi-table'" />
        </v-list-item-icon>
        <v-list-item-title v-text="`Reportes Comercialización`" />
      </v-list-item>
      <v-list-item href="/suag/public/admin">
        <v-list-item-icon>
          <v-icon v-text="'mdi-shield-lock'" />
        </v-list-item-icon>
        <v-list-item-title v-text="`Admin`" />
      </v-list-item>
      <v-list-item router to="/ru/listados">
        <v-list-item-icon>
          <v-icon v-text="'mdi-format-list-bulleted'" />
        </v-list-item-icon>
        <v-list-item-title v-text="`Listados offline`" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { url_base, rol_permitido } from '../utilidades'

// app version
const version = process.env.VUE_APP_VERSION
// roles a nivel de base de datos usados para definir
// que elementos mostrar en los listados
const registro = [1, 2, 5]
const reporte = [1, 3, 5]

export default {
  name: 'EDrawer',
  data: () => {
    return {
      version,
      url_base: url_base,
      registro: registro,
      reporte: reporte,
      elementos: [
        { texto: 'Productores', ruta: '/ru/productores', icon: 'mdi-account' },
        {
          texto: 'Empresa',
          ruta: '/ru/productor/empresa',
          icon: 'mdi-city',
        },
        {
          texto: 'Trabajo y Familia',
          ruta: '/ru/productor/familia',
          icon: 'mdi-account-group',
        },
        { texto: 'Fincas', ruta: '/ru/fincas', icon: 'mdi-warehouse' },
        { texto: 'Acuiculturas', ruta: '/ru/acuiculturas', icon: 'mdi-fish' },
        {
          texto: 'Agrícola',
          ruta: '',
          icon: 'mdi-leaf',
          child: [
            { texto: 'Preparaciones', ruta: '/ru/preparaciones' },
            { texto: 'Siembras', ruta: '/ru/cultivos' },
            { texto: 'Cosechas', ruta: '/ru/cosechas' },
          ],
          group: true,
        },
        {
          texto: 'Agroindustrias',
          ruta: '/ru/agroindustrias',
          icon: 'mdi-factory',
        },
        { texto: 'Agroturismos', ruta: '/ru/agroturismos', icon: 'mdi-home' },
        { texto: 'Apiculturas', ruta: '/ru/apiculturas', icon: 'mdi-water' },
        {
          texto: 'Artesanías',
          ruta: '/ru/artesanias',
          icon: 'mdi-chess-bishop',
        },
        { texto: 'Forestales', ruta: '/ru/forestales', icon: 'mdi-tree' },
        { texto: 'Pecuarios', ruta: '/ru/pecuarios', icon: 'mdi-cow' },
        {
          texto: 'Pesca artesanal',
          ruta: '',
          icon: 'mdi-ferry',
          child: [
            { texto: 'Embarcaciones', ruta: '/ru/embarcaciones' },
            { texto: 'Pescas', ruta: '/ru/pescas' },
          ],
          group: true,
        },
        { divider: true, inset: true },
        {
          texto: 'Consulta Expedientes',
          ruta: '/ru/productor/consulta',
          icon: 'mdi-warehouse',
        },
        {
          texto: 'Certificaciones del Productor',
          ruta: '/ru/productor/formularioconsul',
          icon: 'mdi-printer',
        },
        {
          texto: 'Reportes Operativos',
          icon: 'mdi-table',
          group: true,
          child: [
            { texto: 'Productores', ruta: '/ru/reportes/productores' },

            {
              texto: 'Producción Nacional',
              ruta: '/ru/reportes/productor/produccion-nacional',
            },
            {
              texto: 'Producción Detallada',
              ruta: '/ru/reportes/productor/produccion-detallada',
            },
            {
              texto: 'Producción Consolidada',
              ruta: '/ru/reportes/productor/produccion-consolidada',
            },
            {
              texto: 'Agricultura Familiar',
              ruta: '/ru/reportes/productor/agricultura-familiar',
            },
            {
              texto: 'Organizaciones y Productores Agrupados',
              ruta: '/ru/reportes/productor/organizaciones-y-productores',
            },
            {
              texto: 'Contratación y Beneficiados por Programas',
              ruta: '/ru/reportes/productor/contratacion-y-beneficiados',
            },
            {
              texto: 'Bitácora de Cambios',
              ruta: '/ru/reportes/productor/bitacora-de-cambios',
            },
            // { texto: 'Productor', ruta: '/ru/reportes/productor' },
            {
              texto: 'Trabajo y Familia',
              ruta: '/ru/reportes/productor/familia',
            },
            { texto: 'Finca > Rubros', ruta: '/ru/reportes/finca/rubro' },
            { texto: 'Finca > Tenencia', ruta: '/ru/reportes/finca/tenencia' },
            { texto: 'Acuicultura', ruta: '/ru/reportes/acuicultura' },
            { texto: 'Agroturismo', ruta: '/ru/reportes/agroturismo' },
            { texto: 'Apicultura', ruta: '/ru/reportes/apicultura' },
            { texto: 'Agroindustria', ruta: '/ru/reportes/agroindustria' },
            { texto: 'Artesanía', ruta: '/ru/reportes/artesania' },
            { texto: 'Forestal', ruta: '/ru/reportes/forestal' },
            { texto: 'Pecuario', ruta: '/ru/reportes/pecuario' },
            { texto: 'Pesca', ruta: '/ru/reportes/pesca' },
          ],
        },
        { divider: true, inset: true },
      ],
    }
  },
  computed: {
    e_drawer() {
      return this.$store.state.ui.e_drawer
    },
    ...mapState('ui', ['e_drawer']),
    ...mapState('registros', ['productor']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('registros', ['sync_all']),
  },
  methods: {
    userCan(route) {
      return this.$store.getters['auth/userCan'](route)
    },
    userCanNavigate(route) {
      if (!route) return false
      if (
        (route.includes('reportes') || route.includes('consulta')) &&
        this.userCan(route).read
      )
        return true
      else if (
        (!route.includes('reportes') || !route.includes('consulta')) &&
        this.userCan(route).browse
      )
        return true
      else return false
    },
    rol_permitido: rol_permitido,
    // actualiza el estado del drawer en el state al abrirlo o cerrarlo
    updateEDrawerState(state) {
      this.$store.commit('ui/updateEDrawer', state)
    },
    // llama a la acción cerrarSesión del módulo de auth del store y redirige al usuario al login
    async cerrarSesion() {
      await this.$store.dispatch('auth/cerrarSesion')
      this.$router.push('/bienvenido')
    },
    // deselecciona el productor actual y redefine el tipo de productor global
    quitarProductor() {
      this.$store.commit('registros/productorActual', '')
      this.$store.commit('registros/definirTipoProductor')
    },
    // sincroniza todos los formularios pendientes por sincronizar
    async sincronizarTodo() {
      try {
        await this.$store.dispatch('registros/sincronizarTodo')
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    logearIMA(user) {
      this.$store.dispatch('auth/logearIMA', user)
    },
    showLogin() {
      this.$store.commit('ui/updateUnauthorizedModal', true)
    },
  },
}
</script>
