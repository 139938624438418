import axios from 'axios'
import { url_base } from '../utilidades'

// módulo con todo el estado relacionado al los listados de información de la aplicación
export default {
  namespaced: true,
  state: {
    sino: [
      { id: 0, descripcion: 'No' },
      { id: 1, descripcion: 'Si' },
    ],
    sino_text: ['SI', 'NO'],
    lb_to_kg: 0.4535924,
    peso_default: 'kg',
    peso: ['kg', 'lb'],
    accesibilidad: [],
    actividad: [],
    actividad_trabajador: [],
    actividad_pecuaria: [],
    activo: [],
    agencia: [],
    clasificacion: [],
    corregimiento: [],
    dano: [],
    detalle_dano: [],
    denominacion_varietal: [],
    destino: [],
    distrito: [],
    escolaridad: [],
    especie_acuicola: [],
    especie_pesca: [],
    estado: [],
    estado_parcela: [],
    estado_establecimiento: [],
    estado_dano: [],
    evento: [],
    fuente_agua: [],
    institucion: [],
    pais: [],
    organizacion: [],
    parentesco: [],
    poblado: [],
    programa_gobierno: [],
    provincia: [],
    regional: [],
    cultivo: [],
    rubro_apicola: [],
    rubro_forestal: [],
    semilla: [],
    sexo: [],
    sistema_gestion_calidad: [],
    sistema_produccion: [],
    sub_tipo_embarcacion: [],
    tecnologia: [],
    tenencia_tierra: [],
    tipo_actividad: [],
    tipo_administracion: [],
    tipo_asistencia: [],
    tipo_embarcacion: [],
    tipo_entidad_aseguradora: [],
    tipo_entidad_financiera: [],
    tipo_establecimiento: [],
    tipo_identificacion: [],
    tipo_organizacion: [],
    tipo_prima_seguro: [],
    tipo_empresa: [],
    tipo_parcela: [],
    tipo_telefono: [],
    tipo_terreno: [],
    unidad_medida: [],
    variedad_cultivo: [],
    zona_pesca: [],
    estatus: [
      { text: 'Actualizado', value: 'A' },
      { text: 'Actualizado', value: null },
      { text: 'Desactualizado', value: 'D' },
      { text: 'Desactualizado', value: 'V' },
    ],
    tipo_familiar: [
      { text: 'I', value: 1 },
      { text: 'II', value: 2 },
      { text: 'III', value: 3 },
    ],
  },
  mutations: {
    // asigna a los distinos listados del state los listados provenientes de la base de datos
    cargarListados(state, server_response) {
      state.accesibilidad = [...server_response.accesibilidad]
      state.actividad_trabajador = [...server_response.actividad_trabajador]
      state.actividad = [...server_response.actividad]
      state.actividad_pecuaria = [...server_response.actividad_pecuaria]
      state.activo = [...server_response.activo]
      state.agencia = [...server_response.agencia]
      state.clasificacion = [...server_response.clasificacion]
      state.corregimiento = [...server_response.corregimiento]
      state.dano = [...server_response.dano]
      state.estado_dano = [...server_response.estado_dano]
      state.detalle_dano = [...server_response.detalle_dano]
      state.denominacion_varietal = [...server_response.denominacion_varietal]
      state.destino = [...server_response.destino]
      state.distrito = [...server_response.distrito]
      state.escolaridad = [...server_response.escolaridad]
      state.especie_acuicola = [...server_response.especie_acuicola]
      state.especie_pesca = [...server_response.especie_pesca]
      state.estado = [...server_response.estado]
      state.estado_parcela = [...server_response.estado_parcela]
      state.estado_establecimiento = [...server_response.estado_establecimiento]
      state.evento = [...server_response.evento]
      state.fuente_agua = [...server_response.fuente_agua]
      state.institucion = [...server_response.institucion]
      state.pais = [...server_response.pais]
      state.organizacion = [...server_response.organizacion]
      state.parentesco = [...server_response.parentesco]
      state.poblado = [...server_response.poblado]
      state.programa_gobierno = [...server_response.programa_gobierno]
      state.provincia = [...server_response.provincia]
      state.regional = [...server_response.regional]
      state.cultivo = [...server_response.cultivo]
      state.rubro_apicola = [...server_response.rubro_apicola]
      state.rubro_forestal = [...server_response.rubro_forestal]
      state.semilla = [...server_response.semilla]
      state.sexo = [...server_response.sexo]
      state.sistema_gestion_calidad = [
        ...server_response.sistema_gestion_calidad,
      ]
      state.sistema_produccion = [...server_response.sistema_produccion]
      state.sub_tipo_embarcacion = [...server_response.sub_tipo_embarcacion]
      state.tecnologia = [...server_response.tecnologia]
      state.tenencia_tierra = [...server_response.tenencia_tierra]
      state.tipo_actividad = [...server_response.tipo_actividad]
      state.tipo_administracion = [...server_response.tipo_administracion]
      state.tipo_asistencia = [...server_response.tipo_asistencia]
      state.tipo_embarcacion = [...server_response.tipo_embarcacion]
      state.tipo_entidad_aseguradora = [
        ...server_response.tipo_entidad_aseguradora,
      ]
      state.tipo_entidad_financiera = [
        ...server_response.tipo_entidad_financiera,
      ]
      state.tipo_establecimiento = [...server_response.tipo_establecimiento]
      state.tipo_identificacion = [...server_response.tipo_identificacion]
      state.tipo_organizacion = [...server_response.tipo_organizacion]
      state.tipo_prima_seguro = [...server_response.tipo_prima_seguro]
      state.tipo_empresa = [...server_response.tipo_empresa]
      state.tipo_telefono = [...server_response.tipo_telefono]
      state.tipo_parcela = [...server_response.tipo_parcela]
      state.tipo_terreno = [...server_response.tipo_terreno]
      state.unidad_medida = [...server_response.unidad_medida]
      state.variedad_cultivo = [...server_response.variedad_cultivo]
      state.zona_pesca = [...server_response.zona_pesca]
    },
  },
  actions: {
    // consulta el servidor por los listados de información de la base de datos
    async sincronizar(context) {
      if (navigator.onLine) {
        try {
          const server_response = await axios({
            url: url_base + '/api/listados',
            method: 'get',
          })
          context.commit('cargarListados', server_response.data)
        } catch (error) {
          this._vm.$sentry.captureException(error)
        }
      } else {
        context.rootState.ui.online = false
      }
    },
  },
  getters: {
    subTiposEmbarcacionPorId: (state) => (tipo_embarcacion) => {
      if (tipo_embarcacion) {
        return state.sub_tipo_embarcacion.filter(
          (sub_tipo) => sub_tipo.tipo_embarcacion === tipo_embarcacion
        )
      } else {
        return []
      }
    },
    provincias: (state) => {
      return state.provincia
    },
    provinciaPorId: (state) => (id_provincia) => {
      if (id_provincia) {
        return state.provincia.find(
          (provincia) => provincia.ID_PROVINCIA === id_provincia
        ).NOMBRE_PROVINCIA
      } else {
        return ''
      }
    },
    distritos: (state) => (id_provincia) => {
      return state.distrito.filter(
        (distrito) => distrito.ID_PROVINCIA === id_provincia
      )
    },
    distritoPorId: (state) => (id_distrito) => {
      if (id_distrito) {
        return state.distrito.find(
          (distrito) => distrito.ID_DISTRITO === id_distrito
        ).NOMBRE_DISTRITO
      } else {
        return ''
      }
    },
    corregimientos: (state) => (id_distrito) => {
      return state.corregimiento.filter(
        (corregimiento) => corregimiento.ID_DISTRITO === id_distrito
      )
    },
    corregimientoPorId: (state) => (id_corregimiento) => {
      if (id_corregimiento) {
        return state.corregimiento.find(
          (corregimiento) => corregimiento.ID_CORREGIMIENTO === id_corregimiento
        ).NOMBRE_CORREGIMIENTO
      } else {
        return ''
      }
    },
    poblados: (state) => (id_corregimiento) => {
      return state.poblado.filter(
        (poblado) => poblado.ID_CORREGIMIENTO === id_corregimiento
      )
    },
    pobladoPorId: (state) => (id_poblado) => {
      if (id_poblado) {
        return state.poblado.find(
          (poblado) => poblado.ID_POBLADO === id_poblado
        ).NOMBRE_POBLADO
      } else {
        return ''
      }
    },
    cultivo: (state) => (id_cultivo) => {
      return state.cultivo.find((rubro) => rubro.id === id_cultivo)
    },
    cultivoPorId: (state) => (id_cultivo) => {
      if (id_cultivo) {
        let cosa = state.cultivo.find((sara) => sara.id === id_cultivo)
        return cosa.descripcion
      } else {
        return ''
      }
    },
    sexoPorId: (state) => (id_sexo) => {
      const sexo = state.sexo.find((sexo) => sexo.id === id_sexo)
      if (sexo) return sexo.descripcion
      else return ''
    },
    sino: (state) => (id_sino) => {
      const sino = state.sino.find((sino) => sino.id === id_sino)
      if (sino) return sino.descripcion
      else return ''
    },
    escolaridadPorId: (state) => (id_escolaridad) => {
      const escolaridad = state.escolaridad.find(
        (escolaridad) => escolaridad.id === id_escolaridad
      )
      if (escolaridad) return escolaridad.descripcion
      else return ''
    },
    unidadMedidaPorId: (state) => (id_unidad_medida) => {
      const unidad_medida = state.unidad_medida.find(
        (unidad_medida) => unidad_medida.id === id_unidad_medida
      )
      if (unidad_medida) return unidad_medida.unidad
      else return ''
    },
    actividadPecuariaPorId: (state) => (id_actividad_pecuaria) => {
      const actividad_pecuaria = state.actividad_pecuaria.find(
        (actividad_pecuaria) => actividad_pecuaria.id === id_actividad_pecuaria
      )
      if (actividad_pecuaria) return actividad_pecuaria
      else return ''
    },
    tipoOrganizacionPorId: (state) => (id_tipo_organizacion) => {
      const tipo_organizacion = state.tipo_organizacion.find(
        (tipo_organizacion) => tipo_organizacion.id === id_tipo_organizacion
      )
      if (tipo_organizacion) return tipo_organizacion.descripcion
      else return ''
    },
    programaGobiernoPorId: (state) => (id_programa_gobierno) => {
      const programa_gobierno = state.programa_gobierno.find(
        (programa_gobierno) => programa_gobierno.id === id_programa_gobierno
      )
      if (programa_gobierno) return programa_gobierno.descripcion
      else return ''
    },
    organizacionPorId: (state) => (id_organizacion) => {
      const organizacion = state.organizacion.find(
        (organizacion) => organizacion.id === id_organizacion
      )
      if (organizacion) return organizacion.nombre
      else return ''
    },
    denominacionVarietalDeCultivo: (state) => (id_cultivo) => {
      return state.denominacion_varietal.filter(
        (den) => String(den.id_cultivo) === String(id_cultivo)
      )
    },
    institucionPorId: (state) => (id_institucion) => {
      const institucion = state.institucion.find(
        (i) => String(i.id) === String(id_institucion)
      )
      if (institucion) return institucion.descripcion
      else return ''
    },
    agenciasDeInstitucion: (state) => (id_institucion) => {
      const regionales = state.regional
        .filter((r) => id_institucion.includes(r.id_institucion))
        .map((r) => r.id)
      return state.agencia.filter((a) => regionales.includes(a.id_regional))
    },
    agenciaPorId: (state) => (id_agencia) => {
      const agencia = state.agencia.find((agencia) => agencia.id === id_agencia)
      if (agencia) return agencia.descripcion
      else return ''
    },
    regionalPorId: (state) => (id_agencia) => {
      const region = state.agencia.find((agencia) => agencia.id === id_agencia)
      if (region) return region.id_regional
      else return ''
    },
    regionNomPorId: (state) => (id_regional) => {
      const regional = state.regional.find(
        (regional) => regional.id === id_regional
      )
      if (regional) return regional.descripcion
      else return ''
    },
  },
}
