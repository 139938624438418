<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Consulta del Productor</div>
        </v-col>
        <v-col>
          <v-btn depressed class="primary--text" @click="mostrarFincas()">
            <v-icon left> mdi-warehouse </v-icon>
            Fincas
          </v-btn>
          <v-btn
            router
            to="/ru/copreparaciones"
            depressed
            class="primary--text"
          >
            <v-icon left> mdi-leaf </v-icon>
            Preparaciones
          </v-btn>
          <v-btn router to="/ru/cocultivos" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Siembras
          </v-btn>
          <v-btn router to="/ru/cocosechas" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Cosechas
          </v-btn>
          <v-btn router to="/ru/copecuarios" depressed class="primary--text">
            <v-icon left> mdi-cow </v-icon>
            Pecuario
          </v-btn>
          <v-btn router to="/ru/coacuiculturas" depressed class="primary--text">
            <v-icon left> mdi-fish </v-icon>
            Acuicultura
          </v-btn>
          <v-btn router to="/ru/coagroturismos" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Agroturismo
          </v-btn>
          <v-btn router to="/ru/coapiculturas" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Apicultura
          </v-btn>
          <v-btn
            router
            to="/ru/coagroindustrias"
            depressed
            class="primary--text"
          >
            <v-icon left> mdi-factory </v-icon>
            Agroindustrias
          </v-btn>
          <v-btn router to="/ru/coartesanias" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Artesanias
          </v-btn>
          <v-btn router to="/ru/coforestales" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Forestales
          </v-btn>
          <v-btn router to="/ru/copescas" depressed class="primary--text">
            <v-icon left> mdi-leaf </v-icon>
            Pesca Artesanal
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="tipo_identif"
            label="Tipo de identificación"
          />
        </v-col>
        <v-col cols="12">
          <div
            v-if="r.pa_id(productor.id) !== true && productor.tipo_empresa == 3"
            class="warning--text"
          >
            No es una cédula panameña
          </div>
        </v-col>
        <v-col v-if="productor.tipo_id === 1" cols="6">
          <v-text-field
            readonly
            :value="productor.id"
            label="Cédula del Productor o Representante de la empresa*"
            append-icon="mdi-account"
            outlined
          />
        </v-col>
        <v-col v-else-if="productor.tipo_id === 2" cols="6">
          <v-text-field
            readonly
            :value="productor.id"
            label="Pasaporte*"
            append-icon="mdi-account"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field readonly :value="estadito" label="Estado*" outlined />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="tipo_personita"
            label="Tipo de persona*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.primer_nombre"
            label="Primer nombre*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.segundo_nombre"
            label="Segundo nombre"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.primer_apellido"
            label="Primer apellido*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.segundo_apellido"
            label="Segundo apellido"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.apellido_conyugue"
            label="Apellido de conyugue"
            outlined
          />
        </v-col>
        <v-col cols="8">
          <v-text-field
            label="Fecha de nacimiento*"
            :value="productor.fecha_nacimiento"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field :value="edad" label="Edad" disabled outlined />
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="productor.direccion"
            label="Dirección*"
            counter="1000"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="provinciaPorId(productor.id_provincia)"
            label="Provincia*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="distritoPorId(productor.id_distrito)"
            label="Distrito*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="corregimientoPorId(productor.id_corregimiento)"
            label="Corregimiento*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="pobladoPorId(productor.id_poblado)"
            label="Poblado*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.numero_casa"
            label="Número de casa o departamento"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
        </v-col>
        <v-col cols="12">
          <v-btn depressed block class="primary--text">
            <v-icon left> mdi-crosshairs </v-icon>
            Ubicación actual
          </v-btn>
          <div class="text-center">
            Registrar la ubicación solo al encontrase en la residencia del
            productor.
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.longitud"
            label="Longitud"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.latitud"
            label="Latitud"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="productor.id_nacionalidad"
            :items="pais"
            item-text="NOMBRE"
            item-value="ISO"
            label="Nacionalidad*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.email"
            label="Correo"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="sexoPorId(productor.sexo)"
            readonly
            label="Sexo"
            outlined
          />
        </v-col>
        <!-- listado dinámico de números de teléfono -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text">
            <v-icon left> mdi-phone </v-icon>
            Número
          </v-btn>
        </v-col>
        <template v-for="(numero, index) in productor.numeros">
          <v-col :key="`${index}tipo`" cols="5">
            <v-text-field
              readonly
              :value="numero.tipo_telefono"
              label="Tipo"
              outlined
            />
          </v-col>
          <v-col :key="`${index}numero`" cols="7">
            <v-text-field
              readonly
              :value="numero.telefono"
              label="Número"
              outlined
            >
            </v-text-field>
          </v-col>
        </template>
        <!--<v-col cols="12">
          <v-text-field
            readonly
            :value="productor.tiene_organizacion"
            label="¿Pertecene a una organización asociativa?*"
            outlined
          />
        </v-col>
          Muestra campos de nombre de organización y tipo de organización si la
        organización no existe en el listado (-1)        
         <template v-if="
            productor.id_organizacion === 1 &&
            productor.tiene_organizacion === 1
          "-->
        <v-col cols="12">
          <v-text-field
            readonly
            :value="organizacionPorId(productor.id_organizacion)"
            label="Organización"
            outlined
          />
        </v-col>
        <template
          v-if="
            productor.id_organizacion === 1 &&
            productor.tiene_organizacion === 1
          "
        >
          <v-col cols="12">
            <v-text-field
              readonly
              :value="productor.nombre_organizacion"
              label="Nombre de la organización*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              :value="productor.registro_organizacion"
              label="Registro*"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              readonly
              :value="tipoOrganizacionPorId(productor.tipo_organizacion)"
              label="Tipo*"
              outlined
            />
          </v-col>
        </template>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="productor.escolaridad"
            :items="escolaridad"
            label="Escolaridad"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="productor.actividad"
            :items="actividad"
            label="Cuál es su principal actividad"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            readonly
            :value="productor.anios_actividad"
            label="Años"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            readonly
            :value="productor.meses_actividad"
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            label="Meses"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field readonly :value="sleer" label="Sabe leer" outlined />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="sescrib"
            label="Sabe escribir"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="productor.observacion"
            label="Observaciones"
          ></v-text-field>
        </v-col>
        <div id="empresa" />
        <v-col v-if="tiene_empresa" cols="12">
          <v-col cols="12">
            <div class="text-h6 text-center">Información de la empresa</div>
          </v-col>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.ruc"
                label="RUC*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.dv"
                label="DV*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                readonly
                :value="productor.empresa.nombre"
                label="Nombre de la empresa*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                readonly
                :value="productor.empresa.direccion"
                label="Dirección"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.telefono"
                label="Teléfono"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.apartado_postal"
                label="Apartado postal"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.email"
                label="Correo"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.sitio_web"
                label="Sitio web"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="provinciaPorId(productor.empresa.id_provincia)"
                label="Provincia*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="distritoPorId(productor.empresa.id_distrito)"
                label="Distrito*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="corregimientoPorId(productor.empresa.id_corregimiento)"
                label="Corregimiento*"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="pobladoPorId(productor.empresa.id_poblado)"
                label="Poblado*"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <div class="text-h6">Ubicación (WGS84)</div>
            </v-col>
            <v-col cols="12">
              <v-btn depressed block class="primary--text">
                <v-icon left> mdi-crosshairs </v-icon>
                Ubicación actual
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.longitud"
                label="Longitud"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.latitud"
                label="Latitud"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                readonly
                :value="productor.empresa.actividad"
                :items="actividad"
                label="Cuál es su principal actividad"
                outlined
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                readonly
                :value="productor.empresa.anios_actividad"
                label="Años"
                outlined
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                readonly
                :value="productor.empresa.meses_actividad"
                label="Meses"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                readonly
                :value="productor.empresa.certificaciones"
                label="¿Su empresa ha sido certificada por algún Sistema de Gestión de Calidad?"
                block
                outlined
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                readonly
                :value="productor.empresa.organizaciones"
                label="Organizaciones a la que pertenece"
                block
                outlined
              />
            </v-col> -->
            <v-col cols="12">
              <div class="text-h6">Información del contacto</div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                readonly
                :value="productor.empresa.nombre_contacto"
                label="Nombre"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                readonly
                :value="productor.empresa.telefono_contacto"
                label="Teléfono"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                readonly
                :value="productor.empresa.email_contacto"
                label="Correo"
                outlined
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'El productor ya existe'"
      :description="'La identificación colocada corresponde a un productor existente, desea cargar la información de este productor?'"
      :modal="modal_registro_existe"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { telefono_persona, formulario_de_productor } from '@/modelos'
import * as rules from '@/utils/validations'
import { getCurrentPosition, clona, edad } from '@/utilidades'

export default {
  name: 'Conexpediente',
  data: () => {
    return {
      tipo_personita: '',
      tipo_identif: '',
      estadito: '',
      sleer: '',
      sescrib: '',
      susto: {},
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      tiene_empresa: false,
      registro_existente: {},
      registros: {},
      productor: clona(formulario_de_productor),
      loading: false,
    }
  },
  computed: {
    // retorna cierto si existe al menos un número en el arreglo de numeros del productor
    puedeRemoverNumero() {
      return this.productor.numeros.length >= 0
    },
    edad() {
      return edad(this.productor.fecha_nacimiento)
    },
    puedeGuardar() {
      return (
        this.obtenerIdDeRuta() === '' ||
        this.obtenerIdDeRuta() === this.productor.id
      )
    },
    ...mapState('listados', [
      //'sino',
      'tipo_empresa',
      'pais',
      'sexo',
      'tipo_telefono',
      'organizacion',
      'escolaridad',
      'tipo_organizacion',
      'tipo_identificacion',
      'institucion',
      'sistema_gestion_calidad',
      'actividad',
      'estado',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
      'sexoPorId',
      'tipoOrganizacionPorId',
      'programaGobiernoPorId',
      'organizacionPorId',
      'institucionPorId',
    ]),
    ...mapGetters('registros', ['productor_actual', 'existeRegistroEnState']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'productor.tipo_empresa'() {
      if (this.productor.tipo_empresa === 2) {
        this.tiene_empresa = true
        this.tipo_personita = 'PERSONA JURIDICA'
      } else {
        this.tiene_empresa = false
        this.tipo_personita = 'PERSONA NATURAL'
      }
    },
    'productor.tipo_id'() {
      if (this.productor.tipo_id === 1) {
        this.tipo_identif = 'CEDULA'
      } else {
        this.tipo_identif = 'PASAPORTE'
      }
    },
    'productor.estado'() {
      if (this.productor.tipo_id === 1) {
        this.estadito = 'ACTIVO'
      } else {
        this.estadito = 'INACTIVO'
      }
    },
    /*'productor.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_distrito = ''
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_poblado = ''
      }
    },
    'productor.empresa.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_distrito = ''
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_poblado = ''
      }
    },*/
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Productor')
    // si un id viene como parte de la ruta, intentar cargar el productor con ese id del almacenamiento local
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.$store.commit('registros/productorActual', id)
      //this.$store.commit('registros/definirTipoProductor')
      //this.$store.commit('registros/getFinquita', id)
      this.$store.dispatch('registros/getFinquita', id)
      this.cargarRegistro(id)
    }
  },
  methods: {
    async obtenerPosicionDeEmpresa() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.empresa.longitud = position.coords.longitude
        this.productor.empresa.latitud = position.coords.latitude
      }
    },
    async obtenerPosicionProductor() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.longitud = position.coords.longitude
        this.productor.latitud = position.coords.latitude
      }
    },
    // agrega un objeto de número al arreglo de números del productor
    agregarNumero() {
      this.productor.numeros.push({ ...telefono_persona })
    },
    // remueve un número del arreglo de números
    removerNumero(index) {
      if (this.puedeRemoverNumero) {
        this.productor.numeros.splice(index, 1)
      }
    },
    // registra el productor en el almacenamiento local, lo coloca como productor actual y pasa a la siguiente pantalla
    //async guardarYContinuar() {
    //try {
    //this.loading = true
    //if (!this.$refs.form.validate()) {
    //this.$store.commit('ui/setSnack', {
    //text: 'Verifique los elementos en rojo',
    //color: 'error',
    //})
    //return
    //}
    //if (await this.buscarRegistroExistente()) {
    //return
    //}
    //if (!this.obtenerIdDeRuta()) {
    //  this.productor.created_at = new Date().getTime() / 1000
    //}
    //this.productor.updated_at = new Date().getTime() / 1000

    //await this.$store.dispatch(
    //  'registros/guardarProductor',
    //  clona(this.productor)
    //)
    //} catch (error) {
    //this.$sentry.captureException(error)
    //} finally {
    //this.loading = false
    //}
    //},
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      //if (!this.existeRegistroEnState('productoresci', id)) {
      await this.cargarRegistroDelServer(this.registro_existente)
      //}
      this.$router.push(`/ru/productor/conexpediente/${id}`)
      this.cerrarModalDeRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('productoresci', id)) {
        this.productor = this.obtenerRegistroDelState(id)
        this.cargarRegistroDelServer(id)
        if (this.productor.sabe_leer === 1) {
          this.sleer = 'NO'
        } else {
          this.sleer = 'SI'
        }
        if (this.productor.sabe_escribir === 1) {
          this.sescrib = 'NO'
        } else {
          this.sescrib = 'SI'
        }
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    //async buscarRegistroExistente() {
    //  const registro = await this.obtenerUnRegistroONada()
    //if (elObjetoTienePropiedades(registro)) {
    //const id = registro.id ? registro.id.toString() : ''
    //if (id === this.obtenerIdDeRuta()) {
    //return false
    //}
    //this.almacenarRegistroEncontradoTemporalmente(registro)
    //this.mostrarModalDeRegistroExistente()
    //return true
    //}
    //return false
    //},
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async cargarRegistroDelServer(registro) {
      try {
        //en susto trae los listados de todo
        this.susto = await this.$store.dispatch(
          'registros/getConsulta',
          registro.id
        )
      } catch (e) {
        return false
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    obtenerRegistroDelState(id) {
      return {
        ...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productoresci[id]),
      }
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalDeRegistroExistente() {
      this.modal_registro_existe = false
    },
    async mostrarFincas() {
      try {
        this.loading_add = true
        this.$router.push(`/ru/cofincas`)
      } catch (e) {
        return false
      }
    },
  },
}
</script>
