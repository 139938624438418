<template>
  <n-fields-box>
    <v-row dense>
      <v-col cols="4">
        <n-date
          :date.sync="form.fecha_de_actualizacion"
          :rules="[r.required]"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="form.id_evento"
          :rules="[r.required]"
          :items="evento"
          item-text="id_evento"
          item-value="id_evento"
          outlined
          label="Evento*"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          readonly
          outlined
          label="Tipo de evento"
          :value="tipoEvento.tipo_evento"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="form.factura_insumo"
          :rules="[r.required]"
          outlined
          :items="sino_text"
          label="Factura de insumos*"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="form.factura_compra"
          :rules="[r.required]"
          outlined
          label="Facturas de compra venta*"
          :items="sino_text"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.tecnico"
          outlined
          label="Técnico*"
          :rules="[r.required]"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.numidoneidad"
          :rules="[r.required]"
          outlined
          label="N° de Idoneidad*"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.coordinadorarea"
          :rules="[r.required]"
          outlined
          label="Coordinador del área*"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.testigo"
          :rules="[r.required]"
          outlined
          label="Testigo comunitario*"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="form.latitud"
          :rules="[r.x_coord]"
          label="Latitud"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="form.longitud"
          :rules="[r.y_coord]"
          label="Longitud"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.observaciones"
          outlined
          label="Observaciones"
        />
      </v-col>
      <v-col cols="12">
        <v-btn depressed block class="red--text" @click="$emit('eliminar')">
          <v-icon left>mdi-delete</v-icon>
          Eliminar situación de desastre
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          depressed
          block
          class="primary--text"
          @click="agregarPerdidasYCostos"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar pérdidas y costos
        </v-btn>
      </v-col>
      <v-col cols="12">
        <!-- Formulario de perdidas y costos -->
        <perdidas-y-costos
          v-for="(perdida, index) in form.perdidas_y_costos"
          :key="perdida.id_detalle_dano"
          :form="form.perdidas_y_costos[index]"
          :modulo="modulo"
          @eliminar="eliminarPerdida(index)"
        />
      </v-col>
    </v-row>
  </n-fields-box>
</template>

<script>
import * as rules from '@/utils/validations'
import { mapState } from 'vuex'
import PerdidasYCostos from '@/components/form/PerdidasYCostos.vue'
import { desastre_detalle } from '@/modelos'
/* import  {getCurrentPosition,
} from '../../utilidades' */
export default {
  components: {
    PerdidasYCostos,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      r: rules,
    }
  },
  computed: {
    ...mapState('listados', ['evento', 'sino_text']),
    tipoEvento() {
      return (
        this.evento?.find((ev) => ev.id_evento === this.form.id_evento) ?? {}
      )
    },
  },
  methods: {
    agregarPerdidasYCostos() {
      this.form.perdidas_y_costos?.push({ ...desastre_detalle })
    },
    eliminarPerdida(index) {
      this.form.perdidas_y_costos?.splice(index, 1)
    },
  },
}
</script>
