import Vue from 'vue'
import RegistroExistente from '@/components/global/RegistroExistente'
import RegistroNoExistente from '@/components/global/RegistroNoExistente'
import FormMeta from '@/components/global/FormMeta'
import FormCard from '@/components/global/FormCard'
import UnitPicker from '@/components/global/UnitPicker'
import SaveBtn from '@/components/global/SaveBtn'
import NDate from '@/components/global/NDate'
import NText from '@/components/global/NText'
import NFieldsBox from '@/components/global/NFieldsBox'

Vue.component('registro-existente', RegistroExistente)
Vue.component('registro-noexistente', RegistroNoExistente)
Vue.component('form-meta', FormMeta)
Vue.component('form-card', FormCard)
Vue.component('unit-picker', UnitPicker)
Vue.component('save-btn', SaveBtn)
Vue.component('n-date', NDate)
Vue.component('n-text', NText)
Vue.component('n-fields-box', NFieldsBox)
