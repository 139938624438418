<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/finca" depressed class="primary">
          <v-icon left v-text="'mdi-plus'"></v-icon>
          Agregar finca
        </v-btn>
      </v-col>
      <!-- listado de fincas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="registro in registros">
            <form-card
              :key="registro.id"
              :top-text="registro.nombre || 'Sin nombre...'"
              :middle-text="`Ruc : ${
                registro.ruc || '(no posee)'
              } , Número de registro : ${
                registro.num_registro || '(no posee)'
              }`"
              :bottom-right-text="producerDescription(registro.productor)"
              :sincronized="registro.sincronizado"
              @click="traerRegistroYNavegar(registro)"
            />
          </template>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1 font-weight-bold"
              >Parcelas</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <parcelas></parcelas>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1 font-weight-bold"
              >Establecimientos (RUA)</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <establecimientos></establecimientos>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { producerDescription } from '@/utilidades'
import Establecimientos from './Establecimientos.vue'
import Parcelas from './Parcelas.vue'

export default {
  name: 'Fincas',
  components: {
    Establecimientos,
    Parcelas,
  },
  computed: {
    ...mapState('registros', {
      registros: 'fincas',
    }),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Fincas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getFinca', { id: registro.id })
      }
      this.$router.push(`/ru/finca/${registro.id}`)
    },
  },
}
</script>
