<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Productor</div>
          <div class="font-weight-medium blue-grey--text">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="finca.id_productor"
            :rules="[r.required, r.max(23)]"
            label="Cédula o Pasaporte*"
            append-icon="mdi-account"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="finca.ruc"
            :rules="[r.max(23)]"
            label="RUC*"
            outlined
            @input="debounceBuscarRegistroExistenteRuc"
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Información de la finca</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="finca"
            :remove-commit="'removerFinca'"
            :record-id="finca.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="finca.num_registro"
            label="Número del Registro Público de la finca"
            outlined
            :rules="[r.int, r.max(), r.min_num(1)]"
            @input="debounceObtenerFincasPorRegistro"
          />
        </v-col>
        <v-col v-if="name_exists" cols="12">
          <div
            class="text-body-2 warning--text"
            v-text="'El nombre de esta finca ya ha sido registrado.'"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="finca.nombre"
            :disabled="!!existing_finca.nombre"
            :rules="[r.required, r.max()]"
            label="Nombre de la finca*"
            outlined
            @input="debounceObtenerFincasPorNombre"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.id_provincia"
            :rules="[r.required]"
            :items="provincia"
            item-text="NOMBRE_PROVINCIA"
            item-value="ID_PROVINCIA"
            label="Provincia*"
            outlined
            @input="limpiarDistrito"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.id_distrito"
            :rules="[r.required]"
            :items="distritos(finca.id_provincia)"
            item-text="NOMBRE_DISTRITO"
            item-value="ID_DISTRITO"
            label="Distrito*"
            outlined
            @input="limpiarCorregimiento"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.id_corregimiento"
            :rules="[r.required]"
            :items="corregimientos(finca.id_distrito)"
            item-text="NOMBRE_CORREGIMIENTO"
            item-value="ID_CORREGIMIENTO"
            label="Corregimiento*"
            outlined
            @input="limpiarPoblado"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.id_poblado"
            :rules="[r.required]"
            :items="poblados(finca.id_corregimiento)"
            item-text="NOMBRE_POBLADO"
            item-value="ID_POBLADO"
            label="Poblado*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="finca.id_agencia"
            :rules="[r.required]"
            :items="agencia"
            item-text="descripcion"
            item-value="id"
            label="Agencia que atiende la finca de este productor*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="finca.extension"
            :rules="[r.required, r.float, r.min_num(0), r.max_num()]"
            label="Extensión (superficie)*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.entidad_apoyo"
            multiple
            :items="institucion"
            item-text="descripcion"
            item-value="id"
            label="¿De cuál institución u Organización recibe apoyo en esta finca?"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.fuente_agua"
            multiple
            :items="fuente_agua"
            item-text="descripcion"
            item-value="id"
            label="Fuente de agua"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.accesibilidad"
            :rules="[r.required]"
            :items="accesibilidad"
            item-text="descripcion"
            item-value="id"
            label="Accesibilidad (en vehículo)*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="finca.tiempo_acceso_verano"
            :rules="[r.float, r.min_num(0.1), r.max_num(48)]"
            persistent-hint
            hint="Desde la regional más cercana del MIDA"
            label="Tiempo de acceso en verano"
            suffix="hora(s)"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="finca.tiempo_acceso_invierno"
            :rules="[r.float, r.min_num(0.1), r.max_num(48)]"
            persistent-hint
            hint="Desde la regional más cercana del MIDA"
            label="Tiempo de acceso en invierno"
            suffix="hora(s)"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.asistencias"
            multiple
            :items="tipo_asistencia"
            label="Asistencias técnicas"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="finca.actividades"
            multiple
            :items="actividad"
            label="Actividades"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="finca.infraestructura_almacenamiento"
            :items="sino"
            item-text="descripcion"
            item-value="id"
            label="¿Posee infraestructura de almacenamiento?"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="finca.administrada_por"
            :rules="[r.required]"
            :items="tipo_administracion"
            item-text="descripcion"
            item-value="id"
            label="¿La finca es administrada por?*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="finca.distancia_vivienda"
            :rules="[r.required, r.float, r.min_num(0), r.max_num()]"
            label="Distancia de la finca a la vivienda*"
            suffix="km"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
        </v-col>
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="obtenerPosicion">
            <v-icon left>mdi-crosshairs</v-icon>
            Registrar ubicación actual</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="finca.longitud"
            :rules="[r.x_coord]"
            label="Longitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="finca.latitud"
            :rules="[r.y_coord]"
            label="Latitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Tipo de terreno</div>
        </v-col>
        <!-- listado dinámico de terrenos -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarTerreno">
            <v-icon left>mdi-plus</v-icon>
            Agregar tipo de terreno
          </v-btn>
        </v-col>
        <template v-for="(tenencia, index) in finca.terrenos">
          <v-col :key="`${index}terreno`" cols="5">
            <v-select
              v-model="tenencia.tipo_terreno"
              :rules="[r.required]"
              :items="tipo_terreno_filtrado"
              label="Tipo de terreno*"
              outlined
            >
              <template v-slot:selection="props">
                <div v-text="props.item[props.parent.$props.itemText]" />
              </template>
            </v-select>
          </v-col>
          <v-col :key="`${index}extension_terreno`" cols="7">
            <v-text-field
              v-model.number="tenencia.extension"
              :error="finca.extension != total_terreno"
              :rules="[r.required, r.float]"
              label="Extensión (superficie)*"
              suffix="Ha"
              outlined
            >
              <v-icon
                slot="append-outer"
                :disabled="!puedeRemoverTerreno"
                class="error--text"
                @click="removerTerreno(index)"
                >mdi-close</v-icon
              >
            </v-text-field>
          </v-col>
        </template>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            {{ `Total de superficie: ${finca.extension} Ha` }}
          </div>
          <div class="font-weight-medium grey--text text--darken-2">
            {{ `Superficie por terreno: ${total_terreno} Ha` }}
          </div>
          <div
            v-show="finca.extension != total_terreno"
            class="font-weight-medium error--text"
          >
            La suma de los terrenos debe igualar la superficie de la finca.
          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Tenencia</div>
        </v-col>
        <!-- listado dinámico de tenencias -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarTenencia">
            <v-icon left>mdi-plus</v-icon>
            Agregar tenencia
          </v-btn>
        </v-col>
        <template v-for="(tenencia, index) in finca.tenencias">
          <v-col :key="`${index}tenencia`" cols="5">
            <v-select
              v-model="tenencia.tenencia_tierra"
              :rules="[r.required]"
              :items="tenencia_filtrada"
              label="Tenencia*"
              outlined
            >
              <template v-slot:selection="props">
                <div v-text="props.item[props.parent.$props.itemText]" />
              </template>
            </v-select>
          </v-col>
          <v-col :key="`${index}extension`" cols="7">
            <v-text-field
              v-model.number="tenencia.extension"
              :error="finca.extension != total_tenencia"
              :rules="[r.required, r.float]"
              label="Extensión (superficie)*"
              suffix="Ha"
              outlined
            >
              <v-icon
                slot="append-outer"
                :disabled="!puedeRemoverTenencia"
                class="error--text"
                @click="removerTenencia(index)"
                >mdi-close</v-icon
              >
            </v-text-field>
          </v-col>
        </template>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            {{ `Total de superficie: ${finca.extension} Ha` }}
          </div>
          <div class="font-weight-medium grey--text text--darken-2">
            {{ `Superficie por tenencia: ${total_tenencia} Ha` }}
          </div>
          <div
            v-show="finca.extension != total_tenencia"
            class="font-weight-medium error--text"
          >
            La suma de las tenencias debe igualar la superficie de la finca.
          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Aseguramiento</div>
        </v-col>
        <v-col v-show="bien_asegurado_repetido" cols="12">
          <n-text error :fix-required="bien_asegurado_repetido">
            Se colocaron valores duplicados para bienes y tipos de
            aseguramientos.
          </n-text>
        </v-col>
        <!-- listado dinámico de aseguramientos -->
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="agregarAseguramiento"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar bien asegurado
          </v-btn>
        </v-col>
        <template v-for="(aseguramiento, index) in finca.aseguramientos">
          <v-col :key="`${index}bien_asegurado`" cols="5">
            <v-select
              v-model="aseguramiento.activo"
              :rules="[r.required]"
              :items="activo"
              label="Bien"
              outlined
            ></v-select>
          </v-col>
          <v-col :key="`${index}tipo_aseguramiento`" cols="7">
            <v-select
              v-model="aseguramiento.tipo_entidad_aseguradora"
              :rules="[r.required]"
              :items="tipo_entidad_aseguradora"
              label="Tipo de aseguramiento"
              outlined
            >
              <v-icon
                slot="append-outer"
                :disabled="!puedeRemoverAseguramiento"
                class="error--text"
                @click="removerAseguramiento(index)"
                >mdi-close</v-icon
              >
            </v-select>
          </v-col>
        </template>
        <v-col cols="12">
          <div class="text-h6">Financiamiento</div>
        </v-col>
        <v-col v-show="bien_financiado_repetido" cols="12">
          <n-text error :fix-required="bien_financiado_repetido">
            Se colocaron valores duplicados para bienes y tipos de
            financiamientos.
          </n-text>
        </v-col>
        <!-- listado dinámico de financiamientos -->
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="agregarFinanciamiento"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar bien financiado
          </v-btn>
        </v-col>
        <template v-for="(financiamiento, index) in finca.financiamientos">
          <v-col :key="`${index}bien_financiamiento`" cols="5">
            <v-select
              v-model="financiamiento.activo"
              :rules="[r.required]"
              :items="activo"
              label="Bien"
              outlined
            ></v-select>
          </v-col>
          <v-col :key="`${index}tipo_financiamiento`" cols="7">
            <v-select
              v-model="financiamiento.tipo_entidad_financiera"
              :rules="[r.required]"
              :items="tipo_entidad_financiera"
              label="Tipo de financiamiento"
              outlined
            >
              <v-icon
                slot="append-outer"
                :disabled="!puedeRemoverFinanciamiento"
                class="error--text"
                @click="removerFinanciamiento(index)"
                >mdi-close</v-icon
              >
            </v-select>
          </v-col>
        </template>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="finca.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-id="finca.id"
            :record-status="finca.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La finca ya existe'"
      :description="'Los datos colocados corresponden a una finca existente, desea cargar la información de esta finca?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
    <registro-noexistente
      :title="'Esta Empresa No Existe'"
      :description="'El RUC colocado NO corresponde a una Empresa existente, verifique la información.'"
      :modal="modal_registro_existe_Ruc"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistenteRuc"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import debounce from 'lodash.debounce'
import { mapState, mapGetters } from 'vuex'
import {
  getCurrentPosition,
  clona,
  elObjetoTienePropiedades,
} from '../../utilidades'
import {
  formulario_de_finca,
  tenencia_finca,
  finca_aseguramiento,
  finca_financiamiento,
  terreno_finca,
  formulario_empresa,
} from '../../modelos'
import * as rules from '@/utils/validations'

const existing_empresa = {
  ruc: '',
}
const existing_finca = {
  num_registro: '',
  nombre: '',
  id_productor: '',
}

export default {
  name: 'Finca',
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      modal_registro_existe_Ruc: false,
      registro_existente: {},
      existing_empresa: clona(existing_empresa),
      finca: clona(formulario_de_finca),
      existing_finca: clona(existing_finca),
      name_exists: false,
      loading: false,
      empresa: clona(formulario_empresa),
      ruc: '',
    }
  },
  computed: {
    // retorna cierto si existe al menos un terreno en el arreglo de terrenos de la finca
    puedeRemoverTerreno() {
      return this.finca.terrenos.length >= 2
    },
    // retorna cierto si existe al menos una tenencia en el arreglo de tenencias de la finca
    puedeRemoverTenencia() {
      return this.finca.tenencias.length >= 2
    },
    // retorna cierto si existe al menos un aseguramiento en el arreglo de aseguramientos de la finca
    puedeRemoverAseguramiento() {
      return this.finca.aseguramientos.length >= 1
    },
    // retorna cierto si existe al menos un financiamiento en el arreglo de financiamientos de la finca
    puedeRemoverFinanciamiento() {
      return this.finca.financiamientos.length >= 1
    },
    total_tenencia() {
      if (this.finca.tenencias.length > 1) {
        const total = this.finca.tenencias.reduce(
          (acum, curr) => acum + curr.extension,
          0
        )
        return typeof total === 'number' && !isNaN(total) ? total.toFixed(2) : 0
      } else if (this.finca.tenencias.length === 1) {
        return this.finca.tenencias[0].extension
      } else {
        return 0
      }
    },
    total_terreno() {
      if (this.finca.terrenos.length > 1) {
        const total = this.finca.terrenos.reduce(
          (acum, curr) => acum + curr.extension,
          0
        )
        return typeof total === 'number' && !isNaN(total) ? total.toFixed(2) : 0
      } else if (this.finca.terrenos.length === 1) {
        return this.finca.terrenos[0].extension
      } else {
        return 0
      }
    },
    puedeGuardar() {
      const id = this.finca
        ? this.finca.id
          ? this.finca.id.toString()
          : ''
        : ''
      return this.obtenerIdDeRuta() === '' || this.obtenerIdDeRuta() === id
    },
    ...mapState('listados', [
      'provincia',
      'distrito',
      'corregimiento',
      'poblado',
      'agencia',
      'institucion',
      'fuente_agua',
      'accesibilidad',
      'tipo_asistencia',
      'sistema_produccion',
      'sino',
      'tipo_administracion',
      'tipo_terreno',
      'tenencia_tierra',
      'activo',
      'tipo_entidad_financiera',
      'tipo_entidad_aseguradora',
      'actividad',
    ]),
    ...mapGetters('listados', ['distritos', 'corregimientos', 'poblados']),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'registroConPropiedades',
      'getMatchingRecords',
    ]),
    ...mapState('registros', ['productor']),
    tenencia_filtrada() {
      const selected = this.finca.tenencias.map((v) => v.tenencia_tierra)
      return this.tenencia_tierra.map((v) => ({
        text: v,
        disabled: selected.includes(v),
      }))
    },
    tipo_terreno_filtrado() {
      const selected = this.finca.terrenos.map((v) => v.tipo_terreno)
      return this.tipo_terreno.map((v) => ({
        text: v,
        disabled: selected.includes(v),
      }))
    },
    bien_asegurado_repetido() {
      let rep = 0
      const cant_aseg = this.finca.aseguramientos.length
      for (const aseg of this.finca.aseguramientos) {
        for (const aseg2 of this.finca.aseguramientos) {
          if (
            aseg.activo === aseg2.activo &&
            aseg.tipo_entidad_aseguradora === aseg2.tipo_entidad_aseguradora
          ) {
            rep++
          }
        }
      }
      return rep > cant_aseg
    },
    bien_financiado_repetido() {
      let rep = 0
      const cant_finan = this.finca.financiamientos.length
      for (const aseg of this.finca.financiamientos) {
        for (const aseg2 of this.finca.financiamientos) {
          if (
            aseg.activo === aseg2.activo &&
            aseg.tipo_entidad_financiera === aseg2.tipo_entidad_financiera
          ) {
            rep++
          }
        }
      }
      return rep > cant_finan
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Finca')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.finca = clona(formulario_de_finca)
      this.finca.id_productor = this.productor.id
    }
  },
  methods: {
    limpiarDistrito() {
      this.finca.id_distrito = ''
      this.finca.id_corregimiento = ''
      this.finca.id_poblado = ''
    },
    limpiarCorregimiento() {
      this.finca.id_corregimiento = ''
      this.finca.id_poblado = ''
    },
    limpiarPoblado() {
      this.finca.id_poblado = ''
    },
    async obtenerPosicion() {
      const position = await getCurrentPosition()
      if (position) {
        this.finca.longitud = position.coords.longitude
        this.finca.latitud = position.coords.latitude
      }
    },
    // agrega un objeto de terreno al arreglo de terrenos de la finca
    agregarTerreno() {
      this.finca.terrenos.push({ ...terreno_finca })
    },
    // agrega un objeto de tenencia al arreglo de tenencias de la finca
    agregarTenencia() {
      this.finca.tenencias.push({ ...tenencia_finca })
    },
    // remueve un terreno del arreglo de terrenos
    removerTerreno(index) {
      if (this.puedeRemoverTerreno) {
        this.finca.terrenos.splice(index, 1)
      }
    },
    // remueve una tenencia del arreglo de tenencias
    removerTenencia(index) {
      if (this.puedeRemoverTenencia) {
        this.finca.tenencias.splice(index, 1)
      }
    },
    // agrega un objeto de aseguramiento al arreglo de aseguramientos de la finca
    agregarAseguramiento() {
      this.finca.aseguramientos.push({ ...finca_aseguramiento })
    },
    // remueve un aseguramiento del arreglo de aseguramientos
    removerAseguramiento(index) {
      if (this.puedeRemoverAseguramiento) {
        this.finca.aseguramientos.splice(index, 1)
      }
    },
    // agrega un objeto de financiamiento al arreglo de financiamientos de la finca
    agregarFinanciamiento() {
      this.finca.financiamientos.push({ ...finca_financiamiento })
    },
    // remueve un financiamiento del arreglo de financiamientos
    removerFinanciamiento(index) {
      if (this.puedeRemoverFinanciamiento) {
        this.finca.financiamientos.splice(index, 1)
      }
    },
    // genera un id si la finca no posee uno y registra la finca en el almacenamiento local
    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.finca.id = uuid()
          this.finca.created_at = new Date().getTime() / 1000
        }
        this.finca.updated_at = new Date().getTime() / 1000

        this.$store.dispatch('registros/guardarFinca', clona(this.finca))
        this.$router.push('/ru/fincas')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('fincas', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/finca/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      this.existing_finca = clona(existing_finca)
      if (this.existeRegistroEnState('fincas', id)) {
        this.finca = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        this.obtenerFincasPorRegistro()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),

    debounceBuscarRegistroExistenteRuc: debounce(async function () {
      await this.buscarRegistroExistenteRuc()
    }, 1500),
    //SOLO para Validar la existencia del RUC en el Server
    async buscarRegistroExistenteRuc() {
      //lista 1 RUC del server por la variable ruc enviada
      const registro = await this.obtenerEmpresasPorRegistro()
      if (elObjetoTienePropiedades(registro)) {
        const ruc = registro.ruc ? registro.ruc.toString() : ''
        //if (id === this.obtenerIdDeRuta()) {
        if (ruc === this.finca.ruc) {
          return true
        }
        this.mostrarModalDeRegistroExistenteRuc()
        return false
      } else {
        this.mostrarModalDeRegistroExistenteRuc()
        return false
      }
    },
    //obtiene un RUC existente
    async obtenerEmpresasPorRegistro() {
      try {
        this.existing_empresa = clona(existing_empresa)
        const ruc = this.finca.ruc
        // required fields to be fulfilled
        if (ruc) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmpresas',
            { ruc }
          )
          if (status === 'ok' && data.length > 0) {
            if (data[0].ruc !== this.finca.ruc) {
              this.existing_empresa = clona({
                ruc: this.finca.ruc,
              })
            } else {
              this.finca.ruc = data[0].ruc
              return data[0]
            }
          }
        }
        return {}
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    mostrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = true
    },
    cerrarModalDeRegistroExistenteRuc() {
      this.modal_registro_existe_Ruc = false
    },
    async obtenerUnRegistroONada() {
      try {
        const num_registro = this.finca.num_registro
        const nombre = this.finca.nombre
        const id_productor = this.finca.id_productor
        // required fields to be fulfilled
        if (nombre && id_productor) {
          const fincas = this.getMatchingRecords({
            recordName: 'fincas',
            matchValues: [
              { key: 'num_registro', value: num_registro },
              { key: 'nombre', value: nombre },
              { key: 'id_productor', value: id_productor },
            ],
          })
          if (fincas.length > 0) return fincas[0]

          const { status, data } = await this.$store.dispatch(
            'registros/listarFincas',
            {
              num_registro,
              nombre,
              id_productor,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async obtenerFincasPorRegistro() {
      try {
        this.existing_finca = clona(existing_finca)
        const num_registro = this.finca.num_registro
        const nombre = ''
        const id_productor = ''
        // required fields to be fulfilled
        if (num_registro) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarFincas',
            {
              num_registro,
              nombre,
              id_productor,
            }
          )
          if (status === 'ok' && data.length > 0) {
            if (data[0].id !== this.finca.id) {
              this.existing_finca = clona({
                id_productor: this.finca.id_productor,
                nombre: data[0].nombre,
                num_registro: data[0].num_registro,
              })
              this.finca.id_finca = data[0].id_finca
              this.finca.nombre = data[0].nombre
              this.name_exists = false
            }
          }
        }
        await this.obtenerFincasPorNombre()
        return {}
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
    debounceObtenerFincasPorRegistro: debounce(async function () {
      await this.obtenerFincasPorRegistro()
    }, 1500),
    async obtenerFincasPorNombre() {
      try {
        this.name_exists = false
        const num_registro = ''
        const nombre = this.finca.nombre
        const id_productor = ''
        //  fields to be fulfilled
        if (nombre) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarFincas',
            {
              num_registro,
              nombre,
              id_productor,
            }
          )
          if (status === 'ok' && data.length > 0) {
            if (!this.existing_finca.nombre && this.finca.id !== data[0].id) {
              this.name_exists = true
            }
          }
        }
        return {}
      } catch (e) {
        console.error(e)
        return {}
      }
    },
    debounceObtenerFincasPorNombre: debounce(async function () {
      await this.obtenerFincasPorNombre()
    }, 1500),
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getFinca', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_de_finca,
        ...this.$store.state.registros.fincas[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>
