<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/cosecha" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar cosecha
        </v-btn>
      </v-col>
      <!-- listado de cosechas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="cosecha in cosechas">
            <form-card
              :key="cosecha.id"
              :top-text="`Finca • ${getNombreDeFinca(cosecha.id_finca)}`"
              :middle-text="`Cosecha de ${
                cultivo(cosecha.id_cultivo).descripcion
              }`"
              :bottom-text="`Coa ${cosecha.coa} , RUC: ${getRucDeFinca(
                cosecha.id_finca
              )}`"
              :top-right-text="cosecha.fecha"
              :bottom-right-text="producerDescription(cosecha.productor)"
              :sincronized="cosecha.sincronizado"
              @click="traerRegistroYNavegar(cosecha)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Cosechas',
  computed: {
    ...mapState('registros', ['cosechas']),
    ...mapGetters('listados', ['cultivo']),
    ...mapGetters('registros', ['getNombreDeFinca', 'getRucDeFinca']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Cosechas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getCosecha', { id: registro.id })
      }
      this.$router.push(`/ru/cosecha/${registro.id}`)
    },
  },
}
</script>
