<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/pesca" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar pesca
        </v-btn>
      </v-col>
      <!-- listado de actividades pesqueras en el almacenamiento local 
      `Embarcación ${embarcaciones[pesca.id_embarcacion].nombre ||   -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="pesca in pescas">
            <form-card
              :key="pesca.id"
              :top-text="`Embarcación ${
                pesca.id_embarcacion.nombre || 'sin nombre...'
              }`"
              :bottom-right-text="producerDescription(pesca.productor)"
              :middle-text="`Ciclo ${pesca.ciclo}`"
              :top-right-text="pesca.fecha"
              :sincronized="pesca.sincronizado"
              :opaque="pesca.hasOutdatedRecords"
              @click="traerRegistroYNavegar(pesca)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Cosechas',
  computed: {
    ...mapState('registros', [
      'pescas',
      'fincas',
      'productores',
      'embarcaciones',
    ]),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Pescas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getPesca', { id: registro.id })
      }
      this.$router.push(`/ru/pesca/${registro.id}`)
    },
  },
}
</script>
