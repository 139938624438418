<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-6">Consulta de las actividades agroturísticas</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="agroturismo"
            :remove-commit="'removerAgroturismo'"
            :record-id="agroturismo.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="agroturismo.estatus"
            readonly
            :items="estatus"
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="getNombreDeFincas(agroturismo.id_finca)"
            label="Finca*"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="agroturismo.id_establecimiento"
            readonly
            :no-data-text="$vuetify.noDataText"
            outlined
            :items="establecimientosPorId(agroturismo.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="agroturismo.id_parcela"
            readonly
            :no-data-text="$vuetify.noDataText"
            outlined
            :items="parcelasPorId(agroturismo.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha*"
            range-type="production"
            :date.sync="agroturismo.fecha"
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="agroturismo.ciclo"
            label="Ciclo*"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="agroturismo.descripcion"
            readonly
            counter="1000"
            label="Descripción*"
            outlined
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="agroturismo.ingreso_anual"
            readonly
            label="Ingreso anual*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <!-- Situación de desastre
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in agroturismo.desastres"
          :key="desastre.id_trxdano"
          :form="agroturismo.desastres[index]"
          :modulo="'AGROTURISMO'"
          @eliminar="eliminarDesastre(index)"
        /> -->
        <v-col cols="12">
          <div class="text-6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="agroturismo.observacion"
            readonly
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La producción ya existe'"
      :description="'Los datos colocados corresponden a una producción existente, desea cargar la información de esta producción?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import * as rules from '@/utils/validations'
//import FincaSelectItem from '@/components/FincaSelectItem.vue'
import { toArray, clona, elObjetoTienePropiedades } from '@/utilidades'
//import { danotrx } from '@/modelos'
import { formulario_de_agroturismo } from '@/modelos'
//import ModalParcela from '@/components/ModalParcela.vue'
//import ModalEstablecimiento from '@/components/ModalEstablecimiento.vue'
//import SituacionDeDesastre from '@/components/form/SituacionDeDesastre.vue'

export default {
  name: 'Coagroturismo',
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      agroturismo: clona(formulario_de_agroturismo),
      loading: false,
    }
  },
  computed: {
    fincas() {
      return toArray(this.$store.state.registros.fincasc)
    },
    ...mapState('listados', ['destino', 'estatus']),
    ...mapState('registros', ['productor']),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'establecimientosPorId',
      'parcelasPorId',
      'getNombreDeFincas',
    ]),
    ...mapGetters('auth', ['user']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Agroturismo')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.agroturismo = clona(formulario_de_agroturismo)
    }
  },
  methods: {
    // registra los datos agroturísticos en el almacenamiento local
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('agroturismosc', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/agroturismo/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('agroturismosc', id)) {
        this.agroturismo = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.agroturismo.id_productor
        const ciclo = this.agroturismo.ciclo
        const fecha = this.agroturismo.fecha
        const id_finca = this.agroturismo.id_finca
        const id_parcela = this.agroturismo.id_parcela

        // required fields to be fulfilled
        if (ciclo && fecha && id_finca) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarAgroturismos',
            {
              id_productor,
              ciclo,
              fecha,
              id_finca,
              id_parcela,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getAgroturismos', registro)
        return true
      } catch (e) {
        return false
      }
    },
    /*agregarDesastre() {
      this.agroturismo.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.agroturismo.desastres?.splice(index, 1)
    },*/
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_de_agroturismo,
        ...this.$store.state.registros.agroturismosc[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>
