<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <form-meta
            :data="familia"
            :remove-commit="'removerFamilia'"
            :record-id="familia.id_productor"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Productor</div>
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium blue-grey--text">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="familia.id_productor"
            :rules="[r.required, r.max(23)]"
            label="Cédula o Pasaporte*"
            append-icon="mdi-account"
            outlined
            @input="cargarFamilia"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Consulta de vivienda familiar</div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="familia.programa_familia"
            :no-data-text="$vuetify.noDataText"
            multiple
            :items="programa_gobierno"
            label="Programas de los que se benefician"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Trabajadores fijos contratados</div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="familia.trabajador_fijo_hombre"
            :rules="[r.required, r.int]"
            label="Cantidad de Hombres*"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="familia.trabajador_fijo_mujer"
            :rules="[r.required, r.int]"
            label="Cantidad de mujeres*"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="familia.actividad_fijo"
            :no-data-text="$vuetify.noDataText"
            multiple
            :items="actividad_trabajador"
            item-text="descripcion"
            item-value="id"
            label="Actividades en las que participan"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Trabajadores eventuales contratados</div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="familia.trabajador_eventual_hombre"
            :rules="[r.required, r.int]"
            label="Cantidad de Hombres"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="familia.trabajador_eventual_mujer"
            :rules="[r.required, r.int]"
            label="Cantidad de mujeres"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="familia.actividad_eventual"
            :no-data-text="$vuetify.noDataText"
            multiple
            :items="actividad_trabajador"
            item-text="descripcion"
            item-value="id"
            label="Actividades en las que participan"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Integrantes</div>
        </v-col>
        <v-col cols="12">
          <!-- listado dinámico de integrantes de la familia -->
          <template v-for="(integrante, index) in familia.integrantes">
            <v-card :key="`${index}card`" class="elevation-0 md2-border mt-3">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-model="integrante.tipo_id"
                      :no-data-text="$vuetify.noDataText"
                      :rules="[r.required]"
                      :items="tipo_identificacion"
                      item-text="descripcion"
                      item-value="id"
                      label="Tipo de identificación*"
                      outlined
                    />
                  </v-col>
                  <v-col
                    v-if="
                      integrante.tipo_id === 1 &&
                      !r.cedula_panama.validate(integrante.id_persona).isValid
                    "
                    class="warning--text"
                  >
                    No es una cédula panameña.
                  </v-col>
                  <v-col v-if="integrante.tipo_id === 1" cols="12">
                    <v-text-field
                      v-model="integrante.id_persona"
                      :rules="[r.required, r.max(23)]"
                      placeholder="#-###-###"
                      label="Cédula*"
                      append-icon="mdi-account"
                      outlined
                    />
                  </v-col>
                  <v-col v-else-if="integrante.tipo_id === 2" cols="12">
                    <v-text-field
                      v-model="integrante.id_persona"
                      :rules="[r.required, r.max(23)]"
                      label="Pasaporte*"
                      append-icon="mdi-account"
                      outlined
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="integrante.primer_nombre"
                      :rules="[r.required, r.name, r.max()]"
                      label="Primer nombre*"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="integrante.segundo_nombre"
                      :rules="[r.name, r.max()]"
                      label="Segundo nombre"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="integrante.primer_apellido"
                      :rules="[r.required, r.name, r.max()]"
                      label="Primer apellido*"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="integrante.segundo_apellido"
                      :rules="[r.name, r.max()]"
                      label="Segundo apellido"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="integrante.apodo"
                      :rules="[r.name, r.max()]"
                      label="Apodo"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="integrante.sexo"
                      :no-data-text="$vuetify.noDataText"
                      :rules="[r.required]"
                      :items="sexo"
                      item-text="descripcion"
                      item-value="id"
                      label="Sexo*"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="integrante.parentesco"
                      :no-data-text="$vuetify.noDataText"
                      :rules="[r.required]"
                      :items="parentesco"
                      item-text="descripcion"
                      item-value="id"
                      label="Parentesco*"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <n-date
                      label="Fecha de nacimiento*"
                      :rules="[r.required]"
                      :date.sync="integrante.fecha_nacimiento"
                      range-type="birthdate"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="text-h6">Número de días de trabajo al mes</div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.number="integrante.dias_trabajo_finca"
                      :rules="[r.required, r.min_num(0), r.max_num(31)]"
                      label="En la finca*"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.number="integrante.dias_trabajo_fuera"
                      :rules="[r.required, r.min_num(0), r.max_num(31)]"
                      label="Fuera de la finca*"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.number="integrante.ingreso_fuera_finca"
                      :rules="[r.required, r.float]"
                      label="Ingreso obtenido fuera de la finca*"
                      suffix="$"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="!puedeRemoverIntegrante"
                  depressed
                  block
                  class="error--text"
                  @click="removerIntegrante(index)"
                >
                  <v-icon left>mdi-minus</v-icon>
                  Remover integrante</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
          <v-col cols="12">
            <v-btn
              depressed
              block
              class="primary--text"
              @click="agregarIntegrante"
            >
              <v-icon left>mdi-plus</v-icon>
              Agregar integrante</v-btn
            >
          </v-col>
          <v-col cols="12">
            <save-btn
              :record-status="familia.estatus"
              :left-arrow="false"
              :right-arrow="true"
              :text="'Continuar'"
              :loading="loading"
              @click="guardarYContinuar"
            />
          </v-col>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { familia, integrante_familia } from '@/modelos'
import * as rules from '@/utils/validations'
import { clona } from '@/utilidades'

export default {
  name: 'Cofamilia',
  data: () => {
    return {
      validado: false,
      r: rules,
      familia: {
        ...familia,
        integrantes: [],
      },
      loading: false,
    }
  },
  computed: {
    // retorna cierto si existe al menos un integrante en el arreglo de integrantes de la familia
    puedeRemoverIntegrante() {
      return this.familia.integrantes.length >= 1
    },
    ...mapState('listados', [
      'programa_gobierno',
      'sexo',
      'parentesco',
      'tipo_identificacion',
      'actividad_trabajador',
    ]),
    ...mapState('registros', ['productor']),
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Trabajo y Familia')
    // si existe un productor en el state, cargar la información familiar del mismo
    if (this.productor) {
      this.familia.id_productor = this.productor.id
      this.cargarFamilia()
    }
  },
  methods: {
    // agrega un objeto de integrante de familia al arreglo de integrantes
    agregarIntegrante() {
      this.familia.integrantes.push({ ...integrante_familia })
    },
    // remueve un integrante del arreglo de integrantes
    removerIntegrante(index) {
      if (this.puedeRemoverIntegrante) {
        this.familia.integrantes.splice(index, 1)
      }
    },
    // registra la familia en el almacenamiento local
    guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }

        this.$store.dispatch('registros/guardarFamilia', { ...this.familia })
        this.$router.push('/ru/cofinca')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    // carga la información de una familia en base a su id si se encuentra en el almacenamiento local, de otro modo carga un objeto vacío con el formato de familia
    async cargarFamilia() {
      const id = this.familia.id_productor
      if (id.length === 0) {
        this.familia = {
          ...familia,
          integrantes: [],
        }
      } else {
        await this.$store.dispatch('registros/getFamilias', { id })
        if (
          Object.prototype.hasOwnProperty.call(
            this.$store.state.registros.familias,
            id
          )
        ) {
          this.familia = clona(this.$store.state.registros.familias[id])
        }
      }
    },
  },
}
</script>
