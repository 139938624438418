<template>
  <v-list-item
    class="border mb-2"
    :class="{
      opaque: opaque,
      'right-border-success': sincronized,
      'right-border-warning': !sincronized,
    }"
    @click="loading ? null : showLoadingAndClick()"
  >
    <slot name="avatar" />
    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-bold">{{ topText }}</span>
        <span class="test-caption float-right">{{ topRightText }}</span>
      </v-list-item-title>
      <v-list-item-title class="text-body-2 blue-grey--text">
        {{ middleText }}
      </v-list-item-title>
      <v-list-item-title v-if="bottomText || bottomRightText">
        <span class="text-body-2 blue-grey--text">{{ bottomText }}</span>
        <span class="text-caption float-right">{{ bottomRightText }}</span>
      </v-list-item-title>
      <v-progress-linear
        absolute
        bottom
        indeterminate
        color="primary"
        :active="loading"
      ></v-progress-linear>
    </v-list-item-content>
    <v-list-item-action>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :class="sincronized ? 'success--text' : 'warning--text'"
            v-on="on"
          >
            <v-icon>{{
              sincronized ? 'mdi-check' : 'mdi-alert-circle-outline'
            }}</v-icon>
          </v-btn>
        </template>
        {{ sincronized ? 'Sincronizado' : 'Pendiente por sincronizar' }}
      </v-tooltip>
    </v-list-item-action>
    <slot name="footer" />
  </v-list-item>
</template>

<script>
export default {
  name: 'FormCard',
  props: {
    topText: {
      type: String,
      required: true,
    },
    bottomRightText: {
      type: String,
      required: false,
      default: '',
    },
    bottomText: {
      type: String,
      required: false,
      default: '',
    },
    middleText: {
      type: String,
      required: false,
      default: '',
    },
    topRightText: {
      type: String,
      required: false,
      default: '',
    },
    sincronized: {
      type: Boolean,
      required: false,
      default: false,
    },
    opaque: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    colorClass() {
      return this.sincronized ? 'success--text' : 'warning--text'
    },
  },
  methods: {
    showLoadingAndClick() {
      this.loading = true
      this.$emit('click')
    },
  },
}
</script>
<style scoped>
.border {
  border-radius: 3px;
}
.right-border-success {
  border: 1px solid rgba(76, 175, 79, 0.4);
  border-right: 7px solid #4caf50;
  border-bottom: 2px solid #4caf50;
}
.right-border-warning {
  border: 1px solid rgba(255, 193, 7, 0.5);
  border-right: 7px solid #ffc107;
  border-bottom: 2px solid #ffc107;
}
</style>
