<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de las actividades pesqueras</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="registro"
            :remove-commit="'removerPesca'"
            :record-id="registro.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.id_embarcacion"
            :rules="[r.required]"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="embarcaciones"
            item-value="id"
            item-text="nombre"
            label="Embarcación*"
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha*"
            range-type="production"
            :rules="[r.required]"
            :date.sync="registro.fecha"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.ciclo"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(9)]"
            label="Ciclo*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <!-- listado dinámico de producciones -->
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="agregarProduccion"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar producción pesquera
          </v-btn>
        </v-col>
        <template v-for="(produccion, index) in registro.producciones">
          <v-col :key="`${index}estatus`" cols="6" md="3">
            <v-select
              v-model="produccion.estatus"
              :items="estatus"
              label="Estatus"
              outlined
              disabled
            />
          </v-col>
          <v-col :key="`${index}especie`" cols="6" md="3">
            <v-select
              v-model="produccion.id_especie_pesca"
              :no-data-text="$vuetify.noDataText"
              :rules="[r.required]"
              :items="rubros_filtrados"
              item-text="descripcion"
              item-value="id"
              label="Especie*"
              outlined
              @input="debounceBuscarRegistroExistente"
            >
              <template v-slot:selection="props">
                <div v-text="props.item[props.parent.$props.itemText]" />
              </template>
            </v-select>
          </v-col>
          <v-col :key="`${index}anual`" cols="6" md="2">
            <v-text-field
              v-model.number="produccion.ingreso_anual"
              :rules="[r.required, r.float]"
              label="Ingreso anual*"
              suffix="$"
              outlined
            />
          </v-col>
          <v-col :key="`${index}produccion`" cols="6" md="4">
            <v-text-field
              v-model.number="produccion.produccion"
              :rules="[r.required, r.float]"
              label="Producción*"
              outlined
            >
              <template v-slot:append>
                <unit-picker @transform="produccion.produccion = $event" />
              </template>
              <template v-slot:append-outer>
                <v-icon
                  slot="append-outer"
                  :disabled="!puedeRemoverProduccion"
                  class="error--text"
                  @click="removerProduccion(index)"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </template>
        <!-- Situación de desastre -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'PESCA'"
          @eliminar="eliminarDesastre(index)"
        />
        <!-- listado dinámico de destinos -->
        <destino
          :destinos="registro.destinos"
          :producciones="registro.producciones"
          :listado-destinos="destino"
          :rubros="especie_pesca"
          :tabla-produccion-id-rubro="tabla_produccion_id_rubro"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="registro.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La producción ya existe'"
      :description="'Los datos colocados corresponden a una producción existente, desea cargar la información de esta producción?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import Form from '@/components/js/Form'
import { mapState, mapGetters } from 'vuex'
import { toArray, clona } from '../../utilidades'
import { danotrx } from '../../modelos'
import { produccion_pesca, formulario_pesca } from '../../modelos'
import SituacionDeDesastre from '../../components/form/SituacionDeDesastre.vue'

export default {
  name: 'Pesca',
  components: {
    Destino: () => import('@/components/form/Destino.vue'),
    SituacionDeDesastre,
  },
  extends: Form,
  data() {
    return {
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: 'id_especie_pesca',
      ruta_registros: 'pescas',
      ruta_registro: 'pesca',
      nombre_registros_en_state: 'pescas',
      nombre_registro_en_state: 'pesca',
      dispatch_get_registro: 'getPesca',
      dispatch_guardar_registro: 'guardarPesca',
      registro: clona(formulario_pesca),
      registro_produccion: clona(produccion_pesca),
    }
  },
  computed: {
    ...mapState('listados', ['especie_pesca']),
    ...mapGetters('registros', ['establecimientosPorId', 'parcelasPorId']),
    rubros_filtrados() {
      const selected = this.registro.producciones.map(
        (v) => v[this.tabla_produccion_id_rubro]
      )
      return this.especie_pesca.map((v) => ({
        ...v,
        disabled: selected.includes(v.id),
      }))
    },
    embarcaciones() {
      return toArray(this.$store.state.registros.embarcaciones)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Pesca artesanal')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_pesca)
    }
  },
  methods: {
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const ciclo = this.registro.ciclo
        const fecha = this.registro.fecha
        const id_embarcacion = this.registro.id_embarcacion
        const id_rubros = !this.obtenerIdDeRuta()
          ? this.registro.producciones
              .filter((prod) => !!prod.id_especie_pesca)
              .map((prod) => prod.id_especie_pesca)
          : []
        const rubros =
          (!this.obtenerIdDeRuta() && id_rubros.length > 0) ||
          this.obtenerIdDeRuta()

        // required fields to be fulfilled
        if (ciclo && fecha && id_embarcacion && rubros) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarPescas',
            {
              id_productor,
              ciclo,
              fecha,
              id_embarcacion,
              id_rubros,
            }
          )
          if (status === 'ok' && data.length > 1) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    agregarDesastre() {
      this.registro.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.registro.desastres?.splice(index, 1)
    },
  },
}
</script>
