<template>
  <div>
    <div class="blue--text" @click="openModal">Agregar nuevo</div>
    <v-dialog v-model="modal" persistent max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon class="error--text" @click="cerrarModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text max-height="600px" class="pa-0">
          <parcela :modal.sync="modal" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Parcela from '../views/RU/Parcela.vue'
export default {
  name: 'ModalParcela',
  components: {
    Parcela,
  },
  data: () => {
    return {
      modal: false,
    }
  },
  methods: {
    openModal() {
      this.modal = true
    },
    cerrarModal() {
      this.modal = false
    },
  },
}
</script>
