<template>
  <v-row dense class="mx-1 mb-2 pt-5 px-2 md2-border">
    <slot name="default" />
  </v-row>
</template>

<script>
export default {
  name: 'FieldsBox',
}
</script>
