<template>
  <v-app-bar
    dense
    :scroll-off-screen="false"
    app
    class="primary white--text"
    flat
  >
    <v-btn class="white--text" icon @click="abrirDrawer">
      <v-icon v-text="'mdi-menu'" />
    </v-btn>
    <v-toolbar-title
      class="font-weight-bold text-subtitle-1"
      v-text="toolbar || titulo_app"
    />
  </v-app-bar>
</template>

<script>
export default {
  name: 'EToolbar',
  computed: {
    titulo_app() {
      return this.$store.state.ui.titulo_app
    },
    toolbar() {
      return this.$store.state.ui.toolbar
    },
  },
  methods: {
    // actualiza el estado del drawer cada vez que se hace click sobre el ícono de 'menu'
    abrirDrawer() {
      this.$store.commit('ui/updateEDrawer', !this.$store.state.ui.e_drawer)
    },
  },
}
</script>
