<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de las actividades pecuarias</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="registro"
            :remove-commit="'removerPecuaria'"
            :record-id="registro.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.id_finca"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="registro.id_establecimiento"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="establecimientosPorId(registro.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="registro.id_parcela"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="parcelasPorId(registro.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha*"
            range-type="production"
            :rules="[r.required]"
            :date.sync="registro.fecha"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.ciclo"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(9)]"
            label="Ciclo*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.superficie_para_pasto"
            :rules="[r.required, r.float, () => alertSurfaceOverflow]"
            label="Superficie para pasto*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.superficie_para_hacerla_pasto"
            :rules="[r.required, r.float, () => alertSurfaceOverflow]"
            label="Superficie para hacerla pasto*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <!-- listado dinámico de producciones pecuarias -->
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="agregarProduccion"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar producción pecuaria
          </v-btn>
        </v-col>
        <n-fields-box
          v-for="(produccion, index) in registro.producciones"
          :key="`producciones-${index}`"
        >
          <v-col cols="6" md="3">
            <v-select
              v-model="produccion.estatus"
              :items="estatus"
              label="Estatus"
              outlined
              disabled
            />
          </v-col>
          <v-col cols="6" md="5">
            <v-select
              v-model="produccion.id_actividad_pecuaria"
              :no-data-text="$vuetify.noDataText"
              :rules="[r.required]"
              :items="rubros_filtrados"
              item-text="descripcion"
              item-value="id"
              label="Especie*"
              outlined
              @input="debounceBuscarRegistroExistente"
              @change="
                () => {
                  if (isDiagnosticoPecuario(produccion.id_actividad_pecuaria)) {
                    produccion.cantidad = null
                    produccion.produccion = 0
                  } else {
                    produccion.haespecia = null
                  }
                }
              "
            >
              <template v-slot:selection="props">
                <div v-text="props.item[props.parent.$props.itemText]" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="produccion.produccion"
              :rules="[r.required, r.float, r.min_num(0)]"
              label="Producción*"
              outlined
            >
              <template v-slot:append>
                <unit-picker
                  :group="`to_${guessUnit(produccion.id_actividad_pecuaria)}`"
                  @transform="produccion.produccion = $event"
                />
              </template>
              <template v-slot:append-outer>
                <v-icon
                  slot="append-outer"
                  :disabled="!puedeRemoverProduccion"
                  class="error--text"
                  @click="removerProduccion(index)"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
          <v-col
            v-show="isDiagnosticoPecuario(produccion.id_actividad_pecuaria)"
            cols="6"
            md="4"
          >
            <v-text-field
              v-model.number="produccion.haespecia"
              :rules="[r.float, r.min_num(0.1), r.max_num(10000000)]"
              label="Superficie para pasto"
              suffix="Ha"
              outlined
            />
          </v-col>
          <v-col
            v-if="!isDiagnosticoPecuario(produccion.id_actividad_pecuaria)"
            cols="6"
            md="2"
          >
            <v-text-field
              v-model.number="produccion.cantidad"
              :rules="[r.float, r.min_num(0), r.max_num(10000000)]"
              label="Cantidad"
              suffix="#"
              outlined
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              disabled
              :value="produccion.ugsumespecie"
              :rules="[r.required, r.float, r.min_num(0), r.max_num(10000000)]"
              label="ug"
              outlined
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="produccion.ingreso_anual"
              :rules="[r.required, r.float, r.min_num(0)]"
              label="Ingreso anual*"
              suffix="$"
              outlined
            />
          </v-col>
        </n-fields-box>
        <v-col v-if="pastoSurfaceOverflow" cols="12">
          <n-text error :fix-required="pastoSurfaceOverflow"
            >La superficie acumulada del pasto en la producción excede la
            superficie registrada para pasto del formulario.</n-text
          >
        </v-col>
        <!-- Situación de desastre -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'PECUARIO'"
          @eliminar="eliminarDesastre(index)"
        />
        <!-- listado dinámico de destinos -->
        <destino
          :destinos="registro.destinos"
          :producciones="registro.producciones"
          :listado-destinos="destino"
          :rubros="actividad_pecuaria"
          :tabla-produccion-id-rubro="tabla_produccion_id_rubro"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="registro.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La producción ya existe'"
      :description="'Los datos colocados corresponden a una producción existente, desea cargar la información de esta producción?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import Form from '@/components/js/Form'
import { mapState, mapGetters } from 'vuex'
import { clona } from '../../utilidades'
import {
  produccion_pecuaria,
  formulario_pecuaria,
  danotrx,
} from '../../modelos'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import ModalParcela from '../../components/ModalParcela.vue'
import ModalEstablecimiento from '../../components/ModalEstablecimiento.vue'
import SituacionDeDesastre from '../../components/form/SituacionDeDesastre.vue'

export default {
  name: 'Pecuario',
  components: {
    Destino: () => import('@/components/form/Destino.vue'),
    FincaSelectItem,
    ModalParcela,
    ModalEstablecimiento,
    SituacionDeDesastre,
  },
  extends: Form,
  data() {
    return {
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: 'id_actividad_pecuaria',
      ruta_registros: 'pecuarios',
      ruta_registro: 'pecuario',
      nombre_registros_en_state: 'pecuarias',
      nombre_registro_en_state: 'acuicultura',
      dispatch_get_registro: 'getPecuaria',
      dispatch_guardar_registro: 'guardarPecuaria',
      registro: clona(formulario_pecuaria),
      registro_produccion: clona(produccion_pecuaria),
      pastoSurfaceOverflow: false,
    }
  },
  computed: {
    ...mapGetters('registros', [
      'establecimientosPorId',
      'parcelasPorId',
      'getMatchingRecords',
    ]),
    ...mapState('listados', ['actividad_pecuaria']),
    ...mapGetters('listados', ['unidadMedidaPorId']),
    rubros_filtrados() {
      const selected = this.registro.producciones.map(
        (v) => v[this.tabla_produccion_id_rubro]
      )
      return this.actividad_pecuaria.map((v) => ({
        ...v,
        disabled: selected.includes(v.id),
      }))
    },
    alertSurfaceOverflow() {
      const id = this.registro.id
      const id_finca = this.registro.id_finca
      const year = (date) => new Date(date).getFullYear()
      const pecuarias = this.getMatchingRecords({
        recordName: 'pecuarias',
        matchValues: [
          { key: 'id_finca', value: id_finca },
          { key: 'ciclo', value: this.registro.ciclo },
        ],
        exceptIds: [id],
      })
      const fincas = this.getMatchingRecords({
        recordName: 'fincas',
        matchValues: [{ key: 'id', value: id_finca }],
      })
      const superficies_pecuarias_para_pasto = pecuarias
        .filter((p) => year(p.fecha) === year(this.registro.fecha))
        .map((p) => p.superficie_para_pasto)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      const superficies_pecuarias_para_hacerlas_pasto = pecuarias
        .filter((p) => year(p.fecha) === year(this.registro.fecha))
        .map((p) => p.superficie_para_hacerla_pasto)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      const overflow =
        superficies_pecuarias_para_pasto +
          superficies_pecuarias_para_hacerlas_pasto +
          parseFloat(this.registro.superficie_para_pasto) +
          parseFloat(this.registro.superficie_para_hacerla_pasto) >
        parseFloat(fincas?.[0]?.extension ?? 0)
      return (
        !overflow ||
        'La suma de las superficies para este año, ciclo y finca exceden la superficie de la finca'
      )
    },
  },
  watch: {
    'registro.superficie_para_pasto': {
      immediate: true,
      handler() {
        this.calculatePastoSurfaceAndWarn()
      },
    },
    'registro.producciones': {
      deep: true,
      immediate: true,
      handler() {
        this.calculatePastoSurfaceAndWarn()

        for (const [index, prod] of this.registro?.producciones?.entries()) {
          // calcula el ug para cada elemento de producción
          const isDiagnosticoPecuario = this.isDiagnosticoPecuario(
            prod.id_actividad_pecuaria
          )
          const rubro = this.getRubroById(prod.id_actividad_pecuaria)
          if (!rubro) {
            return ''
          }

          if (isDiagnosticoPecuario) {
            if (!isNaN(prod.haespecia) && !isNaN(rubro.ugequivalente)) {
              this.registro.producciones[index].ugsumespecie = (
                prod.haespecia * rubro.ugequivalente
              ).toFixed(2)
            } else {
              this.registro.producciones[index].ugsumespecie = 0
            }
          } else {
            if (!isNaN(prod.cantidad) && !isNaN(rubro.ugequivalente)) {
              this.registro.producciones[index].ugsumespecie = (
                prod.cantidad * rubro.ugequivalente
              ).toFixed(2)
            } else {
              this.registro.producciones[index].ugsumespecie = 0
            }
          }
        }
      },
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Pecuario')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_pecuaria)
    }
  },
  methods: {
    isDiagnosticoPecuario(id_cultivo) {
      return (
        this.getRubroById(id_cultivo)?.clasificacion === 'ADIAGNOSTICO PECUARIO'
      )
    },
    getRubroById(id_cultivo) {
      return this.actividad_pecuaria.find((ap) => ap.id === id_cultivo)
    },
    calculatePastoSurfaceAndWarn() {
      let surfaceSum = 0
      for (const prod of this.registro?.producciones) {
        // calcula que la suma de superfices no exceda la del formulario
        if (!isNaN(parseFloat(prod.haespecia)) && prod.haespecia > 0) {
          surfaceSum += parseFloat(prod.haespecia)
        }

        this.pastoSurfaceOverflow =
          surfaceSum > this.registro.superficie_para_pasto ? true : false
      }
    },
    guessUnit(id_rubro) {
      const rubro = this.actividad_pecuaria.find((a) => a.id === id_rubro)
      return this.unidadMedidaPorId(rubro?.id_unidad_medida ?? 2)
    },
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const ciclo = this.registro.ciclo
        const fecha = this.registro.fecha
        const id_finca = this.registro.id_finca
        const id_parcela = this.registro.id_parcela
        const id_rubros = !this.obtenerIdDeRuta()
          ? this.registro.producciones
              .filter((prod) => !!prod.id_actividad_pecuaria)
              .map((prod) => prod.id_actividad_pecuaria)
          : []
        const rubros =
          (!this.obtenerIdDeRuta() && id_rubros.length > 0) ||
          this.obtenerIdDeRuta()

        // required fields to be fulfilled
        if (ciclo && fecha && id_finca && rubros) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarPecuarias',
            {
              id_productor,
              ciclo,
              fecha,
              id_finca,
              id_parcela,
              id_rubros,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    agregarDesastre() {
      this.registro.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.registro.desastres?.splice(index, 1)
    },
  },
}
</script>
