<template>
  <n-fields-box>
    <v-row dense>
      <v-col cols="3">
        <v-select
          v-model="form.id_clasificadano"
          :items="danoFiltrado"
          :rules="[r.required]"
          outlined
          label="Daño*"
          item-text="id_clasificadano"
          item-value="id_clasificadano"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.id_actividadrubro"
          :rules="[r.required]"
          outlined
          label="Detalle*"
          :items="detalleDanoFiltrado"
          item-value="id_actividadrubro"
          item-text="id_actividadrubro"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="indiqueDetalle.indique"
          readonly
          outlined
          label="Indique"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="form.respuesta" outlined label="Respuesta" />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="form.estado"
          :rules="[r.required]"
          :items="estado_dano"
          item-text="estado_dano"
          item-value="estado_dano"
          outlined
          label="Estado*"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.cantidaddano"
          outlined
          label="Cantidad*"
          :rules="[r.required, r.int]"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          :value="indiqueDetalle.unidad"
          disabled
          outlined
          label="Unidad"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.costo_unit"
          outlined
          label="Costo Unit*"
          :rules="[r.required, r.float]"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="form.mo" outlined label="MO" />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.mo_cantidad"
          outlined
          label="Cantidad MO"
          :rules="[r.int]"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.mo_costo_unit"
          outlined
          label="Costo MO"
          :rules="[r.float]"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          :value="totalDetalle"
          disabled
          outlined
          label="Total Detalle"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field :value="totalMO" disabled outlined label="Total MO" />
      </v-col>
      <v-col cols="4">
        <v-text-field
          :value="totalFinal"
          disabled
          outlined
          label="Total final"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.aseguramiento"
          :rules="[r.int]"
          outlined
          label="Numero Asegurado"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.tipo_entidad_aseguradora"
          :items="tipo_entidad_aseguradora"
          outlined
          item-text="tipo_entidad_aseguradora"
          item-value="tipo_entidad_aseguradora"
          label="Entidad Aseguradora"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.financiamiento"
          :rules="[r.required]"
          :items="sino_text"
          outlined
          label="Financiamiento*"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.banco"
          :items="tipo_entidad_financiera"
          outlined
          label="Banco"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.describa"
          :rules="[r.String]"
          outlined
          label="Describa"
        />
      </v-col>
      <!-- <v-col cols="6">
        <form
          @submit.prevent="addArchivo"
          method="POST"
          action="/archivos"
          enctype="multipart/form-data"
        >
          <label for="imagenes">Añadir imagenes </label>
          <input
            type="file"
            @change="obtenerimagen"
            accept="image/*"
            class="form-control-file"
            name="archivos"
            id="imagen"
            multiple
          />
        </form>
      </v-col> -->
      <v-col cols="12">
        <v-btn depressed block class="red--text" @click="$emit('eliminar')">
          <v-icon left>mdi-delete</v-icon>
          Eliminar pérdida y costo
        </v-btn>
      </v-col>
    </v-row>
  </n-fields-box>
</template>

<script>
import { mapState } from 'vuex'
import * as rules from '@/utils/validations'
/* import Axios from 'axios' */

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      r: rules,
      /*  imagenMiniatura: '',
      archivo: {
        imagen: '',
      }, */
    }
  },
  computed: {
    ...mapState('listados', [
      'sino_text',
      'dano',
      'detalle_dano',
      'estado_dano',
      'tipo_entidad_financiera',
      'tipo_entidad_aseguradora',
    ]),
    danoFiltrado() {
      return this.dano?.filter((d) => d.modulo === this.modulo)
    },
    detalleDanoFiltrado() {
      return this.detalle_dano?.filter(
        (dd) => dd.id_clasificadano === this.form.id_clasificadano
      )
    },
    indiqueDetalle() {
      return (
        this.detalle_dano?.find(
          (dd) =>
            dd.id_actividadrubro === this.form.id_actividadrubro &&
            dd.id_clasificadano === this.form.id_clasificadano
        ) ?? {}
      )
    },
    totalDetalle() {
      return this.form.cantidaddano * this.form.costo_unit
    },
    totalMO() {
      return this.form.mo_cantidad * this.form.mo_costo_unit
    },
    totalFinal() {
      return this.totalDetalle + this.totalMO
    },
  },
  watch: {
    totalDetalle(val) {
      this.form.total_detalle = val
    },
    totalMO(val) {
      this.form.total_mo = val
    },
    totalFinal(val) {
      this.form.total_final = val
    },
  },
  methods: {
    /* obtenerimagen(e) {
      let file = e.target.files[0]
      console.log(file)
      this.archivo.imagen = file
      this.cargarImagen(file)
    },
    cargarImagen(file) {
      let reader = new FileReader()
      reader.onload = e => {
        this.imagenMiniatura = e.target.result
      }
      reader.readAsDataURL(file)
    },
    addArchivo() {
      let formData = new FormData()
      formData.append('archivos', this.archivo.imagen)

      Axios.post('/archivos', formData).then(response => {
        console.log(response.data)
      })
    }, */
  },
}
</script>
