<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de la cosecha</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="registro"
            :remove-commit="'removerCosecha'"
            :record-id="registro.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.estatus"
            :items="estatus"
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.id_finca"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="registro.id_establecimiento"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="establecimientosPorId(registro.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="registro.id_parcela"
            :no-data-text="$vuetify.noDataText"
            clearable
            outlined
            :items="parcelasPorId(registro.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.coa"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(5)]"
            label="Número de COA*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="registro.id_cultivo"
            :rules="[r.required]"
            :items="cultivo"
            item-text="descripcion"
            item-value="id"
            label="Rubro*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.variedad_cultivo"
            :no-data-text="$vuetify.noDataText"
            :items="variedad_cultivo"
            label="Variedad"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.semilla"
            :no-data-text="$vuetify.noDataText"
            :items="semilla"
            label="Tipo de semilla"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="registro.denominacion_varietal"
            :items="denominacionVarietalDeCultivo(registro.id_cultivo)"
            item-value="denominacion_varietal"
            item-text="denominacion_varietal"
            label="Denominación varietal"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <n-date
            label="Fecha de cosecha*"
            range-type="production"
            :rules="[
              r.required,
              (v) =>
                invalidRelativeDate.status ||
                `Fecha inválida con respecto a la ${invalidRelativeDate.record}.`,
            ]"
            :date.sync="registro.fecha"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <!-- año agricola -->
        <v-col cols="6">
          <v-text-field
            v-model="registro.anio_agricola"
            placeholder="####-####"
            :rules="[r.inta]"
            label="inserte el año agricola"
          >
          </v-text-field>
          <!-- <v-date-picker-years
            v-model="registro.anio_agricola"
            label="año agricola"
            :min="registro.anio_agricola - 1"
            :max="registro.fecha"
          ></v-date-picker-years> -->
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.ley_de_beneficio"
            :no-data-text="$vuetify.noDataText"
            :items="lista_Ley"
            item-text="programa"
            item-value="programa"
            label="Ley de beneficio"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.produccion_anual_estimada"
            :rules="[r.required, r.float, r.min_num(0)]"
            label="Producción anual estimada*"
            outlined
          >
            <template v-slot:append>
              <unit-picker
                @transform="registro.produccion_anual_estimada = $event"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.superficie_cosechada"
            :rules="[
              r.required,
              r.float,
              r.min_num(0),
              (v) => !alertar_superficie_mayor || alertar_superficie_mayor,
            ]"
            label="Superficie cosechada*"
            suffix="Ha"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.precio_de_venta"
            :rules="[r.required, r.float]"
            label="Precio de venta*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="registro.superficie_perdida"
            :rules="[r.required, r.float, r.min_num(0)]"
            label="Superficie perdida*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div
            v-show="cantidad_vendida_inversa"
            class="font-weight-medium error--text"
          >
            La cantidad vendida supera la cantidad producida.
          </div>
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            v-model.number="registro.produccion"
            :rules="[r.required, r.float]"
            label="Producción*"
            outlined
          >
            <template v-slot:append>
              <unit-picker @transform="registro.produccion = $event" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            v-model.number="registro.cantidad_vendida"
            :error="cantidad_vendida_inversa"
            :rules="[r.required, r.float]"
            label="Cantidad vendida*"
            outlined
          >
            <template v-slot:append>
              <unit-picker @transform="registro.cantidad_vendida = $event" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            disabled
            :value="rendimiento"
            label="Rendimiento"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.sistemas"
            multiple
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="sistema_produccion"
            item-text="descripcion"
            item-value="id"
            label="Modo de producción*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.tiempo_produccion"
            :rules="[r.required, r.int, r.max_num(12)]"
            label="Tiempo de producción (Meses)*"
            persistent-hint
            hint="Mes(es)"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.ingreso_anual"
            :rules="[r.required, r.float]"
            label="Ingreso anual*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <!-- Situación de desastre -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'AGRICOLA'"
          @eliminar="eliminarDesastre(index)"
        />
        <!-- listado dinámico de destinos -->
        <destino
          :destinos="registro.destinos"
          :producciones="[{ produccion: registro.produccion }]"
          :listado-destinos="destino"
          unit="kg"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="registro.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La cosecha ya existe'"
      :description="'Los datos colocados corresponden a una cosecha existente, desea cargar la información de esta cosecha?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import Form from '@/components/js/Form'
import { mapState, mapGetters } from 'vuex'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import { clona, isProductionDateValidComparedToRecords } from '../../utilidades'
import { danotrx, formulario_cosecha } from '../../modelos'
import * as rules from '@/utils/validations'
import ModalParcela from '../../components/ModalParcela.vue'
import ModalEstablecimiento from '../../components/ModalEstablecimiento.vue'
import SituacionDeDesastre from '../../components/form/SituacionDeDesastre.vue'

export default {
  name: 'Cosecha',
  components: {
    Destino: () => import('@/components/form/Destino.vue'),
    FincaSelectItem,
    ModalParcela,
    ModalEstablecimiento,
    SituacionDeDesastre,
  },
  extends: Form,
  data: () => {
    return {
      lista_Ley: [],
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      alertar_superficie_mayor: '',
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: '',
      ruta_registros: 'cosechas',
      ruta_registro: 'cosecha',
      nombre_registros_en_state: 'cosechas',
      nombre_registro_en_state: 'cosecha',
      dispatch_get_registro: 'getCosecha',
      dispatch_guardar_registro: 'guardarCosecha',
      registro: clona(formulario_cosecha),
      registro_produccion: clona({}),
    }
  },
  computed: {
    ...mapState('listados', [
      'cultivo',
      'variedad_cultivo',
      'semilla',
      'denominacion_varietal',
      'sistema_produccion',
      'programa_gobierno',
    ]),
    ...mapGetters('registros', [
      'establecimientosPorId',
      'parcelasPorId',
      'getSumaDeExtensionEnPreparaciones',
      'getSumaDeExtensionEnCosechas',
      'finca',
      'getMatchingRecords',
    ]),
    ...mapGetters('listados', ['denominacionVarietalDeCultivo']),
    sumaDeSuperficiesDeCosechas() {
      return parseInt(
        this.getSumaDeExtensionEnCosechas(
          this.registro.id,
          this.registro.id_finca,
          this.registro.coa,
          new Date(this.registro.fecha).getFullYear()
        )
      )
    },
    // retorna true si la produccion vendida excede la produccion producida
    cantidad_vendida_inversa() {
      let produccion = this.registro.produccion
      let vendido = this.registro.cantidad_vendida
      if (this.registro.unidad_produccion === 'lb') {
        produccion = produccion * this.lb_to_kg
      }
      if (this.registro.unidad_cantidad_vendida === 'lb') {
        vendido = vendido * this.lb_to_kg
      }
      return vendido > produccion
    },
    // cálculo del rendimiento de la producción
    rendimiento() {
      if (this.registro.superficie_cosechada > 0) {
        return (
          (
            this.registro.produccion / this.registro.superficie_cosechada
          ).toFixed(2) || 0
        )
      } else {
        return 0
      }
    },
    invalidRelativeDate() {
      const preparacion =
        this.getMatchingRecords({
          recordName: 'preparaciones',
          matchValues: [
            { key: 'id_productor', value: this.registro.id_productor },
            { key: 'coa', value: this.registro.coa },
            { key: 'id_finca', value: this.registro.id_finca },
            { key: 'id_parcela', value: this.registro.id_parcela },
            { key: 'id_cultivo', value: this.registro.id_cultivo },
          ],
        })?.[0] ?? {}
      const siembra =
        this.getMatchingRecords({
          recordName: 'siembras',
          matchValues: [
            { key: 'id_productor', value: this.registro.id_productor },
            { key: 'COA', value: this.registro.coa },
            { key: 'ID_FINCA', value: this.registro.id_finca },
            { key: 'ID_PARCELA', value: this.registro.id_parcela },
            { key: 'ID_CULTIVO', value: this.registro.id_cultivo },
          ],
        })?.[0] ?? {}

      const referenceRecord = {
        record: 'cosecha',
        date: this.registro.fecha,
      }
      const compareRecords = [
        { record: 'siembra', date: siembra.FECHA_SIEMBRA, cp: 'isAfter' },
        { record: 'preparacion', date: preparacion.fecha, cp: 'isAfter' },
      ].filter((v) => !!v)

      return isProductionDateValidComparedToRecords(
        referenceRecord,
        compareRecords
      )
    },
  },
  watch: {
    'registro.superficie_cosechada'() {
      this.alertSurfaceOverflow()
    },
  },
  mounted() {
    for (let i = 0; i < this.programa_gobierno.length; i++) {
      if (!this.programa_gobierno[i].indexOf('Ley')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
      if (!this.programa_gobierno[i].indexOf('LEY')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
    }
    this.$store.commit('ui/updateToolbar', 'Cosecha')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_cosecha)
    }
  },
  methods: {
    alertSurfaceOverflow() {
      this.alertar_superficie_mayor = ''
      if (this.registro.id_finca) {
        this.alertar_superficie_mayor =
          this.sumaDeSuperficiesDeCosechas +
            parseInt(this.registro.superficie_cosechada) >
          this.finca(this.registro.id_finca)?.extension
            ? 'La suma de las superficies cosechadas para este año, coa y finca exceden la superficie de la finca'
            : ''
      }
    },
    async guardarYContinuar() {
      try {
        this.loading = true
        this.alertSurfaceOverflow()
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.registro[this.tabla_principal_id] = uuid()
          this.registro.created_at = new Date().getTime() / 1000
        } else this.registro.updated_at = new Date().getTime() / 1000

        this.$store.dispatch(
          `registros/${this.dispatch_guardar_registro}`,
          clona(this.registro)
        )
        this.$router.push(`/ru/${this.ruta_registros}`)
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const coa = this.registro.coa
        const fecha = this.registro.fecha
        const id_finca = this.registro.id_finca
        const id_parcela = this.registro.id_parcela
        const id_cultivo = this.registro.id_cultivo
        // required fields to be fulfilled
        if (coa && fecha && id_finca && id_cultivo) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarCosechas',
            {
              id_productor,
              coa,
              fecha,
              id_finca,
              id_cultivo,
              id_parcela,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    agregarDesastre() {
      this.registro.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.registro.desastres?.splice(index, 1)
    },
  },
}
</script>
