<template>
  <v-container>
    <v-row dense>
      <!-- listado de fincas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="registro in registros">
            <form-card
              :key="registro.id"
              :top-text="registro.nombre || 'Sin nombre...'"
              :middle-text="`Número de registro • ${
                registro.num_registro || '(no posee)'
              }`"
              :bottom-right-text="producerDescription(registro.productor)"
              :sincronized="registro.sincronizado"
              @click="traerRegistroYNavegar(registro)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { producerDescription } from '@/utilidades'
//import Establecimientos from './Establecimientos.vue'
//import Parcelas from './Parcelas.vue'

export default {
  name: 'Cofincas',
  //components: {
  //Establecimientos,
  //Parcelas,
  //},
  computed: {
    ...mapState('registros', {
      registros: 'fincasc',
    }),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Fincas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      await this.$store.dispatch('registros/getFincas', { id: registro.id })
      this.$router.push(`/ru/cofinca/${registro.id}`)
    },
  },
}
</script>
