import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import app from './app'
import listados from './listados'
import registros from './registros'
import ui from './ui'
import reportes from './reportes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    app: app,
    ui: ui,
    listados: listados,
    registros: registros,
    reportes: reportes,
  },
  plugins: [createPersistedState()],
})
