// módulo con todo el estado relacionado a la interfaz de la aplicación (títulos globales, toolbars, etc)
export default {
  namespaced: true,
  state: {
    titulo_app: 'SIGAP',
    toolbar: '',
    e_drawer: false,
    offline_modal: false,
    unauthorized_modal: false,
    old_record_modal: false,
    existing_record_modal: false,
    online: true,
    snack: {
      show: false,
      text: '',
      timeout: 4000,
      color: 'success',
    },
  },
  mutations: {
    setOnlineStatus(state, status) {
      state.online = status
    },
    // actualiza el estado del snackbar global
    setSnack(
      state,
      { show = true, text = '', timeout = 4000, color = 'success' }
    ) {
      state.snack = { show, text, timeout, color }
    },
    // actualiza el título de la aplicación
    updateTituloApp(state, titulo) {
      state.titulo_app = titulo
    },
    // actualiza el título del toolbar de la aplicación
    updateToolbar(state, titulo) {
      state.toolbar = titulo
    },
    // actualiza el estado de la visibilidad del drawer izquierdo
    updateEDrawer(state, drawer) {
      state.e_drawer = drawer
    },
    // actualiza el estado de la visibilidad del modal de notificación del estado
    // de la conexión
    updateOfflineModal(state, estado) {
      state.offline_modal = estado
    },
    updateUnauthorizedModal(state, estado) {
      state.unauthorized_modal = estado
    },
    updatedOldRecordModal(state, estado) {
      state.old_record_modal = estado
    },
    updatedExistingRecordModal(state, estado) {
      state.existing_record_modal = estado
    },
  },
}
