<template>
  <v-btn
    v-if="canSave"
    depressed
    block
    :loading="loading"
    class="primary"
    @click="$emit('click')"
  >
    <v-icon v-if="leftArrow" left>mdi-arrow-left</v-icon>
    {{ text }}
    <v-icon v-if="rightArrow" right>mdi-arrow-right</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    recordStatus: {
      type: String,
      default: '',
    },
    recordId: {
      type: [String, Number],
      default: '',
    },
    text: {
      type: String,
      default: 'Guardar y Regresar',
    },
    leftArrow: {
      type: Boolean,
      default: true,
    },
    rightArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canSave() {
      return (
        !['D', 'V', 'R'].includes(this.recordStatus) ||
        (this.recordStatus === 'R' && !isNaN(this.recordId))
      )
    },
  },
}
</script>
