<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de las actividades acuícolas</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="registro"
            :remove-commit="'removerAcuicultura'"
            :record-id="registro.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.id_finca"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <modal-establecimiento />
          <v-select
            v-model="registro.id_establecimiento"
            clearable
            outlined
            :items="establecimientosPorId(registro.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          />
        </v-col>
        <v-col cols="6">
          <modal-parcela />
          <v-select
            v-model="registro.id_parcela"
            clearable
            outlined
            :items="parcelasPorId(registro.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Tina"
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <n-date
            range-type="production"
            :rules="[r.required]"
            :date.sync="registro.fecha"
            @change="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.ciclo"
            :rules="[r.required, r.int, r.min_num(1), r.max_num(9)]"
            label="Ciclo*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="registro.espejo_de_agua"
            :rules="[r.required, r.float, () => alertSurfaceOverflow]"
            label="Tamaño del estanque (espejo de agua)*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <!-- listado dinámico de producciones -->
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="primary--text"
            @click="agregarProduccion"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar producción acuícola
          </v-btn>
        </v-col>
        <v-row
          v-for="(produccion, index) in registro.producciones"
          :key="`producciones-${index}`"
          dense
        >
          <v-col cols="6" md="3">
            <v-select
              v-model="produccion.estatus"
              :items="estatus"
              label="Estatus"
              outlined
              disabled
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-select
              v-model="produccion.id_especie_acuicola"
              :rules="[r.required]"
              :items="rubros_filtrados"
              item-text="descripcion"
              item-value="id"
              label="Especie*"
              outlined
              @input="debounceBuscarRegistroExistente"
            >
              <template v-slot:selection="props">
                <div v-text="props.item[props.parent.$props.itemText]" />
              </template>
            </v-select>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              v-model.number="produccion.ingreso_anual"
              :rules="[r.required, r.float]"
              label="Ingreso anual*"
              suffix="$"
              outlined
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="produccion.produccion"
              :rules="[r.required, r.float]"
              label="Producción*"
              outlined
            >
              <template v-slot:append>
                <unit-picker @transform="produccion.produccion = $event" />
              </template>
              <template v-slot:append-outer>
                <v-icon
                  slot="append-outer"
                  :disabled="!puedeRemoverProduccion"
                  class="error--text"
                  @click="removerProduccion(index)"
                  >mdi-close</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Situación de desastre -->
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'ACUICULTURA'"
          @eliminar="eliminarDesastre(index)"
        />
        <!-- listado dinámico de destinos -->

        <destino
          :destinos="registro.destinos"
          :producciones="registro.producciones"
          :listado-destinos="destino"
          :rubros="especie_acuicola"
          :tabla-produccion-id-rubro="tabla_produccion_id_rubro"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <!-- Boton para guardar y continuar -->
        <v-col cols="12">
          <save-btn
            :record-status="registro.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La producción ya existe'"
      :description="'Los datos colocados corresponden a una producción existente, desea cargar la información de esta producción?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import Form from '@/components/js/Form'
import { mapState, mapGetters } from 'vuex'
import { clona } from '../../utilidades'
import { danotrx } from '../../modelos'
import { produccion_acuicola, formulario_acuicultura } from '../../modelos'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import ModalParcela from '../../components/ModalParcela.vue'
import ModalEstablecimiento from '../../components/ModalEstablecimiento.vue'
import SituacionDeDesastre from '../../components/form/SituacionDeDesastre.vue'
export default {
  name: 'Acuicultura',
  components: {
    Destino: () => import('@/components/form/Destino.vue'),
    FincaSelectItem,
    ModalParcela,
    ModalEstablecimiento,
    SituacionDeDesastre,
  },
  extends: Form,
  data() {
    return {
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: 'id_especie_acuicola',
      ruta_registros: 'acuiculturas',
      ruta_registro: 'acuicultura',
      nombre_registros_en_state: 'acuiculturas',
      nombre_registro_en_state: 'acuicultura',
      dispatch_get_registro: 'getAcuicultura',
      dispatch_guardar_registro: 'guardarAcuicultura',
      registro: clona(formulario_acuicultura),
      registro_produccion: clona(produccion_acuicola),
    }
  },
  computed: {
    ...mapState('listados', ['especie_acuicola']),
    ...mapGetters('registros', [
      'establecimientosPorId',
      'parcelasPorId',
      'getMatchingRecords',
    ]),
    ...mapGetters('listados', ['cultivo']),
    rubros_filtrados() {
      const selected = this.registro.producciones.map(
        (v) => v[this.tabla_produccion_id_rubro]
      )
      return this.especie_acuicola.map((v) => ({
        ...v,
        disabled: selected.includes(v.id),
      }))
    },
    alertSurfaceOverflow() {
      const id = this.registro.id
      const id_finca = this.registro.id_finca
      const year = (date) => new Date(date).getFullYear()
      const acuiculturas = this.getMatchingRecords({
        recordName: 'acuiculturas',
        matchValues: [
          { key: 'id_finca', value: id_finca },
          { key: 'ciclo', value: this.registro.ciclo },
        ],
        exceptIds: [id],
      })
      const fincas = this.getMatchingRecords({
        recordName: 'fincas',
        matchValues: [{ key: 'id', value: id_finca }],
      })
      const superficies_acuicolas = acuiculturas
        .filter((a) => year(a.fecha) === year(this.registro.fecha))
        .map((a) => a.espejo_de_agua)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      const overflow =
        superficies_acuicolas + parseFloat(this.registro.espejo_de_agua) >
        parseFloat(fincas?.[0]?.extension ?? 0)
      return (
        !overflow ||
        'La suma de las superficies de los espejos de agua para este año, ciclo y finca exceden la superficie de la finca'
      )
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Acuicultura')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_acuicultura)
    }
  },
  methods: {
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const ciclo = this.registro.ciclo
        const fecha = this.registro.fecha
        const id_finca = this.registro.id_finca
        const id_parcela = this.registro.id_parcela
        const id_rubros = !this.obtenerIdDeRuta()
          ? this.registro.producciones
              .filter((prod) => !!prod.id_especie_acuicola)
              .map((prod) => prod.id_especie_acuicola)
          : []
        const rubros =
          (!this.obtenerIdDeRuta() && id_rubros.length > 0) ||
          this.obtenerIdDeRuta()

        // required fields to be fulfilled
        if (ciclo && fecha && id_finca && rubros) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarAcuiculturas',
            {
              id_productor,
              ciclo,
              fecha,
              id_finca,
              id_parcela,
              id_rubros,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    agregarDesastre() {
      this.registro.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.registro.desastres?.splice(index, 1)
    },
  },
}
</script>
