<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Información de la parcela</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="parcela"
            :remove-commit="'removerParcela'"
            :record-id="parcela.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="parcela.id_finca"
            :no-data-text="$vuetify.noDataText"
            :rules="[r.required]"
            :items="fincas"
            item-text="nombre"
            item-value="id"
            label="Finca*"
            outlined
            @input="debounceBuscarRegistroExistente"
          >
            <template slot="item" slot-scope="{ item }">
              <finca-select-item :item="item" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="parcela.nombre"
            :rules="[r.required, r.max(45)]"
            label="Nombre*"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6" md="4">
          <v-text-field
            v-model="parcela.porcentaje"
            :rules="[
              r.required,
              r.float,
              (v) =>
                !extensionOverflow ||
                'La superficie de las parcelas supera la de la finca.',
            ]"
            label="Superficie"
            suffix="HA"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="parcela.tipo_parcela"
            :items="tipo_parcela"
            label="Tipo de parcela"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
            v-model="parcela.estado"
            :items="estado_parcela"
            item-text="NOMBRE"
            item-value="ID_STATUS"
            :rules="[r.required]"
            label="Estado*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
          <div>
            La parcela sirve para que usted realice la trazabilidad y para que
            diversos clientes consulten el lugar de origen del producto dentro
            de una Finca.
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="obtenerPosicion">
            <v-icon left>mdi-crosshairs</v-icon>
            Registrar ubicación actual
          </v-btn>
          <div class="text-center">
            Registrar la ubicación solo cuando se encuentre en el lugar de la
            parcela.
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="parcela.longitud"
            :rules="[r.x_coord]"
            label="Longitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="parcela.latitud"
            :rules="[r.y_coord]"
            label="Latitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="parcela.observacion"
            :rules="[r.max(1000)]"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="parcela.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La parcela ya existe'"
      :description="'Los datos colocados corresponden a una parcela existente, desea cargar la información de esta parcela?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import debounce from 'lodash.debounce'
import { mapState, mapGetters } from 'vuex'
import FincaSelectItem from '../../components/FincaSelectItem.vue'
import {
  toArray,
  getCurrentPosition,
  clona,
  elObjetoTienePropiedades,
  sumRecordsProp,
} from '../../utilidades'
import { formulario_parcela } from '../../modelos'
import * as rules from '@/utils/validations'

export default {
  name: 'Parcela',
  components: {
    FincaSelectItem,
  },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      parcela: clona(formulario_parcela),
      loading: false,
    }
  },
  computed: {
    ...mapState('listados', ['tipo_parcela', 'estado_parcela']),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'getMatchingRecords',
      'getRecord',
    ]),
    fincas() {
      return toArray(this.$store.state.registros.fincas)
    },
    extensionOverflow() {
      const matchingRecords = this.getMatchingRecords({
        recordName: 'parcelas',
        matchValues: [{ key: 'id_finca', value: this.parcela.id_finca }],
        exceptIds: [this.parcela.id],
      })
      const totalExtension = sumRecordsProp(matchingRecords, 'porcentaje')
      const fincaExtension =
        this.getRecord({
          recordName: 'fincas',
          key: this.parcela.id_finca,
        })?.extension ?? 0
      return (
        totalExtension + parseFloat(this.parcela.porcentaje) > fincaExtension
      )
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Parcela')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.parcela = clona(formulario_parcela)
    }
  },
  methods: {
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicion() {
      const position = await getCurrentPosition()
      if (position) {
        this.parcela.longitud = position.coords.longitude
        this.parcela.latitud = position.coords.latitude
      }
    },
    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.parcela.id = uuid()
          this.parcela.created_at = new Date().getTime() / 1000
        }
        this.parcela.updated_at = new Date().getTime() / 1000

        this.$store.dispatch('registros/guardarParcela', clona(this.parcela))

        this.parcela = clona(formulario_parcela)
        if (this.modal) this.$emit('update:modal', false)
        else this.$router.push('/ru/fincas')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('parcelas', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/parcela/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('parcelas', id)) {
        this.parcela = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_finca = this.parcela.id_finca
        const nombre = this.parcela.nombre
        // required fields to be fulfilled
        if (nombre && id_finca) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarParcelas',
            {
              id_finca,
              nombre,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getParcela', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_parcela,
        ...this.$store.state.registros.parcelas[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>
