<template>
  <div>
    <v-alert :value="true" border="right" :type="alertType" class="mb-2">
      {{ alertMessage }}
    </v-alert>
    <v-btn
      v-if="can_delete"
      class="grey--text"
      block
      depressed
      @click="confirm_dialog = true"
    >
      <v-icon small left v-text="'mdi-delete'" />
      Remover del dispositivo
    </v-btn>
    <div class="mb-2">
      <div class="text-body-2" v-text="created_at"></div>
      <div class="text-body-2" v-text="updated_at"></div>
      <div class="text-body-2" v-text="created_by"></div>
      <div
        v-if="data.id_institucion"
        class="text-body-2"
        v-text="
          `Institución: ${institucionPorId(data.id_institucion).descripcion}`
        "
      ></div>
    </div>
    <v-dialog v-model="confirm_dialog" max-width="300px" persistent>
      <v-card class="error" dark>
        <v-card-title class="text-h6">Eliminar del dispositivo</v-card-title>
        <v-card-text
          >Esta versión del registro existente en el dispositivo no será
          recuperable.</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="confirm_dialog = false">Cancelar</v-btn>
          <v-spacer />
          <v-btn text @click="removeFromDevice">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'FormMeta',
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeCommit: {
      type: String,
      required: true,
    },
    recordId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      confirm_dialog: false,
    }
  },
  computed: {
    ...mapGetters('listados', ['institucionPorId']),
    created_by() {
      if (
        Object.prototype.hasOwnProperty.call(this.data, 'created_by') &&
        this.data.created_by
      ) {
        return 'Realizado por ' + this.data.created_by
      }
      return ''
    },
    created_at() {
      if (
        Object.prototype.hasOwnProperty.call(this.data, 'created_at') &&
        this.data.created_at
      ) {
        if (typeof this.data.created_at === 'number') {
          return (
            'Creado el ' + moment(this.data.created_at * 1000).format('LLLL')
          )
        }
        return 'Creado el ' + moment(this.data.created_at).format('LLLL')
      }
      return ''
    },
    updated_at() {
      if (
        Object.prototype.hasOwnProperty.call(this.data, 'updated_at') &&
        this.data.updated_at
      ) {
        if (typeof this.data.updated_at === 'number') {
          return (
            'Actualizado el ' +
            moment(this.data.updated_at * 1000).format('LLLL')
          )
        }
        return 'Actualizado el ' + moment(this.data.updated_at).format('LLLL')
      }
      return ''
    },
    can_delete() {
      const isProductor = Object.prototype.hasOwnProperty.call(
        this.data,
        'sexo'
      )
      const isPesca = Object.prototype.hasOwnProperty.call(
        this.data,
        'id_embarcacion'
      )
      const isProduccion = Object.prototype.hasOwnProperty.call(
        this.data,
        'id_parcela'
      )
      const isSiembra = Object.prototype.hasOwnProperty.call(
        this.data,
        'ID_PARCELA'
      )
      const isFamilia = Object.prototype.hasOwnProperty.call(
        this.data,
        'integrantes'
      )
      const isUndefined = !this.recordId
      const sincronizado = this.data.sincronizado
      return (
        ['D', 'V', 'R'].includes(this.data.estatus) ||
        ((isProductor ||
          isProduccion ||
          isSiembra ||
          isPesca ||
          isFamilia ||
          isUndefined) &&
          !sincronizado)
      )
    },
    alertMessage() {
      switch (this.data.estatus) {
        case 'D':
          return 'Este registro fue modificado por otra institución. Actualice el registro más reciente.'
        case 'V':
          return 'Existe un registro más reciente para estos datos. Actualice el registro correspondiente.'
        case 'R':
          return 'Los datos contenidos en este registro ya existen en un registro capturado previamente.'
        default:
          return this.data.sincronizado
            ? 'Información sincronizada'
            : 'Pendiente por sincronizar'
      }
    },
    alertType() {
      switch (this.data.estatus) {
        case 'D':
          return 'warning'
        case 'V':
          return 'error'
        case 'R':
          return 'error'
        default:
          return this.data.sincronizado ? 'success' : 'warning'
      }
    },
  },
  methods: {
    removeFromDevice() {
      if (!this.recordId) {
        this.$store.commit(`registros/${this.removeCommit}`, 'undefined')
      } else {
        this.$store.commit(`registros/${this.removeCommit}`, this.recordId)
      }
      this.$emit('removed', true)
    },
  },
}
</script>
