<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <!-- listado de siembras en el almacenamiento local -->
        <v-list three-line>
          <template v-for="siembra in siembrasc">
            <form-card
              :key="siembra.ID"
              :top-text="`Finca • ${getNombreDeFincas(siembra.ID_FINCA)}`"
              :bottom-right-text="producerDescription(siembra.productor)"
              :middle-text="`Siembra de ${
                cultivo(siembra.ID_CULTIVO).descripcion
              }`"
              :bottom-text="`Coa ${siembra.COA}`"
              :top-right-text="siembra.FECHA_SIEMBRA"
              :sincronized="siembra.sincronizado"
              @click="traerRegistroYNavegar(siembra)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription, parcelaDescription } from '@/utilidades'

export default {
  name: 'Cosiembras',
  computed: {
    ...mapState('registros', ['siembrasc']),
    ...mapGetters('listados', ['cultivo']),
    ...mapGetters('registros', ['getNombreDeFincas']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Siembra')
  },
  methods: {
    producerDescription,
    parcelaDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getSiembras', { id: registro.ID })
      }
      this.$router.push(`/ru/cocultivo/${registro.ID}`)
    },
  },
}
</script>
