<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/apicultura" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar actividad apícola
        </v-btn>
      </v-col>
      <!-- listado de actividades apícolas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="apicultura in apiculturas">
            <form-card
              :key="apicultura.id"
              :top-text="`Finca • ${getNombreDeFinca(apicultura.id_finca)}`"
              :middle-text="apicultura.descripcion"
              :bottom-text="`Ciclo ${apicultura.ciclo}`"
              :top-right-text="apicultura.fecha"
              :bottom-right-text="producerDescription(apicultura.productor)"
              :sincronized="apicultura.sincronizado"
              :opaque="apicultura.hasOutdatedRecords"
              @click="traerRegistroYNavegar(apicultura)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Cosechas',
  computed: {
    ...mapState('registros', ['apiculturas']),
    ...mapGetters('registros', ['getNombreDeFinca']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Apiculturas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getApicultura', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/apicultura/${registro.id}`)
    },
  },
}
</script>
