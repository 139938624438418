<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/agroindustria" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar actividad agroindustrial
        </v-btn>
      </v-col>
      <!-- listado de actividades agroindustriales en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="agroindustria in agroindustrias">
            <form-card
              :key="agroindustria.id"
              :top-text="`Finca • ${getNombreDeFinca(agroindustria.id_finca)}`"
              :top-right-text="agroindustria.fecha"
              :middle-text="agroindustria.descripcion"
              :bottom-text="`Ciclo ${agroindustria.ciclo}`"
              :bottom-right-text="producerDescription(agroindustria.productor)"
              :sincronized="agroindustria.sincronizado"
              @click="traerRegistroYNavegar(agroindustria)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Cosechas',
  computed: {
    ...mapState('registros', ['agroindustrias']),
    ...mapGetters('registros', ['getNombreDeFinca']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Agroindustrias')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getAgroindustria', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/agroindustria/${registro.id}`)
    },
  },
}
</script>
