<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Productor</div>
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="embarcacion.id_productor"
            :rules="[r.required, r.max(23)]"
            label="Cédula o Pasaporte*"
            append-icon="mdi-account"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Información de la embarcacion</div>
        </v-col>
        <v-col cols="12">
          <form-meta
            :data="embarcacion"
            :remove-commit="'removerEmbarcacion'"
            :record-id="embarcacion.id"
            @removed="$router.go(-1)"
          />
        </v-col>
        <v-col cols="12">
          <!-- REVIEW -->
          <v-text-field
            v-model="embarcacion.nombre"
            :rules="[r.required, r.max(100)]"
            label="Nombre"
            outlined
            @input="debounceBuscarRegistroExistente"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="embarcacion.numero_unico"
            label="Número único"
            :rules="[r.int]"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="embarcacion.licencia"
            :rules="[r.max(100)]"
            label="Licencia"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="embarcacion.tipo_embarcacion"
            :rules="[r.required]"
            :items="tipo_embarcacion"
            item-value="id"
            item-text="descripcion"
            label="Tipo de embarcación*"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="embarcacion.sub_tipo_embarcacion"
            :items="subTiposEmbarcacionPorId(embarcacion.tipo_embarcacion)"
            item-text="sub_tipo_embarcacion"
            label="Sub tipo"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="embarcacion.zona_pesca"
            :items="zona_pesca"
            label="Zona de pesca"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Ubicación (WGS84)</div>
        </v-col>
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="obtenerPosicion">
            <v-icon left>mdi-crosshairs</v-icon>
            Registrar ubicación actual</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="embarcacion.longitud"
            :rules="[r.x_coord]"
            label="Longitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="embarcacion.latitud"
            :rules="[r.y_coord]"
            label="Latitud"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <save-btn
            :record-status="embarcacion.estatus"
            :loading="loading"
            @click="guardarYContinuar"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La embarcación ya existe'"
      :description="'Los datos colocados corresponden a una embarcación existente, desea cargar la información de esta embarcación?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import uuid from 'uuid/v4'
import debounce from 'lodash.debounce'
import { mapState, mapGetters } from 'vuex'
import {
  getCurrentPosition,
  clona,
  elObjetoTienePropiedades,
} from '../../utilidades'
import { formulario_embarcacion } from '../../modelos'
import * as rules from '@/utils/validations'

export default {
  name: 'Embarcacion',
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      embarcacion: clona(formulario_embarcacion),
      loading: false,
    }
  },
  computed: {
    ...mapState('listados', [
      'tipo_embarcacion',
      'sub_tipo_embarcacion',
      'zona_pesca',
    ]),
    ...mapGetters('listados', ['subTiposEmbarcacionPorId']),
    ...mapState('registros', ['productor']),
    ...mapGetters('registros', ['existeRegistroEnState']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Embarcacion')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.embarcacion = clona(formulario_embarcacion)
      this.embarcacion.id_productor = this.productor.id
      this.obtenerPosicion()
    }
  },
  methods: {
    // obtiene la posición y la carga a los campos [x, y] del formulario
    async obtenerPosicion() {
      const position = await getCurrentPosition()
      if (position) {
        this.embarcacion.longitud = position.coords.longitude
        this.embarcacion.latitud = position.coords.latitude
      }
    },
    // genera un id si no posee uno y registra la información en el almacenamiento local
    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.embarcacion.id = uuid()
          this.embarcacion.created_at = new Date().getTime() / 1000
        }
        this.embarcacion.updated_at = new Date().getTime() / 1000

        this.$store.dispatch(
          'registros/guardarEmbarcacion',
          clona(this.embarcacion)
        )
        this.$router.push('/ru/embarcaciones')
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('embarcaciones', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/embarcacion/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('embarcaciones', id)) {
        this.embarcacion = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.embarcacion.id_productor
        const nombre = this.embarcacion.nombre
        // required fields to be fulfilled
        if (nombre && id_productor) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarEmbarcaciones',
            {
              nombre,
              id_productor,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getEmbarcacion', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...clona(formulario_embarcacion),
        ...clona(this.$store.state.registros.embarcaciones[id]),
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>
