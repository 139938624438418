<template>
  <v-dialog v-model="modal" max-width="350" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ description }}
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$emit('cerrarModal', true)"> Cancelar </v-btn>
        <v-spacer />
        <v-btn
          class="teal--text"
          text
          :loading="loading"
          @click="$emit('cargarDatos', true)"
        >
          Cargar información
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RegistroExistente',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
}
</script>
