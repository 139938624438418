<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/acuicultura" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar actividad acuícola
        </v-btn>
      </v-col>
      <!-- listado de actividades acuícolas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="registro in registros">
            <form-card
              :key="registro.id"
              :top-text="`Finca • ${getNombreDeFinca(registro.id_finca)}`"
              :top-right-text="registro.fecha"
              :middle-text="parcelaDescription(registro.parcela)"
              :bottom-text="`Ciclo ${registro.ciclo}`"
              :bottom-right-text="producerDescription(registro.productor)"
              :sincronized="registro.sincronizado"
              :opaque="registro.hasOutdatedRecords"
              @click="traerRegistroYNavegar(registro)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription, parcelaDescription } from '@/utilidades'

export default {
  name: 'Cosechas',
  computed: {
    ...mapState('registros', {
      registros: 'acuiculturas',
      fincas: 'fincas',
    }),
    ...mapGetters('registros', ['getNombreDeFinca']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Acuiculturas')
  },
  methods: {
    producerDescription,
    parcelaDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getAcuicultura', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/acuicultura/${registro.id}`)
    },
  },
}
</script>
