<template>
  <v-container>
    <v-row dense>
      <!-- listado de actividades acuícolas en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="registro in registros">
            <form-card
              :key="registro.id"
              :top-text="`Finca • ${getNombreDeFincas(registro.id_finca)}`"
              :top-right-text="registro.fecha"
              :middle-text="parcelaDescription(registro.parcela)"
              :bottom-text="`Ciclo ${registro.ciclo}`"
              :bottom-right-text="producerDescription(registro.productor)"
              :sincronized="registro.sincronizado"
              :opaque="registro.hasOutdatedRecords"
              @click="traerRegistroYNavegar(registro)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { producerDescription, parcelaDescription } from '@/utilidades'

export default {
  name: 'Coacuiculturas',
  computed: {
    ...mapState('registros', {
      registros: 'acuiculturasc',
      fincas: 'fincasc',
    }),
    ...mapGetters('registros', ['getNombreDeFincas']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Acuiculturas')
  },
  methods: {
    producerDescription,
    parcelaDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getAcuiculturas', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/coacuicultura/${registro.id}`)
    },
  },
}
</script>
