<template>
  <div>
    <v-container v-if="!isLoading && online">
      <v-card class="elevation-0">
        <v-card-text>
          <h1
            v-text="`${productor.primer_nombre} ${productor.primer_apellido}`"
          ></h1>
          <div class="font-weight-bold" v-text="productor.id"></div>
          <div v-if="c.familiar" class="font-weight-bold green--text">
            El productor es un agricultor familiar
          </div>
          <div v-else class="text-body-2 error--text">
            El productor no es un agricultor familiar.
          </div>
          <div v-if="c.familiar" class="text-body-2">
            Tipo {{ c.tipo_familiar }}
          </div>
          <div
            v-text="
              `
            Este comercializa el ${c.porcentaje_destino}% de lo que produce.`
            "
          ></div>
          <div v-show="false" class="pt-3">
            El sistema llegó a esta conclusión de acuerdo a los siguientes
            cruces:
          </div>
        </v-card-text>
        <v-list>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                :class="color_familiar(c.administrada_por_valida)"
                v-text="c.administrada_por_valida ? 'mdi-check' : 'mdi-close'"
              >
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="text-body-1"
                v-text="
                  c.administrada_por_valida
                    ? 'Todas las fincas del productor son adminstradas por la familia.'
                    : 'Una o más fincas son administradas por un administrador.'
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                :class="color_familiar(c.distancia_vivienda_valida)"
                v-text="c.distancia_vivienda_valida ? 'mdi-check' : 'mdi-close'"
              >
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="text-body-1"
                v-text="
                  c.distancia_vivienda_valida
                    ? 'La vivienda del productor se encuentra a menos de 50km de todas las fincas.'
                    : 'La vivienda del productor se encuentra a 50km o más de alguna de las fincas.'
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                :class="color_familiar(c.extension_tenencias < 50)"
                v-text="c.extension_tenencias < 50 ? 'mdi-check' : 'mdi-close'"
              >
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="text-body-1"
                v-text="
                  c.extension_tenencias < 50
                    ? `El productor, sumando todas sus fincas (${c.extension_tenencias}Ha), posee menos de 50Ha.*`
                    : `El productor, sumando todas sus fincas (${c.extension_tenencias}Ha), posee 50Ha o más.*`
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                :class="color_familiar(c.trabajador_fijo_hombre == 0)"
                v-text="
                  c.trabajador_fijo_hombre == 0 ? 'mdi-check' : 'mdi-close'
                "
              >
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="text-body-1"
                v-text="
                  c.trabajador_fijo_hombre == 0
                    ? 'El productor no posee trabajadores fijos hombres.'
                    : `El productor posee (${c.trabajador_fijo_hombre}) trabajadores fijos hombres.`
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                :class="color_familiar(c.trabajador_fijo_mujer == 0)"
                v-text="
                  c.trabajador_fijo_mujer == 0 ? 'mdi-check' : 'mdi-close'
                "
              >
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div
                class="text-body-1"
                v-text="
                  c.trabajador_fijo_mujer == 0
                    ? 'El productor no posee trabajadores fijos mujeres.'
                    : `El productor posee (${c.trabajador_fijo_mujer}) trabajadores fijos mujeres.`
                "
              ></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-body-1">
              El productor genera
              <v-chip color="green lighten-1 white--text" small>{{
                `${c.p_ingresos_finca}%`
              }}</v-chip>
              de sus ingresos de la finca desglosados asi:
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_acuicola}$`
              }}</v-chip
              >Acuicultura
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_cosechas}$`
              }}</v-chip
              >Cosechas
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_agroturismo}$`
              }}</v-chip
              >Agroturismos
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_agroindustrial}$`
              }}</v-chip
              >Agroindustria
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_apicultura}$`
              }}</v-chip
              >Apicultura
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_artesanal}$`
              }}</v-chip
              >Artesanía
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_forestal}$`
              }}</v-chip
              >Forestal
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_pecuario}$`
              }}</v-chip
              >Pecuario
            </div>
            <div class="py-1 text-body-1">
              <v-chip class="mr-3" color="green lighten-1 white--text" small>{{
                `${c.ingreso_pesca}$`
              }}</v-chip
              >Pesca
            </div>
            <div class="text-body-1">
              Que sumado da
              <v-chip color="green lighten-1 white--text" small>{{
                `${c.ingresos_fincas}$`
              }}</v-chip>
              vs
              <v-chip color="green lighten-1 white--text" small>{{
                `${c.ingresos_fuera}$`
              }}</v-chip>
              generados fuera de la finca. Totalizando
              <v-chip small class="green lighten-1 white--text">{{
                `${c.ingresos_totales}$`
              }}</v-chip>
              ingresos totales.
            </div>
            <div class="grey--text pt-3">
              * Las fincas que pertenecen a comarcas no se suman.
            </div>
          </v-card-text>
        </v-list>
      </v-card>
    </v-container>
    <div v-else-if="!online">
      <v-row class="text-center">
        <v-col cols="12">
          <v-icon size="128" color="primary">mdi-cloud-off-outline</v-icon>
          <n-text bold
            >Se require de conexión a internet para obtener esta
            información</n-text
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-skeleton-loader v-for="n in 5" :key="n" type="article" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ClasificacionProductor',
  data: () => {
    return {
      productor: {},
      c: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapState('ui', ['online']),
  },
  async mounted() {
    this.$store.commit('ui/updateToolbar', 'Clasificación')
    await this.getProductor()
  },
  methods: {
    async getProductor() {
      try {
        this.isLoading = true
        const id_productor = this.$route.params.id || ''
        if (id_productor) {
          const data = (
            await this.$store.dispatch('registros/getDefinicion', {
              id: id_productor,
            })
          ).data
          this.productor = data.productor
          this.c = data.c
        }
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.isLoading = false
      }
    },
    color_familiar(familiar) {
      return familiar ? 'green--text' : 'error--text'
    },
  },
}
</script>
