import { geolocationAvailable, getCurrentPosition } from '../utilidades'

// módulo con todo el estado relacionado al estado de la aplicación (conexión, geolocalización)
export default {
  namespaced: true,
  state: {
    geolocation: false,
    position: {},
    x: '',
    y: '',
  },
  mutations: {
    // modifica el estado de la disponibilidad de geolocalización en la aplicación
    geolocationDisponible(state, disponibilidad) {
      state.geolocation = disponibilidad
    },
    // asigna la información de la posición [x, y] y el objeto mismo al state de la aplicación
    setGeolocation(state, position) {
      state.position = position
      state.x = position.coords.longitude
      state.y = position.coords.latitude
    },
  },
  actions: {
    // verifica que la geolocalización esté disponible en el dispositivo
    // obtiene la posición actual
    // registra la posición actual en el state
    async registrarPosicionActual({ commit }) {
      const geolocation = geolocationAvailable()
      if (geolocation) {
        commit('geolocationDisponible', geolocation)
        const position = await getCurrentPosition()
        if (position) {
          commit('setGeolocation', position)
        }
      } else {
        commit('geolocationDisponible', geolocation)
      }
    },
  },
}
