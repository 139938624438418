<template>
  <v-container>
    <v-row dense>
      <!-- listado de actividades pesqueras en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="pesca in pescasc">
            <form-card
              :key="pesca.id"
              :top-text="`Embarcación ${
                embarcaciones[pesca.id_embarcacion].nombre || 'sin nombre...'
              }`"
              :bottom-right-text="producerDescription(pesca.productor)"
              :middle-text="`Ciclo ${pesca.ciclo}`"
              :top-right-text="pesca.fecha"
              :sincronized="pesca.sincronizado"
              :opaque="pesca.hasOutdatedRecords"
              @click="traerRegistroYNavegar(pesca)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Copescas',
  computed: {
    ...mapState('registros', [
      'pescasc',
      'fincas',
      'productores',
      'embarcacionesc',
    ]),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Pescas')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getPescas', { id: registro.id })
      }
      this.$router.push(`/ru/copesca/${registro.id}`)
    },
  },
}
</script>
