/**
 * Conjunto de functiones utilizadas para validar datos. Estas funciones son usadas principalmente en la validación de campos en formularios.
 * Estas funciones están pensadas para ser utilizadas con v-form de vuetify. Para más información https://vuetifyjs.com/en/components/forms
 * @module Validations
 */
import cedula_panama from 'cedula-panama'

const text = {
  REQUIRED: 'Requerido',
  TOO_LONG: 'Texto demasiado largo',
  WRONG_PA_ID: 'Formato de cédula equivocado',
  INVALID_NUMBER: 'Número inválido',
  INVALID_NAME: 'Nombre inválido',
  INVALID_EMAIL: 'Correo inválido',
  WEAK_PASSWORD:
    'Incluya en al menos 8 caracteres, una letra minúscula y mayúscula, un número y un carácter especial (!@#$%^&*).',
}
const default_size = 254
const default_num_size = 2147483647

/**
 * @typedef {function} FunctionValidation
 * @param {*} v - Input
 * @returns {boolean|string} Verdadero si cumple con las condiciones, falso o una cadena con el error si no.
 */

/**
 * Valida que el contenido recibido sea válido, es decir, sea un número, un arreglo con al menos un elemento o una cadena con carácteres.
 * @param {*} v - Input
 * @returns {boolean|string} Verdadero si cumple con las condiciones, falso o una cadena con el error si no.
 */
const required = (v) => {
  if (typeof v === 'number') return true
  else if (Array.isArray(v)) return v.length > 0 || text.REQUIRED
  else return !!v || text.REQUIRED
}
/**
 * Valida que un texto no contenga más carácteres que la cantidad especificada.
 * @param {number} max_size - Numero máximo de carácteres que puede tener el texto.
 * @returns {FunctionValidation}
 */
const max = (max_size = default_size) => {
  return (v) =>
    [undefined, null, ''].includes(v) ||
    String(v)?.length <= max_size ||
    text.TOO_LONG
}
/**
 * Valida que el contenido recibido sea un número.
 * @param {*} v - Input
 * @returns {boolean|string} Verdadero si cumple con las condiciones, falso o una cadena con el error si no.
 */
const int = (v) => {
  return (
    [undefined, null, ''].includes(v) ||
    /^(\d+)*$/.test(v) ||
    text.INVALID_NUMBER
  )
}
const inta = (v) => {
  return (
    [undefined, null, ''].includes(v) ||
    /^(?:([1-9][0-9][0-9][0-9])-([1-9][0-9][0-9][0-9]))*$/.test(v) ||
    text.INVALID_NUMBER
  )
}
const min_num = (min) => {
  return (v) =>
    [undefined, null, ''].includes(v) || v >= min || text.INVALID_NUMBER
}

const max_num = (max = default_num_size) => {
  return (v) =>
    [undefined, null, ''].includes(v) || v <= max || text.INVALID_NUMBER
}

const exact_number = (val) => {
  return (v) => parseFloat(v) === parseFloat(val)
}

const float = (v) => {
  return (
    [undefined, null, ''].includes(v) ||
    /^\d+(\.\d{1,2})?$/.test(v) ||
    text.INVALID_NUMBER
  )
}

const pa_id = (v) => {
  return cedula_panama.validate(v).isComplete || text.WRONG_PA_ID
}

const name = (v) => {
  return /^[ .a-zA-ZÁÉÍÓÚÑáéíóúñ]*$/.test(v) || text.INVALID_NAME
}

const x_coord = (v) => {
  return (
    [null, undefined, ''].includes(v) ||
    /^(\+|-)?(?:180(?:(?:\.0{1,15})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(
      v
    ) ||
    text.INVALID_NUMBER
  )
}

const y_coord = (v) => {
  return (
    [null, undefined, ''].includes(v) ||
    /^(\+|-)?(?:90(?:(?:\.0{1,15})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(
      v
    ) ||
    text.INVALID_NUMBER
  )
}

const coord = (v) => {
  return (
    [null, undefined, ''].includes(v) ||
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
      v
    ) ||
    text.INVALID_NUMBER
  )
}

const email = (v) => {
  return (
    [null, undefined, ''].includes(v) ||
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    ) ||
    text.INVALID_EMAIL
  )
}

const password = (v) => {
  return (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(v) ||
    text.WEAK_PASSWORD
  )
}

export {
  required,
  max,
  int,
  inta,
  min_num,
  max_num,
  pa_id,
  name,
  float,
  coord,
  x_coord,
  y_coord,
  email,
  cedula_panama,
  exact_number,
  text,
  password,
}
