<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Consulta de las actividades artesanales</div>
        </v-col>
        <v-col cols="12">
          <div class="font-weight-medium grey--text text--darken-2">
            * Campos requeridos
          </div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="registro.estatus"
            readonly
            :items="estatus"
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="getNombreDeFincas(registro.id_finca)"
            label="Finca*"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.id_establecimiento"
            readonly
            :no-data-text="$vuetify.noDataText"
            outlined
            :items="establecimientosPorId(registro.id_finca)"
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.id_parcela"
            readonly
            :no-data-text="$vuetify.noDataText"
            outlined
            :items="parcelasPorId(registro.id_finca)"
            item-value="id"
            item-text="nombre"
            label="Parcela"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <n-date
            readonly
            label="Fecha*"
            range-type="production"
            :date.sync="registro.fecha"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="registro.ciclo"
            readonly
            label="Ciclo*"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.descripcion"
            readonly
            counter="1000"
            label="Descripción*"
            outlined
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="registro.produccion"
            readonly
            label="Producción*"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.number="registro.ingreso_anual"
            readonly
            label="Ingreso anual*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <!-- Situación de desastre
        <v-col cols="12">
          <v-btn depressed block class="primary--text" @click="agregarDesastre">
            <v-icon left>mdi-plus</v-icon>
            Agregar situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'ARTESANIA'"
          @eliminar="eliminarDesastre(index)"
        /> -->
        <!-- listado dinámico de destinos -->
        <codestino
          :destinos="registro.destinos"
          :producciones="[{ produccion: registro.produccion }]"
          :listado-destinos="destino"
          :unit-select="false"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="registro.observacion"
            readonly
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La producción ya existe'"
      :description="'Los datos colocados corresponden a una producción existente, desea cargar la información de esta producción?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import Form from '@/components/js/Form'
import { mapGetters } from 'vuex'
import * as rules from '@/utils/validations'
//import FincaSelectItem from '@/components/FincaSelectItem.vue'
import { clona } from '@/utilidades'
//import { danotrx } from '@/modelos'
import { formulario_artesania } from '@/modelos'
//import ModalParcela from '@/components/ModalParcela.vue'
//import ModalEstablecimiento from '@/components/ModalEstablecimiento.vue'
//import SituacionDeDesastre from '@/components/form/SituacionDeDesastre.vue'

export default {
  name: 'Artesania',
  components: {
    Codestino: () => import('@/components/form/CoDestino.vue'),
    //FincaSelectItem,
    //ModalParcela,
    //ModalEstablecimiento,
    //SituacionDeDesastre,
  },
  extends: Form,
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      registro: clona(formulario_artesania),
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: '',
      ruta_registros: 'artesanias',
      ruta_registro: 'artesania',
      nombre_registros_en_state: 'artesaniasc',
      nombre_registro_en_state: 'artesania',
      dispatch_get_registro: 'getArtesanias',
      registro_produccion: clona({}),
    }
  },
  computed: {
    ...mapGetters('registros', [
      'establecimientosPorId',
      'parcelasPorId',
      'getNombreDeFincas',
    ]),
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Artesanía')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_artesania)
    }
  },
  methods: {
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const ciclo = this.registro.ciclo
        const fecha = this.registro.fecha
        const id_finca = this.registro.id_finca
        const id_parcela = this.registro.id_parcela

        // required fields to be fulfilled
        if (ciclo && fecha && id_finca) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarArtesanias',
            {
              id_productor,
              ciclo,
              fecha,
              id_finca,
              id_parcela,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
  },
}
</script>
