<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Consulta de la preparación</div>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="preparacion.estatus"
            :items="estatus"
            readonly
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="getNombreDeFincas(preparacion.id_finca)"
            label="Finca*"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.id_establecimiento"
            label="RUA"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="getNombreDeParcela(preparacion.id_parcela)"
            label="Parcela"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.coa"
            label="Número de COA*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="cultivoPorId(preparacion.id_cultivo)"
            label="Rubro*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.variedad_cultivo"
            label="Variedad"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.semilla"
            label="Tipo de semilla"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="preparacion.denominacion_varietal"
            :items="denominacionVarietalDeCultivo(preparacion.id_cultivo)"
            readonly
            item-value="denominacion_varietal"
            item-text="denominacion_varietal"
            label="Denominación varietal"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.fecha"
            label="Fecha de la preparación*"
          />
        </v-col>
        <!-- año agricola -->
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.anio_agricola"
            label="inserte el año agricola"
          >
          </v-text-field>
          <!-- <v-date-picker-years
            v-model="preparacion.anio_agricola"
            label="año agricola"
            :min="preparacion.anio_agricola - 1"
            :max="preparacion.fechaSeleccionada"
          ></v-date-picker-years> -->
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.ley_de_beneficio"
            :items="lista_Ley"
            readonly
            item-text="programa"
            item-value="programa"
            label="Ley de beneficio"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.superficie_preparada"
            label="Superficie preparada*"
            suffix="Ha"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="preparacion.tipo_prima_seguro"
            label="Tipo de prima de seguro"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="preparacion.financiamiento"
            :items="sino"
            readonly
            item-text="descripcion"
            item-value="id"
            label="Financiamiento"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="preparacion.observacion"
            label="Observaciones"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La preparación ya existe'"
      :description="'Los datos colocados corresponden a una preparación existente, desea cargar la información de esta preparación?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
//import FincaSelectItem from '../../components/FincaSelectItem.vue'
import {
  toArray,
  clona,
  elObjetoTienePropiedades,
  isProductionDateValidComparedToRecords,
} from '@/utilidades'
import { formulario_preparacion } from '@/modelos'
import * as rules from '@/utils/validations'
//import ModalParcela from '@/components/ModalParcela.vue'
//import ModalEstablecimiento from '@/components/ModalEstablecimiento.vue'
//import NDate from '../../components/global/NDate.vue'
export default {
  name: 'Preparacion',
  data: () => {
    return {
      estadito: '',
      lista_Ley: [],
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      preparacion: clona(formulario_preparacion),
      alertar_superficie_mayor: '',
      loading: false,
    }
  },
  computed: {
    ...mapState('listados', [
      'cultivo',
      'variedad_cultivo',
      'semilla',
      'denominacion_varietal',
      'tipo_prima_seguro',
      'sino',
      'estatus',
      'programa_gobierno',
      // TOREVIEW: 'planificacion',
    ]),
    ...mapGetters('registros', [
      'existeRegistroEnState',
      'getSumaDeExtensionEnPreparaciones',
      'finca',
      'getMatchingRecords',
      'getNombreDeParcela',
      'getNombreDeFincas',
    ]),
    ...mapGetters('listados', [
      'denominacionVarietalDeCultivo',
      'cultivoPorId',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
    ]),
    fincas() {
      return toArray(this.$store.state.registros.fincasc)
    },
    sumaDeSuperficiesDePreparaciones() {
      return parseInt(
        this.getSumaDeExtensionEnPreparaciones(
          this.preparacion.id,
          this.preparacion.id_finca,
          this.preparacion.coa,
          new Date(this.preparacion.fecha).getFullYear()
        )
      )
    },
    invalidRelativeDate() {
      const siembra =
        this.getMatchingRecords({
          recordName: 'siembrasc',
          matchValues: [
            { key: 'id_productor', value: this.preparacion.id_productor },
            { key: 'COA', value: this.preparacion.coa },
            { key: 'ID_FINCA', value: this.preparacion.id_finca },
            { key: 'ID_PARCELA', value: this.preparacion.id_parcela },
            { key: 'ID_CULTIVO', value: this.preparacion.id_cultivo },
          ],
        })?.[0] ?? {}
      const cosecha =
        this.getMatchingRecords({
          recordName: 'cosechasc',
          matchValues: [
            { key: 'id_productor', value: this.preparacion.id_productor },
            { key: 'coa', value: this.preparacion.coa },
            { key: 'id_finca', value: this.preparacion.id_finca },
            { key: 'id_parcela', value: this.preparacion.id_parcela },
            { key: 'id_cultivo', value: this.preparacion.id_cultivo },
          ],
        })?.[0] ?? {}

      const referenceRecord = {
        record: 'preparacion',
        date: this.preparacion.fecha,
      }
      const compareRecords = [
        { record: 'siembra', date: siembra.FECHA_SIEMBRA, cp: 'isBefore' },
        { record: 'cosecha', date: cosecha.fecha, cp: 'isBefore' },
      ].filter((v) => !!v)

      return isProductionDateValidComparedToRecords(
        referenceRecord,
        compareRecords
      )
    },
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'preparacion.superficie_preparada'() {
      this.alertSurfaceOverflow()
    },
    'preparacion.estatus'() {
      if (this.preparacion.estatus === 'A') {
        this.estadito = 'Actualizado'
      } else {
        this.estadito = 'Desactualizado'
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.programa_gobierno.length; i++) {
      if (!this.programa_gobierno[i].indexOf('Ley')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
      if (!this.programa_gobierno[i].indexOf('LEY')) {
        this.lista_Ley.push(this.programa_gobierno[i])
      }
    }
    this.$store.commit('ui/updateToolbar', 'Consulta Preparación')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.preparacion = clona(formulario_preparacion)
    }
  },
  methods: {
    datepickerAbierto: function () {
      console.log('El datepicker ha sido abierto')
    },
    fechaSeleccionada: function () {
      console.log('Una fecha ha sido seleccionada')
    },
    datepickerCerrado: function () {
      console.log('El datepicker ha sido cerrado')
    },
    alertSurfaceOverflow() {
      this.alertar_superficie_mayor = ''
      if (this.preparacion.id_finca) {
        this.alertar_superficie_mayor =
          this.sumaDeSuperficiesDePreparaciones +
            parseInt(this.preparacion.superficie_preparada) >
          this.finca(this.preparacion.id_finca)?.extension
            ? 'La suma de las superficies preparadas para este año, coa y finca exceden la superficie de la finca'
            : ''
      }
    },
    // genera un id si la preparación no posee uno y registra la preparación en el almacenamiento local
    async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState('preparacionesc', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/copreparacion/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState('preparacionesc', id)) {
        this.preparacion = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro.id ? registro.id.toString() : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.preparacion.id_productor
        const coa = this.preparacion.coa
        const fecha = this.preparacion.fecha
        const id_finca = this.preparacion.id_finca
        const id_parcela = this.preparacion.id_parcela
        const id_cultivo = this.preparacion.id_cultivo
        const anio_agricola = this.preparacion.anio_agricola
        // required fields to be fulfilled
        if (coa && fecha && id_finca && id_cultivo) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarPreparaciones',
            {
              id_productor,
              coa,
              fecha,
              id_finca,
              id_cultivo,
              id_parcela,
              anio_agricola,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch('registros/getPreparaciones', registro)
        return true
      } catch (e) {
        return false
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...formulario_preparacion,
        ...this.$store.state.registros.preparacionesc[id],
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
</script>
