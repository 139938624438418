<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/productor" depressed class="primary">
          <v-icon left>mdi-plus</v-icon>
          Agregar productor
        </v-btn>
      </v-col>
      <!-- listado de productores en el almacenamiento local -->
      <v-col cols="12">
        <v-list two-line>
          <template v-for="(productor, index) in productores">
            <form-card
              :key="productor.id"
              :top-text="`${productor.primer_nombre} ${productor.primer_apellido}`"
              :middle-text="productor.id"
              :sincronized="productor.sincronizado"
              @click="traerRegistroYNavegar(productor)"
            >
              <template v-slot:avatar>
                <v-list-item-avatar>
                  <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon v-text="'mdi-dots-vertical'" />
                      </v-btn>
                    </template>
                    <v-card class="pa-3">
                      <v-row class="py-1">
                        <v-btn
                          v-if="productor.sincronizado"
                          class="success--text"
                          text
                          block
                          small
                          :disabled="loading_add"
                          @click.stop="cargarProductor(productor)"
                        >
                          <v-icon left v-text="'mdi-reload'" />
                          Cargar expediente nuevamente
                        </v-btn>
                      </v-row>
                      <v-row class="py-1">
                        <v-btn
                          :key="`v-btn-${index}`"
                          :loading="loading_delete"
                          block
                          small
                          class="error--text"
                          text
                          @click="eliminarTodoDe(productor.id)"
                        >
                          <v-icon left v-text="'mdi-delete'" />
                          Archivar Expediente
                        </v-btn>
                      </v-row>
                    </v-card>
                  </v-menu>
                </v-list-item-avatar>
              </template>
            </form-card>
          </template>
        </v-list>
      </v-col>
      <v-col cols="12" class="pb-3">
        <div class="text-h6">Búsqueda de productores</div>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="id"
          :rules="[r.max(23)]"
          outlined
          label="Cédula o Pasaporte"
          name="cedula"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="ruc"
          :rules="[r.max(23)]"
          outlined
          label="RUC"
          name="ruc"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="nombre"
          :rules="[r.name]"
          outlined
          label="Nombre"
          name="nombre"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="apellido"
          :rules="[r.name]"
          outlined
          label="Apellido"
          name="apellido"
          @keyup.enter="buscarProductores"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading_search"
          depressed
          block
          class="primary"
          @click="buscarProductores"
        >
          <v-icon left>mdi-magnify</v-icon>
          Buscar
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-iterator :items="listado_productores" :options="options">
          <template v-slot:item="props">
            <v-list-item :key="props.item.id">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >{{ props.item.primer_nombre || '' }}
                  {{ props.item.primer_apellido || '' }}</v-list-item-title
                >
                <v-list-item-subtitle class="text-body-2 blue-grey--text"
                  >{{ props.item.id }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="existsInDevice(props.item.id)"
                  class="orange--text text-body-2"
                  >Este productor ya está agregado en el
                  dispositivo</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action v-show="!existsInDevice(props.item.id)">
                <v-btn
                  :disabled="loading_add"
                  icon
                  @click="cargarProductor(props.item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`v-divider-${props.item.id}`"></v-divider>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as rules from '@/utils/validations'

export default {
  name: 'Productores',
  data: () => {
    return {
      r: rules,
      options: {
        itemsPerPage: 10,
      },
      //productor: {},
      listado_productores: [],
      id: '',
      nombre: '',
      apellido: '',
      ruc: '',
      loading_search: false,
      loading_add: false,
      loading_delete: false,
    }
  },
  computed: {
    productores() {
      return this.$store.state.registros.productores
    },
    ...mapGetters('registros', ['id_productores']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Productores')
  },
  methods: {
    existsInDevice(id = 0) {
      return this.id_productores.includes(id)
    },
    async eliminarTodoDe(id_productor) {
      try {
        this.loading_delete = true
        await this.$store.dispatch(
          'registros/eliminarDatosDeProductor',
          id_productor
        )
        this.loading_delete = false
      } catch (e) {
        this.loading_delete = false
      }
    },
    async buscarProductores() {
      try {
        this.loading_search = true
        const res = await this.$store.dispatch('registros/listarProductores', {
          id: this.id,
          nombre: this.nombre,
          apellido: this.apellido,
          ruc: this.ruc,
        })
        const { status, data } = res
        if (status === 'ok') {
          this.listado_productores = data
        }
        this.loading_search = false
      } catch (e) {
        this.loading_search = false
      }
    },
    async cargarProductor(productor) {
      try {
        this.loading_add = true
        await this.$store.dispatch('registros/getExpediente', productor.id)
        this.$store.commit('ui/setSnack', {
          text: 'Datos agregados al dispositivo',
        })
        this.loading_add = false
      } catch (e) {
        this.loading_add = false
      }
    },
    /*async mostrarProductor(productor) {
      this.loading_add = true
      await this.$store.dispatch('registros/getProductor', {
        id: productor.id,
      })
      this.$router.push(`/ru/productor/${productor.id}`)
    },*/
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getProductor', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/productor/${registro.id}`)
    },
  },
}
</script>
