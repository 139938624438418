<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6" v-text="user.name || ''"></div>
          <div class="subheading" v-text="user.email || ''"></div>
        </v-col>
        <v-col cols="12">
          <div class="text-h6">Actualizar contraseña</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="actual"
            type="password"
            append-icon="mdi-key"
            :rules="[r.required, r.max()]"
            label="Contraseña actual*"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="nueva"
            type="password"
            append-icon="mdi-key"
            :rules="[r.password, r.max(16)]"
            label="Contraseña nueva*"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="repetir_nueva"
            type="password"
            append-icon="mdi-key"
            persistent-hint
            :hint="no_igual ? 'Las contraseñas no coinciden' : ''"
            :error="no_igual"
            :rules="[r.password, r.max(16)]"
            label="Repetir contraseña nueva*"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            :loading="loading"
            :disabled="!valid"
            depressed
            block
            class="primary"
            @click="actualizarPassword"
            >Actualizar contraseña</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { url_base } from '../utilidades'
import * as rules from '@/utils/validations'
import { mapGetters } from 'vuex'

export default {
  name: 'Cuenta',
  data: () => {
    return {
      valid: false,
      r: rules,
      url_base: url_base,
      actual: '',
      nueva: '',
      repetir_nueva: '',
      loading: false,
    }
  },
  computed: {
    no_igual() {
      return this.nueva !== this.repetir_nueva
    },
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Cambio de contraseña')
  },
  methods: {
    async actualizarPassword() {
      this.loading = true
      const response = await this.$store.dispatch('auth/actualizarPassword', {
        actual: this.actual,
        nueva: this.nueva,
        repetir_nueva: this.repetir_nueva,
      })
      if (response.success) {
        this.$store.commit('ui/setSnack', {
          text: response.custom_message,
          color: 'success',
        })
        this.actual = ''
        this.nueva = ''
        this.repetir_nueva = ''
        this.$refs.form.reset()
      } else {
        this.$store.commit('ui/setSnack', {
          text: response.custom_message,
          color: 'error',
        })
      }
      this.loading = false
    },
  },
}
</script>
