import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

Sentry.init({
  dsn: 'https://0beec4fa17104434a41a14a04941028b@sentry.io/5188756',
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
})

Vue.prototype.$sentry = Sentry
