import uuid from 'uuid/v4'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { toArray, elObjetoTienePropiedades, clona } from '../../utilidades'
import * as rules from '@/utils/validations'

export default {
  name: 'Form',
  data: () => {
    return {
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      tabla_principal_id: '',
      tabla_produccion_id_rubro: '',
      ruta_registros: '',
      ruta_registro: '',
      nombre_registros_en_state: '',
      nombre_registro_en_state: '',
      dispatch_get_registro: '',
      dispatch_guardar_registro: '',
      registro_existente: {},
      registro: {},
      registro_produccion: {},
      total_porcentajes: 0,
      loading: false,
    }
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
  },
  computed: {
    // retorna cierto si existe al menos una producción acuícola en el arreglo de producciones acuícolas
    puedeRemoverProduccion() {
      return this.registro.producciones.length >= 2
    },
    fincas() {
      return toArray(this.$store.state.registros.fincas)
    },
    ...mapState('listados', [
      'destino',
      'peso',
      'peso_default',
      'lb_to_kg',
      'estatus',
    ]),
    ...mapState('registros', ['productor']),
    ...mapGetters('registros', ['existeRegistroEnState']),
  },
  methods: {
    async guardarYContinuar() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) {
          this.$store.commit('ui/setSnack', {
            text: 'Verifique los elementos en rojo',
            color: 'error',
          })
          return ''
        }
        if (await this.buscarRegistroExistente()) return

        if (!this.obtenerIdDeRuta()) {
          this.registro[this.tabla_principal_id] = uuid()
          this.registro.created_at = new Date().getTime() / 1000
        } else this.registro.updated_at = new Date().getTime() / 1000

        this.$store.dispatch(
          `registros/${this.dispatch_guardar_registro}`,
          clona(this.registro)
        )
        this.$router.push(`/ru/${this.ruta_registros}`)
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async cargarRegistroExistente() {
      const id = this.registro_existente[this.tabla_principal_id]
      this.modal_registro_existe_loading = true
      if (!this.existeRegistroEnState(this.nombre_registros_en_state, id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/${this.nombre_registro_en_state}/${id}`)
      this.cerrarModalRegistroExistente()
      this.modal_registro_existe_loading = false
    },
    cargarRegistro(id) {
      if (this.existeRegistroEnState(this.nombre_registros_en_state, id)) {
        this.registro = this.obtenerRegistroDelState(id)
        this.$refs.form.resetValidation()
        return true
      } else return false
    },
    async buscarRegistroExistente() {
      const registro = await this.obtenerUnRegistroONada()
      if (elObjetoTienePropiedades(registro)) {
        const id = registro[this.tabla_principal_id]
          ? registro[this.tabla_principal_id].toString()
          : ''
        if (id === this.obtenerIdDeRuta()) return false
        this.almacenarRegistroEncontradoTemporalmente(registro)
        this.mostrarModalDeRegistroExistente()
        return true
      }
      return false
    },
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async cargarRegistroDelServer(registro) {
      try {
        await this.$store.dispatch(
          `registros/${this.dispatch_get_registro}`,
          registro
        )
        return true
      } catch (e) {
        return false
      }
    },
    agregarProduccion() {
      this.registro.producciones.push(clona(this.registro_produccion))
    },
    // remueve una producción del arreglo de producciones
    removerProduccion(index) {
      if (this.puedeRemoverProduccion) {
        this.registro.producciones.splice(index, 1)
      }
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalRegistroExistente() {
      this.modal_registro_existe = false
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    obtenerRegistroDelState(id) {
      return {
        ...clona(this.registro_produccion),
        ...clona(
          this.$store.state.registros[this.nombre_registros_en_state][id]
        ),
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
  },
}
