<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Consulta de la cosecha</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="estad"
            label="Estatus"
            outlined
            disabled
          />
        </v-col>
        <!-- <v-col cols="12">
          <v-text-field
            readonly
            :value="registro.id_finca"
            label="Finca*"
            outlined
          ></v-text-field>
        </v-col>  -->
        <v-col cols="12">
          <v-text-field
            readonly
            :value="getNombreDeFincas(registro.id_finca)"
            label="Finca*"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.id_establecimiento"
            outlined
            :items="establecimientosPorId(registro.id_finca)"
            readonly
            item-value="id"
            item-text="rua"
            label="RUA"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.id_parcela"
            outlined
            :items="parcelasPorId(registro.id_finca)"
            readonly
            item-value="id"
            item-text="nombre"
            label="Parcela"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.coa"
            label="Número de COA*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="cultivoPorId(registro.id_cultivo)"
            label="Rubro*"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.variedad_cultivo"
            :items="variedad_cultivo"
            readonly
            label="Variedad"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.semilla"
            :items="semilla"
            readonly
            label="Tipo de semilla"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="registro.denominacion_varietal"
            :items="denominacionVarietalDeCultivo(registro.id_cultivo)"
            readonly
            item-value="denominacion_varietal"
            item-text="denominacion_varietal"
            label="Denominación varietal"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.fecha"
            label="Fecha de cosecha*"
          />
        </v-col>
        <!-- año agricola -->
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.anio_agricola"
            label="inserte el año agricola"
          >
          </v-text-field>
          <!-- <v-date-picker-years
            v-model="registro.anio_agricola"
            label="año agricola"
            :min="registro.anio_agricola - 1"
            :max="registro.fecha"
          ></v-date-picker-years> -->
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.ley_de_beneficio"
            :items="lista_Ley"
            readonly
            item-text="programa"
            item-value="programa"
            label="Ley de beneficio"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.produccion_anual_estimada"
            label="Producción anual estimada*"
            outlined
          >
            <template v-slot:append>
              <unit-picker
                @transform="registro.produccion_anual_estimada = $event"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.superficie_cosechada"
            label="Superficie cosechada*"
            suffix="Ha"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.precio_de_venta"
            label="Precio de venta*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.superficie_perdida"
            label="Superficie perdida*"
            suffix="Ha"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div
            v-show="cantidad_vendida_inversa"
            class="font-weight-medium error--text"
          >
            La cantidad vendida supera la cantidad producida.
          </div>
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            readonly
            :value="registro.produccion"
            label="Producción*"
            outlined
          >
            <template v-slot:append>
              <unit-picker @transform="registro.produccion = $event" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="8" md="6">
          <v-text-field
            readonly
            :value="registro.cantidad_vendida"
            :error="cantidad_vendida_inversa"
            label="Cantidad vendida*"
            outlined
          >
            <template v-slot:append>
              <unit-picker @transform="registro.cantidad_vendida = $event" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            disabled
            :value="rendimiento"
            label="Rendimiento"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="registro.sistemas"
            multiple
            :items="sistema_produccion"
            readonly
            item-text="descripcion"
            item-value="id"
            label="Modo de producción*"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.tiempo_produccion"
            label="Tiempo de producción (Meses)*"
            persistent-hint
            hint="Mes(es)"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            :value="registro.ingreso_anual"
            label="Ingreso anual*"
            suffix="$"
            outlined
          ></v-text-field>
        </v-col>
        <!-- Situación de desastre
        <v-col cols="12">
          <v-btn depressed block class="primary--text">
            situación de desastre
          </v-btn>
        </v-col>
        <situacion-de-desastre
          v-for="(desastre, index) in registro.desastres"
          :key="desastre.id_trxdano"
          :form="registro.desastres[index]"
          :modulo="'AGRICOLA'"
        /> -->
        <!-- listado dinámico de destinos -->
        <codestino
          :destinos="registro.destinos"
          :producciones="[{ produccion: registro.produccion }]"
          :listado-destinos="destino"
          unit="kg"
        />
        <v-col cols="12">
          <div class="text-h6">Observaciones</div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="registro.observacion"
            label="Observaciones"
            auto-grow
            outlined
            rows="1"
          />
        </v-col>
      </v-row>
    </v-form>
    <registro-existente
      :title="'La cosecha ya existe'"
      :description="'Los datos colocados corresponden a una cosecha existente, desea cargar la información de esta cosecha?'"
      :loading="modal_registro_existe_loading"
      :modal="modal_registro_existe"
      @cerrarModal="cerrarModalRegistroExistente"
      @cargarDatos="cargarRegistroExistente"
    />
  </v-container>
</template>

<script>
import Form from '@/components/js/Form'
import { mapState, mapGetters } from 'vuex'
//import FincaSelectItem from '../../components/FincaSelectItem.vue'
import { clona, isProductionDateValidComparedToRecords } from '@/utilidades'
import { formulario_cosecha } from '@/modelos'
//import danotrx, de modelos de arriba
import * as rules from '@/utils/validations'
//import ModalParcela from '@/components/ModalParcela.vue'
//import ModalEstablecimiento from '@/components/ModalEstablecimiento.vue'
//import SituacionDeDesastre from '@/components/form/SituacionDeDesastre.vue'

export default {
  name: 'Cocosecha',
  components: {
    Codestino: () => import('@/components/form/CoDestino.vue'),
    //ModalParcela,
    //ModalEstablecimiento,
    //SituacionDeDesastre,
  },
  extends: Form,
  data: () => {
    return {
      lista_Ley: [],
      estad: '',
      fincasi: {},
      parcelita: {},
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      registro_existente: {},
      alertar_superficie_mayor: '',
      tabla_principal_id: 'id',
      tabla_produccion_id_rubro: '',
      ruta_registros: 'cosechas',
      ruta_registro: 'cosecha',
      nombre_registros_en_state: 'cosechasc',
      nombre_registro_en_state: 'cosecha',
      dispatch_get_registro: 'getCosechas',
      registro: clona(formulario_cosecha),
      registro_produccion: clona({}),
    }
  },
  computed: {
    ...mapState('listados', [
      'cultivo',
      'variedad_cultivo',
      'semilla',
      'denominacion_varietal',
      'sistema_produccion',
      'programa_gobierno',
    ]),
    ...mapState('registros', {
      cosechas: 'cosechasc',
    }),
    ...mapGetters('registros', [
      'establecimientosPorId',
      'parcelasPorId',
      'getSumaDeExtensionEnPreparaciones',
      'getSumaDeExtensionEnCosechas',
      'finca',
      'getMatchingRecords',
      'getNombreDeParcela',
      'getNombreDeFincas',
    ]),
    ...mapGetters('listados', [
      'denominacionVarietalDeCultivo',
      'cultivoPorId',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
    ]),
    sumaDeSuperficiesDeCosechas() {
      return parseInt(
        this.getSumaDeExtensionEnCosechas(
          this.registro.id,
          this.registro.id_finca,
          this.registro.coa,
          new Date(this.registro.fecha).getFullYear()
        )
      )
    },
    // retorna true si la produccion vendida excede la produccion producida
    cantidad_vendida_inversa() {
      let produccion = this.registro.produccion
      let vendido = this.registro.cantidad_vendida
      if (this.registro.unidad_produccion === 'lb') {
        produccion = produccion * this.lb_to_kg
      }
      if (this.registro.unidad_cantidad_vendida === 'lb') {
        vendido = vendido * this.lb_to_kg
      }
      return vendido > produccion
    },
    // cálculo del rendimiento de la producción
    rendimiento() {
      if (this.registro.superficie_cosechada > 0) {
        return (
          (
            this.registro.produccion / this.registro.superficie_cosechada
          ).toFixed(2) || 0
        )
      } else {
        return 0
      }
    },
    invalidRelativeDate() {
      const preparacion =
        this.getMatchingRecords({
          recordName: 'preparacionesc',
          matchValues: [
            { key: 'id_productor', value: this.registro.id_productor },
            { key: 'coa', value: this.registro.coa },
            { key: 'id_finca', value: this.registro.id_finca },
            { key: 'id_parcela', value: this.registro.id_parcela },
            { key: 'id_cultivo', value: this.registro.id_cultivo },
          ],
        })?.[0] ?? {}
      const siembra =
        this.getMatchingRecords({
          recordName: 'siembrasc',
          matchValues: [
            { key: 'id_productor', value: this.registro.id_productor },
            { key: 'COA', value: this.registro.coa },
            { key: 'ID_FINCA', value: this.registro.id_finca },
            { key: 'ID_PARCELA', value: this.registro.id_parcela },
            { key: 'ID_CULTIVO', value: this.registro.id_cultivo },
          ],
        })?.[0] ?? {}

      const referenceRecord = {
        record: 'cosecha',
        date: this.registro.fecha,
      }
      const compareRecords = [
        { record: 'siembra', date: siembra.FECHA_SIEMBRA, cp: 'isAfter' },
        { record: 'preparacion', date: preparacion.fecha, cp: 'isAfter' },
      ].filter((v) => !!v)

      return isProductionDateValidComparedToRecords(
        referenceRecord,
        compareRecords
      )
    },
  },
  watch: {
    'registro.superficie_cosechada'() {
      this.alertSurfaceOverflow()
    },
    'registro.estatus'() {
      if (this.registro.estatus === 'A') {
        this.estad = 'Actualizado'
      } else {
        this.estad = 'Desactualizado'
      }
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Consulta Cosecha')
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.cargarRegistro(id)
    } else {
      this.registro = clona(formulario_cosecha)
    }
  },
  methods: {
    alertSurfaceOverflow() {
      const id_finca = this.registro.id_finca
      this.fincasi = this.getMatchingRecords({
        recordName: 'fincasc',
        matchValues: [{ key: 'id', value: id_finca }],
      })
      //const id_finca = this.preparacion.id_finca
      this.parcelita = this.getMatchingRecords({
        recordName: 'parcelasc',
        matchValues: [{ key: 'id', value: this.registro.id_parcela }],
      })
      this.alertar_superficie_mayor = ''
      if (this.registro.id_finca) {
        this.alertar_superficie_mayor =
          this.sumaDeSuperficiesDeCosechas +
            parseInt(this.registro.superficie_cosechada) >
          this.finca(this.registro.id_finca)?.extension
            ? 'La suma de las superficies cosechadas para este año, coa y finca exceden la superficie de la finca'
            : ''
      }
    },
    async obtenerUnRegistroONada() {
      try {
        const id_productor = this.registro.id_productor
        const coa = this.registro.coa
        const fecha = this.registro.fecha
        const id_finca = this.registro.id_finca
        const id_parcela = this.registro.id_parcela
        const id_cultivo = this.registro.id_cultivo
        // required fields to be fulfilled
        if (coa && fecha && id_finca && id_cultivo) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarCosechas',
            {
              id_productor,
              coa,
              fecha,
              id_finca,
              id_cultivo,
              id_parcela,
            }
          )
          if (status === 'ok' && data.length > 0) return data[0]
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    /*agregarDesastre() {
      this.registro.desastres?.push({ ...danotrx })
    },
    eliminarDesastre(index) {
      this.registro.desastres?.splice(index, 1)
    },*/
  },
}
</script>
