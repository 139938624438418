<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          depressed
          class="primary"
          @click="sincronizarListados"
          >Sincronizar listados</v-btn
        >
      </v-col>
      <v-col cols="12">
        <!-- Listado con la cantidad de elementos que posee cada listado en el almacenamiento local -->
        <v-list dense>
          <template v-for="item in listados">
            <v-list-item :key="item.tabla">
              <v-list-item-content>
                <v-list-item-title>{{ item.texto }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                {{ $store.state.listados[item.tabla].length }}
              </v-list-item-avatar>
            </v-list-item>
            <v-divider :key="item.texto"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Listados',
  data: () => {
    return {
      loading: false,
      listados: [
        { texto: 'Accesibilidad', tabla: 'accesibilidad' },
        { texto: 'Actividad familiares y empresariales', tabla: 'actividad' },
        { texto: 'Actividad de trabajadores', tabla: 'actividad_trabajador' },
        { texto: 'Actividades pecuarias', tabla: 'actividad_pecuaria' },
        { texto: 'Activo', tabla: 'activo' },
        { texto: 'Agencia', tabla: 'agencia' },
        { texto: 'Clasificaciones de rubros', tabla: 'clasificacion' },
        { texto: 'Corregimientos', tabla: 'corregimiento' },
        { texto: 'Denominaciones varietales', tabla: 'denominacion_varietal' },
        { texto: 'Destinos', tabla: 'destino' },
        { texto: 'Distritos', tabla: 'distrito' },
        { texto: 'Escolaridad', tabla: 'escolaridad' },
        { texto: 'Especies acuícolas', tabla: 'especie_acuicola' },
        { texto: 'Especies pesqueras', tabla: 'especie_pesca' },
        { texto: 'Estados', tabla: 'estado' },
        { texto: 'Estados de las parcelas', tabla: 'estado_parcela' },
        {
          texto: 'Estados de los establecimientos',
          tabla: 'estado_establecimiento',
        },
        { texto: 'Fuentes de agua', tabla: 'fuente_agua' },
        { texto: 'Instituciones', tabla: 'institucion' },
        { texto: 'Organizaciones', tabla: 'organizacion' },
        { texto: 'Países', tabla: 'pais' },
        { texto: 'Parentescos', tabla: 'parentesco' },
        { texto: 'Poblados', tabla: 'poblado' },
        {
          texto: 'Programas de ayuda del gobierno',
          tabla: 'programa_gobierno',
        },
        { texto: 'Provincias', tabla: 'provincia' },
        { texto: 'Regionales', tabla: 'regional' },
        { texto: 'Rubros', tabla: 'cultivo' },
        { texto: 'Rubros forestales', tabla: 'rubro_forestal' },
        { texto: 'Rubros apícolas', tabla: 'rubro_apicola' },
        { texto: 'Semillas', tabla: 'semilla' },
        { texto: 'Sexos', tabla: 'sexo' },
        {
          texto: 'Sistemas de gestión de calidad',
          tabla: 'sistema_gestion_calidad',
        },
        { texto: 'Sistemas de producción', tabla: 'sistema_produccion' },
        { texto: 'Tecnologías', tabla: 'tecnologia' },
        { texto: 'Tipos de tenencia de tierra', tabla: 'tenencia_tierra' },
        { texto: 'Sub-tipos de embarcaciones', tabla: 'sub_tipo_embarcacion' },
        { texto: 'Tipos de actividades', tabla: 'tipo_actividad' },
        {
          texto: 'Tipos de administración de una finca',
          tabla: 'tipo_administracion',
        },
        { texto: 'Tipos de asistencia', tabla: 'tipo_asistencia' },
        { texto: 'Tipos de embarcaciones', tabla: 'tipo_embarcacion' },
        {
          texto: 'Tipos de entidades aseguradoras',
          tabla: 'tipo_entidad_aseguradora',
        },
        {
          texto: 'Tipos de entidades financieras',
          tabla: 'tipo_entidad_financiera',
        },
        { texto: 'Tipos de establecimientos', tabla: 'tipo_establecimiento' },
        {
          texto: 'Tipos de identificación de productores',
          tabla: 'tipo_identificacion',
        },
        { texto: 'Tipos de organizaciones', tabla: 'tipo_organizacion' },
        { texto: 'Tipos de prima de seguro', tabla: 'tipo_prima_seguro' },
        { texto: 'Tipos de productores', tabla: 'tipo_empresa' },
        { texto: 'Tipos de teléfonos', tabla: 'tipo_telefono' },
        { texto: 'Tipos de terreno de una finca', tabla: 'tipo_terreno' },
        { texto: 'Unidades de medida', tabla: 'unidad_medida' },
        { texto: 'Variedades de un cultivo', tabla: 'variedad_cultivo' },
        { texto: 'Zonas de pesca', tabla: 'zona_pesca' },
      ],
    }
  },
  methods: {
    // consulta la base de datos por los listados de información actualizados
    async sincronizarListados() {
      this.loading = true
      await this.$store.dispatch('listados/sincronizar')
      this.loading = false
    },
  },
}
</script>
