<template>
  <v-container>
    <v-row dense>
      <v-col v-if="user_can.add" cols="12">
        <v-btn router to="/ru/embarcacion" depressed class="primary">
          <v-icon left v-text="'mdi-plus'"></v-icon>
          Agregar embarcacion
        </v-btn>
      </v-col>
      <!-- listado de embarcaciones en el almacenamiento local -->
      <v-col cols="12">
        <v-list three-line>
          <template v-for="embarcacion in embarcaciones">
            <form-card
              :key="embarcacion.id"
              :top-text="embarcacion.nombre || 'Sin nombre...'"
              :bottom-text="embarcacion.zona_pesca"
              :middle-text="`${embarcacion.tipo_embarcacion}`"
              :bottom-right-text="producerDescription(embarcacion.productor)"
              :sincronized="embarcacion.sincronizado"
              @click="traerRegistroYNavegar(embarcacion)"
            />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { producerDescription } from '@/utilidades'

export default {
  name: 'Embarcaciones',
  computed: {
    ...mapState('registros', ['embarcaciones', 'productores']),
    user_can() {
      return this.$store.getters['auth/userCan'](this.$route.path)
    },
  },
  mounted() {
    this.$store.commit('ui/updateToolbar', 'Embarcaciones')
  },
  methods: {
    producerDescription,
    async traerRegistroYNavegar(registro) {
      if (registro.sincronizado === true) {
        await this.$store.dispatch('registros/getEmbarcacion', {
          id: registro.id,
        })
      }
      this.$router.push(`/ru/embarcacion/${registro.id}`)
    },
  },
}
</script>
